$fontcolor: #000000;
$bluecolor: #006fbc;
$orangecolor: #f2483a;
$bodycolor: #e8e8e8;
$whitecolor: #fff;
$fieldrow: rgba(0, 117, 153, 0.2);
$lightblue: #d8ecf2;
$teal: #1D3557;
$gradientBlue: #87cefa;
$limeyellow: #f5f580;
$google-blue: #4285f4;
$button-active-blue: #1669f2;
$modalBackdrop: rgba(0, 0, 0, 0.4);
$borderColor: rgba(0, 0, 0, 0.25);
$blue: #2b78ff;
$lightBlue: rgba(219, 233, 255, 0.7);
$bgBlue: #dbe9ff8a;
$orange: #ff772b;
$lightOrange: #fff2ea;
$white: #ffffff;
$grey: #746e6e;
$darkbg: rgba(29, 53, 87, 1);
$tablehead: #b9d8f9;
$tablebody: rgba(219, 233, 255, 0.25);
$headerHeight: 65px;
$largeMenuWidth: 300px;
$smallMenuWidth: 79px;
$whiteLowOpacity: rgba(255, 255, 255, 0.15);

@font-face {
  font-family: myFirstFont;
  src: url("../Verdana.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

svg {
  width: 100%;
}

html,
.full-height {
  height: 100%;
}

#phocketApp {
  height: 99.9%;
}

.full-width {
  width: 100% !important;
}

body {
  color: $fontcolor;
  font-family: "DM Sans";
  font-size: 14px !important;
  font-weight: 500 !important;
  height: 100% !important;
  padding: 0 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  background-color: $whitecolor;
}

@keyframes animateFade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

a {
  background-color: transparent;
}

a:active,
a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: 700;
}

dfn {
  font-style: italic;
}

h1 {
  margin: 0.67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  height: 0;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

pre {
  overflow: auto;
}

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

button,
input,
optgroup,
select,
textarea {
  margin: 0;
  font: inherit;
  color: inherit;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"],
input[type="radio"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  -webkit-appearance: textfield;
}

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

fieldset {
  padding: 0.35em 0.625em 0.75em;
  margin: 0 2px;
  border: 1px solid silver;
}

legend {
  padding: 0;
  border: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: 700;
}

table {
  border-spacing: 0;
  border-collapse: unset;
}

td,
th {
  padding: 0;
}

@media print {

  *,
  :after,
  :before {
    color: #000 !important;
    text-shadow: none !important;
    background: 0 0 !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }

  a,
  a:visited {
    text-decoration: underline;
  }

  a[href]:after {
    content: " (" attr(href) ")";
  }

  abbr[title]:after {
    content: " (" attr(title) ")";
  }

  a[href^="javascript:"]:after,
  a[href^="#"]:after {
    content: "";
  }

  blockquote,
  pre {
    border: 1px solid #999;
    page-break-inside: avoid;
  }

  thead {
    display: table-header-group;
  }

  img,
  tr {
    page-break-inside: avoid;
  }

  img {
    max-width: 100% !important;
  }

  h2,
  h3,
  p {
    orphans: 3;
    widows: 3;
  }

  h2,
  h3 {
    page-break-after: avoid;
  }

  .navbar {
    display: none;
  }

  .btn>.caret,
  .dropup>.btn>.caret {
    border-top-color: #000 !important;
  }

  .label {
    border: 1px solid #000;
  }

  .table {
    border-collapse: collapse !important;
  }

  .table td,
  .table th {
    background-color: #fff !important;
  }

  .table-bordered td,
  .table-bordered th {
    border: 1px solid #ddd !important;
  }
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

a {
  color: $bluecolor;
  text-decoration: none !important;
}

a:focus,
a:hover {
  color: $bluecolor;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

figure {
  margin: 0;
}

img {
  vertical-align: middle;
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img,
.img-responsive,
.thumbnail a>img,
.thumbnail>img {
  display: block;
  max-width: 100%;
  height: auto;
}

.img-rounded {
  border-radius: 6px;
}

.img-thumbnail {
  display: inline-block;
  max-width: 100%;
  height: auto;
  padding: 4px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.img-circle {
  border-radius: 50%;
}

hr {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 0;
  border-top: 1px solid #eee;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  clip: auto;
}

[role="button"] {
  cursor: pointer;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-weight: 400;
  line-height: 1;
  color: #777;
}

.h1,
.h2,
.h3,
h1,
h2,
h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.h1 .small,
.h1 small,
.h2 .small,
.h2 small,
.h3 .small,
.h3 small,
h1 .small,
h1 small,
h2 .small,
h2 small,
h3 .small,
h3 small {
  font-size: 65%;
}

.h4,
.h5,
.h6,
h4,
h5,
h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.h4 .small,
.h4 small,
.h5 .small,
.h5 small,
.h6 .small,
.h6 small,
h4 .small,
h4 small,
h5 .small,
h5 small,
h6 .small,
h6 small {
  font-size: 75%;
}

.h1,
h1 {
  font-size: 36px;
}

.h2,
h2 {
  font-size: 30px;
}

.h3,
h3 {
  font-size: 24px;
}

.h4,
h4 {
  font-size: 18px;
}

.h5,
h5 {
  font-size: 14px;
}

.h6,
h6 {
  font-size: 12px;
}

p {
  margin: 0 0 10px;
}

.lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .lead {
    font-size: 21px;
  }
}

.small,
small {
  font-size: 85%;
}

.mark,
mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-nowrap {
  white-space: nowrap;
}

.text-lowercase {
  text-transform: lowercase;
}

.text-uppercase {
  text-transform: uppercase;
}

.text-capitalize {
  text-transform: capitalize;
}

.text-muted {
  color: #777;
}

.text-primary {
  color: #337ab7;
}

a.text-primary:focus,
a.text-primary:hover {
  color: #286090;
}

.text-success {
  color: #3c763d;
}

a.text-success:focus,
a.text-success:hover {
  color: #2b542c;
}

.text-info {
  color: #31708f;
}

a.text-info:focus,
a.text-info:hover {
  color: #245269;
}

.text-warning {
  color: #8a6d3b;
}

a.text-warning:focus,
a.text-warning:hover {
  color: #66512c;
}

.text-danger {
  color: #a94442;
}

a.text-danger:focus,
a.text-danger:hover {
  color: #843534;
}

.bg-primary {
  color: #fff;
  background-color: #337ab7;
}

a.bg-primary:focus,
a.bg-primary:hover {
  background-color: #286090;
}

.bg-success {
  background-color: #dff0d8;
}

a.bg-success:focus,
a.bg-success:hover {
  background-color: #c1e2b3;
}

.bg-info {
  background-color: #d9edf7;
}

a.bg-info:focus,
a.bg-info:hover {
  background-color: #afd9ee;
}

.bg-warning {
  background-color: #fcf8e3;
}

a.bg-warning:focus,
a.bg-warning:hover {
  background-color: #f7ecb5;
}

.bg-danger {
  background-color: #f2dede;
}

a.bg-danger:focus,
a.bg-danger:hover {
  background-color: #e4b9b9;
}

.page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eee;
}

ol,
ul {
  margin-top: 0;
  margin-bottom: 10px;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin-bottom: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  margin-left: -5px;
  list-style: none;
  margin-bottom: 0px;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

dl {
  margin-top: 0;
  margin-bottom: 20px;
}

dd,
dt {
  line-height: 1.42857143;
}

dt {
  font-weight: 700;
}

dd {
  margin-left: 0;
}

.three-cogs {
  position: relative;

  >.fa:nth-child(1) {
    animation-duration: 4s;
  }

  >.fa:nth-child(2),
  >.fa:nth-child(3) {
    position: absolute;
    top: -0.2em;
    right: -0.45em;
    animation-direction: reverse;
    animation-duration: 2s;
  }

  >.fa:nth-child(3) {
    top: auto;
    bottom: -0.2em;
  }
}

@keyframes color {
  0% {
    color: red;
  }

  50% {
    color: blue;
  }

  100% {
    color: red;
  }
}

.blinking {
  animation: blinkingText 0.8s infinite;
}

@keyframes blinkingText {
  0% {
    color: #000;
  }

  49% {
    color: transparent;
  }

  50% {
    color: transparent;
  }

  99% {
    color: transparent;
  }

  100% {
    color: #000;
  }
}

.blink {
  animation: blink 2s steps(5, start) infinite;
  -webkit-animation: blink 1s steps(5, start) infinite;
}

@keyframes blink {
  to {
    visibility: hidden;
  }
}

@-webkit-keyframes blink {
  to {
    visibility: hidden;
  }
}

@media (min-width: 768px) {
  .dl-horizontal dt {
    float: left;
    width: 160px;
    overflow: hidden;
    clear: left;
    text-align: right;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dl-horizontal dd {
    margin-left: 180px;
  }
}

abbr[data-original-title],
abbr[title] {
  cursor: help;
  border-bottom: 1px dotted #777;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eee;
}

blockquote ol:last-child,
blockquote p:last-child,
blockquote ul:last-child {
  margin-bottom: 0;
}

blockquote .small,
blockquote footer,
blockquote small {
  display: block;
  font-size: 80%;
  line-height: 1.42857143;
  color: #777;
}

blockquote .small:before,
blockquote footer:before,
blockquote small:before {
  content: "\2014 \00A0";
}

.blockquote-reverse,
blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eee;
  border-left: 0;
}

.blockquote-reverse .small:before,
.blockquote-reverse footer:before,
.blockquote-reverse small:before,
blockquote.pull-right .small:before,
blockquote.pull-right footer:before,
blockquote.pull-right small:before {
  content: "";
}

.blockquote-reverse .small:after,
.blockquote-reverse footer:after,
.blockquote-reverse small:after,
blockquote.pull-right .small:after,
blockquote.pull-right footer:after,
blockquote.pull-right small:after {
  content: "\00A0 \2014";
}

address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.42857143;
}

code,
kbd,
pre,
samp {
  font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
}

code {
  padding: 2px 4px;
  font-size: 90%;
  color: #c7254e;
  background-color: #f9f2f4;
  border-radius: 4px;
}

kbd {
  padding: 2px 4px;
  font-size: 90%;
  color: #fff;
  background-color: #333;
  border-radius: 3px;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.25);
}

kbd kbd {
  padding: 0;
  font-size: 100%;
  font-weight: 700;
  -webkit-box-shadow: none;
  box-shadow: none;
}

pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 1.42857143;
  color: #333;
  word-break: break-all;
  word-wrap: break-word;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 4px;
}

pre code {
  padding: 0;
  font-size: inherit;
  color: inherit;
  white-space: pre-wrap;
  background-color: transparent;
  border-radius: 0;
}

.pre-scrollable {
  max-height: 340px;
  overflow-y: scroll;
}

.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 768px) {
  .container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1170px;
  }
}

.container-fluid {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  margin-right: -15px;
  margin-left: -15px;
}

.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

.col-xs-1,
.col-xs-10,
.col-xs-11,
.col-xs-12,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0;
}

@media (min-width: 768px) {

  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9 {
    float: left;
  }

  .col-sm-12 {
    width: 100%;
  }

  .col-sm-11 {
    width: 91.66666667%;
  }

  .col-sm-10 {
    width: 83.33333333%;
  }

  .col-sm-9 {
    width: 75%;
  }

  .col-sm-8 {
    width: 66.66666667%;
  }

  .col-sm-7 {
    width: 58.33333333%;
  }

  .col-sm-6 {
    width: 50%;
  }

  .col-sm-5 {
    width: 41.66666667%;
  }

  .col-sm-4 {
    width: 33.33333333%;
  }

  .col-sm-3 {
    width: 25%;
  }

  .col-sm-2 {
    width: 16.66666667%;
  }

  .col-sm-1 {
    width: 8.33333333%;
  }

  .col-sm-pull-12 {
    right: 100%;
  }

  .col-sm-pull-11 {
    right: 91.66666667%;
  }

  .col-sm-pull-10 {
    right: 83.33333333%;
  }

  .col-sm-pull-9 {
    right: 75%;
  }

  .col-sm-pull-8 {
    right: 66.66666667%;
  }

  .col-sm-pull-7 {
    right: 58.33333333%;
  }

  .col-sm-pull-6 {
    right: 50%;
  }

  .col-sm-pull-5 {
    right: 41.66666667%;
  }

  .col-sm-pull-4 {
    right: 33.33333333%;
  }

  .col-sm-pull-3 {
    right: 25%;
  }

  .col-sm-pull-2 {
    right: 16.66666667%;
  }

  .col-sm-pull-1 {
    right: 8.33333333%;
  }

  .col-sm-pull-0 {
    right: auto;
  }

  .col-sm-push-12 {
    left: 100%;
  }

  .col-sm-push-11 {
    left: 91.66666667%;
  }

  .col-sm-push-10 {
    left: 83.33333333%;
  }

  .col-sm-push-9 {
    left: 75%;
  }

  .col-sm-push-8 {
    left: 66.66666667%;
  }

  .col-sm-push-7 {
    left: 58.33333333%;
  }

  .col-sm-push-6 {
    left: 50%;
  }

  .col-sm-push-5 {
    left: 41.66666667%;
  }

  .col-sm-push-4 {
    left: 33.33333333%;
  }

  .col-sm-push-3 {
    left: 25%;
  }

  .col-sm-push-2 {
    left: 16.66666667%;
  }

  .col-sm-push-1 {
    left: 8.33333333%;
  }

  .col-sm-push-0 {
    left: auto;
  }

  .col-sm-offset-12 {
    margin-left: 100%;
  }

  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-sm-offset-9 {
    margin-left: 75%;
  }

  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-sm-offset-6 {
    margin-left: 50%;
  }

  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-sm-offset-3 {
    margin-left: 25%;
  }

  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-sm-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 992px) {

  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9 {
    float: left;
  }

  .col-md-12 {
    width: 100%;
  }

  .col-md-11 {
    width: 91.66666667%;
  }

  .col-md-10 {
    width: 83.33333333%;
  }

  .col-md-9 {
    width: 75%;
  }

  .col-md-8 {
    width: 66.66666667%;
  }

  .col-md-7 {
    width: 58.33333333%;
  }

  .col-md-6 {
    width: 50%;
  }

  .col-md-5 {
    width: 41.66666667%;
  }

  .col-md-4 {
    width: 33.33333333%;
  }

  .col-md-3 {
    width: 25%;
  }

  .col-md-2 {
    width: 16.66666667%;
  }

  .col-md-1 {
    width: 8.33333333%;
  }

  .col-md-pull-12 {
    right: 100%;
  }

  .col-md-pull-11 {
    right: 91.66666667%;
  }

  .col-md-pull-10 {
    right: 83.33333333%;
  }

  .col-md-pull-9 {
    right: 75%;
  }

  .col-md-pull-8 {
    right: 66.66666667%;
  }

  .col-md-pull-7 {
    right: 58.33333333%;
  }

  .col-md-pull-6 {
    right: 50%;
  }

  .col-md-pull-5 {
    right: 41.66666667%;
  }

  .col-md-pull-4 {
    right: 33.33333333%;
  }

  .col-md-pull-3 {
    right: 25%;
  }

  .col-md-pull-2 {
    right: 16.66666667%;
  }

  .col-md-pull-1 {
    right: 8.33333333%;
  }

  .col-md-pull-0 {
    right: auto;
  }

  .col-md-push-12 {
    left: 100%;
  }

  .col-md-push-11 {
    left: 91.66666667%;
  }

  .col-md-push-10 {
    left: 83.33333333%;
  }

  .col-md-push-9 {
    left: 75%;
  }

  .col-md-push-8 {
    left: 66.66666667%;
  }

  .col-md-push-7 {
    left: 58.33333333%;
  }

  .col-md-push-6 {
    left: 50%;
  }

  .col-md-push-5 {
    left: 41.66666667%;
  }

  .col-md-push-4 {
    left: 33.33333333%;
  }

  .col-md-push-3 {
    left: 25%;
  }

  .col-md-push-2 {
    left: 16.66666667%;
  }

  .col-md-push-1 {
    left: 8.33333333%;
  }

  .col-md-push-0 {
    left: auto;
  }

  .col-md-offset-12 {
    margin-left: 100%;
  }

  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-md-offset-9 {
    margin-left: 75%;
  }

  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-md-offset-6 {
    margin-left: 50%;
  }

  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-md-offset-3 {
    margin-left: 25%;
  }

  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-md-offset-0 {
    margin-left: 0;
  }
}

@media (min-width: 1200px) {

  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9 {
    float: left;
  }

  .col-lg-12 {
    width: 100%;
  }

  .col-lg-11 {
    width: 91.66666667%;
  }

  .col-lg-10 {
    width: 83.33333333%;
  }

  .col-lg-9 {
    width: 75%;
  }

  .col-lg-8 {
    width: 66.66666667%;
  }

  .col-lg-7 {
    width: 58.33333333%;
  }

  .col-lg-6 {
    width: 50%;
  }

  .col-lg-5 {
    width: 41.66666667%;
  }

  .col-lg-4 {
    width: 33.33333333%;
  }

  .col-lg-3 {
    width: 25%;
  }

  .col-lg-2 {
    width: 16.66666667%;
  }

  .col-lg-1 {
    width: 8.33333333%;
  }

  .col-lg-pull-12 {
    right: 100%;
  }

  .col-lg-pull-11 {
    right: 91.66666667%;
  }

  .col-lg-pull-10 {
    right: 83.33333333%;
  }

  .col-lg-pull-9 {
    right: 75%;
  }

  .col-lg-pull-8 {
    right: 66.66666667%;
  }

  .col-lg-pull-7 {
    right: 58.33333333%;
  }

  .col-lg-pull-6 {
    right: 50%;
  }

  .col-lg-pull-5 {
    right: 41.66666667%;
  }

  .col-lg-pull-4 {
    right: 33.33333333%;
  }

  .col-lg-pull-3 {
    right: 25%;
  }

  .col-lg-pull-2 {
    right: 16.66666667%;
  }

  .col-lg-pull-1 {
    right: 8.33333333%;
  }

  .col-lg-pull-0 {
    right: auto;
  }

  .col-lg-push-12 {
    left: 100%;
  }

  .col-lg-push-11 {
    left: 91.66666667%;
  }

  .col-lg-push-10 {
    left: 83.33333333%;
  }

  .col-lg-push-9 {
    left: 75%;
  }

  .col-lg-push-8 {
    left: 66.66666667%;
  }

  .col-lg-push-7 {
    left: 58.33333333%;
  }

  .col-lg-push-6 {
    left: 50%;
  }

  .col-lg-push-5 {
    left: 41.66666667%;
  }

  .col-lg-push-4 {
    left: 33.33333333%;
  }

  .col-lg-push-3 {
    left: 25%;
  }

  .col-lg-push-2 {
    left: 16.66666667%;
  }

  .col-lg-push-1 {
    left: 8.33333333%;
  }

  .col-lg-push-0 {
    left: auto;
  }

  .col-lg-offset-12 {
    margin-left: 100%;
  }

  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }

  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }

  .col-lg-offset-9 {
    margin-left: 75%;
  }

  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }

  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }

  .col-lg-offset-6 {
    margin-left: 50%;
  }

  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }

  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }

  .col-lg-offset-3 {
    margin-left: 25%;
  }

  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }

  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }

  .col-lg-offset-0 {
    margin-left: 0;
  }
}

table {
  background-color: transparent;
}

caption {
  padding-top: 8px;
  padding-bottom: 8px;
  color: #777;
  text-align: left;
}

th {
  text-align: left;
}

.table {
  width: 100%;
  max-width: 100%;
  margin-bottom: 20px;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
  padding: 8px;
  line-height: 1.42857143;
  vertical-align: middle;
  border-top: 1px solid #ddd;
}

.table>thead>tr>th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.table>caption+thead>tr:first-child>td,
.table>caption+thead>tr:first-child>th,
.table>colgroup+thead>tr:first-child>td,
.table>colgroup+thead>tr:first-child>th,
.table>thead:first-child>tr:first-child>td,
.table>thead:first-child>tr:first-child>th {
  border-top: 0;
}

.table>tbody+tbody {
  border-top: 2px solid #ddd;
}

.table .table {
  background-color: #fff;
}

.table-condensed>tbody>tr>td,
.table-condensed>tbody>tr>th,
.table-condensed>tfoot>tr>td,
.table-condensed>tfoot>tr>th,
.table-condensed>thead>tr>td,
.table-condensed>thead>tr>th {
  padding: 5px;
}

.table-bordered {
  border: 1px solid #ddd;
}

.table-bordered>tbody>tr>td,
.table-bordered>tbody>tr>th,
.table-bordered>tfoot>tr>td,
.table-bordered>tfoot>tr>th,
.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
  border: 1px solid #ddd;
}

.table-bordered>thead>tr>td,
.table-bordered>thead>tr>th {
  border-bottom-width: 2px;
}

.table-striped>tbody>tr:nth-of-type(odd) {
  background-color: #f9f9f9;
}

.table-hover>tbody>tr:hover {
  background-color: #f5f5f5;
}

table col[class*="col-"] {
  position: static;
  display: table-column;
  float: none;
}

table td[class*="col-"],
table th[class*="col-"] {
  position: static;
  display: table-cell;
  float: none;
}

.table>tbody>tr.active>td,
.table>tbody>tr.active>th,
.table>tbody>tr>td.active,
.table>tbody>tr>th.active,
.table>tfoot>tr.active>td,
.table>tfoot>tr.active>th,
.table>tfoot>tr>td.active,
.table>tfoot>tr>th.active,
.table>thead>tr.active>td,
.table>thead>tr.active>th,
.table>thead>tr>td.active,
.table>thead>tr>th.active {
  background-color: #f5f5f5;
}

.table-hover>tbody>tr.active:hover>td,
.table-hover>tbody>tr.active:hover>th,
.table-hover>tbody>tr:hover>.active,
.table-hover>tbody>tr>td.active:hover,
.table-hover>tbody>tr>th.active:hover {
  background-color: $bodycolor;
}

.table>tbody>tr.success>td,
.table>tbody>tr.success>th,
.table>tbody>tr>td.success,
.table>tbody>tr>th.success,
.table>tfoot>tr.success>td,
.table>tfoot>tr.success>th,
.table>tfoot>tr>td.success,
.table>tfoot>tr>th.success,
.table>thead>tr.success>td,
.table>thead>tr.success>th,
.table>thead>tr>td.success,
.table>thead>tr>th.success {
  background-color: #dff0d8;
}

.table-hover>tbody>tr.success:hover>td,
.table-hover>tbody>tr.success:hover>th,
.table-hover>tbody>tr:hover>.success,
.table-hover>tbody>tr>td.success:hover,
.table-hover>tbody>tr>th.success:hover {
  background-color: #d0e9c6;
}

.table>tbody>tr.info>td,
.table>tbody>tr.info>th,
.table>tbody>tr>td.info,
.table>tbody>tr>th.info,
.table>tfoot>tr.info>td,
.table>tfoot>tr.info>th,
.table>tfoot>tr>td.info,
.table>tfoot>tr>th.info,
.table>thead>tr.info>td,
.table>thead>tr.info>th,
.table>thead>tr>td.info,
.table>thead>tr>th.info {
  background-color: #d9edf7;
}

.table-hover>tbody>tr.info:hover>td,
.table-hover>tbody>tr.info:hover>th,
.table-hover>tbody>tr:hover>.info,
.table-hover>tbody>tr>td.info:hover,
.table-hover>tbody>tr>th.info:hover {
  background-color: #c4e3f3;
}

.table>tbody>tr.warning>td,
.table>tbody>tr.warning>th,
.table>tbody>tr>td.warning,
.table>tbody>tr>th.warning,
.table>tfoot>tr.warning>td,
.table>tfoot>tr.warning>th,
.table>tfoot>tr>td.warning,
.table>tfoot>tr>th.warning,
.table>thead>tr.warning>td,
.table>thead>tr.warning>th,
.table>thead>tr>td.warning,
.table>thead>tr>th.warning {
  background-color: #fcf8e3;
}

.table-hover>tbody>tr.warning:hover>td,
.table-hover>tbody>tr.warning:hover>th,
.table-hover>tbody>tr:hover>.warning,
.table-hover>tbody>tr>td.warning:hover,
.table-hover>tbody>tr>th.warning:hover {
  background-color: #faf2cc;
}

.table>tbody>tr.danger>td,
.table>tbody>tr.danger>th,
.table>tbody>tr>td.danger,
.table>tbody>tr>th.danger,
.table>tfoot>tr.danger>td,
.table>tfoot>tr.danger>th,
.table>tfoot>tr>td.danger,
.table>tfoot>tr>th.danger,
.table>thead>tr.danger>td,
.table>thead>tr.danger>th,
.table>thead>tr>td.danger,
.table>thead>tr>th.danger {
  background-color: #f2dede;
}

.table-hover>tbody>tr.danger:hover>td,
.table-hover>tbody>tr.danger:hover>th,
.table-hover>tbody>tr:hover>.danger,
.table-hover>tbody>tr>td.danger:hover,
.table-hover>tbody>tr>th.danger:hover {
  background-color: #ebcccc;
}

.table-responsive {
  min-height: 0.01%;
  overflow-x: auto;
}

@media screen and (max-width: 767px) {
  .login-tea-image {
    margin-top: 80px !important;
  }

  .display-select-box {
    display: block !important;
  }

  .margin-top-30 {
    margin-top: 10px !important;
  }

  .table-responsive {
    width: 100%;
    margin-bottom: 15px;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    border: 1px solid #ddd;
  }

  .table-responsive>.table {
    margin-bottom: 0;
  }

  .table-responsive>.table>tbody>tr>td,
  .table-responsive>.table>tbody>tr>th,
  .table-responsive>.table>tfoot>tr>td,
  .table-responsive>.table>tfoot>tr>th,
  .table-responsive>.table>thead>tr>td,
  .table-responsive>.table>thead>tr>th {
    white-space: nowrap;
  }

  .table-responsive>.table-bordered {
    border: 0;
  }

  .table-responsive>.table-bordered>tbody>tr>td:first-child,
  .table-responsive>.table-bordered>tbody>tr>th:first-child,
  .table-responsive>.table-bordered>tfoot>tr>td:first-child,
  .table-responsive>.table-bordered>tfoot>tr>th:first-child,
  .table-responsive>.table-bordered>thead>tr>td:first-child,
  .table-responsive>.table-bordered>thead>tr>th:first-child {
    border-left: 0;
  }

  .table-responsive>.table-bordered>tbody>tr>td:last-child,
  .table-responsive>.table-bordered>tbody>tr>th:last-child,
  .table-responsive>.table-bordered>tfoot>tr>td:last-child,
  .table-responsive>.table-bordered>tfoot>tr>th:last-child,
  .table-responsive>.table-bordered>thead>tr>td:last-child,
  .table-responsive>.table-bordered>thead>tr>th:last-child {
    border-right: 0;
  }

  .table-responsive>.table-bordered>tbody>tr:last-child>td,
  .table-responsive>.table-bordered>tbody>tr:last-child>th,
  .table-responsive>.table-bordered>tfoot>tr:last-child>td,
  .table-responsive>.table-bordered>tfoot>tr:last-child>th {
    border-bottom: 0;
  }
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  font-size: 21px;
  line-height: inherit;
  color: #333;
  border: 0;
  border-bottom: 1px solid #e5e5e5;
}

label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: 700;
}

input[type="search"] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

input[type="checkbox"],
input[type="radio"] {
  margin: 4px 0 0;
  margin-top: 1px\9;
  line-height: normal;
}

input[type="file"] {
  display: block;
}

input[type="range"] {
  display: block;
  width: 100%;
}

select[multiple],
select[size] {
  height: auto;
}

input[type="file"]:focus,
input[type="checkbox"]:focus,
input[type="radio"]:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

output {
  display: block;
  padding-top: 7px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
}

.form-control {
  display: block;
  width: 100%;
  // height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #000;
  background-color: #DBE9FF;
  background-image: none;
  border-radius: 6px;
  padding: 10px;
  // border: 1px solid $teal;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::-moz-placeholder {
  color: #999;
  opacity: 1;
}

.form-control:-ms-input-placeholder {
  color: #999;
}

.form-control::-webkit-input-placeholder {
  color: #999;
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control[disabled],
.form-control[readonly],
fieldset[disabled] .form-control {
  background-color: #eee;
  opacity: 1;
}

.form-control[disabled],
fieldset[disabled] .form-control {
  cursor: not-allowed;
}

textarea.form-control {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: none;
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {

  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    // line-height: 34px;
  }

  .input-group-sm input[type="date"],
  .input-group-sm input[type="time"],
  .input-group-sm input[type="datetime-local"],
  .input-group-sm input[type="month"],
  input[type="date"].input-sm,
  input[type="time"].input-sm,
  input[type="datetime-local"].input-sm,
  input[type="month"].input-sm {
    line-height: 30px;
  }

  .input-group-lg input[type="date"],
  .input-group-lg input[type="time"],
  .input-group-lg input[type="datetime-local"],
  .input-group-lg input[type="month"],
  input[type="date"].input-lg,
  input[type="time"].input-lg,
  input[type="datetime-local"].input-lg,
  input[type="month"].input-lg {
    line-height: 46px;
  }
}

.form-group {
  // margin-bottom: 15px !important;
}

.checkbox,
.radio {
  position: relative;
  display: block;
  margin-top: 10px;
  margin-bottom: 10px;
}

.checkbox label,
.radio label {
  min-height: 20px;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
}

.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"],
.radio input[type="radio"],
.radio-inline input[type="radio"] {
  position: absolute;
  margin-top: 4px\9;
  margin-left: -20px;
}

.checkbox+.checkbox,
.radio+.radio {
  margin-top: -5px;
}

.checkbox-inline,
.radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox-inline+.checkbox-inline,
.radio-inline+.radio-inline {
  margin-top: 0;
  margin-left: 10px;
}

fieldset[disabled] input[type="checkbox"],
fieldset[disabled] input[type="radio"],
input[type="checkbox"].disabled,
input[type="checkbox"][disabled],
input[type="radio"].disabled,
input[type="radio"][disabled] {
  cursor: not-allowed;
}

.checkbox-inline.disabled,
.radio-inline.disabled,
fieldset[disabled] .checkbox-inline,
fieldset[disabled] .radio-inline {
  cursor: not-allowed;
}

.checkbox.disabled label,
.radio.disabled label,
fieldset[disabled] .checkbox label,
fieldset[disabled] .radio label {
  cursor: not-allowed;
}

.form-control-static {
  min-height: 34px;
  padding-top: 7px;
  padding-bottom: 7px;
  margin-bottom: 0;
}

.form-control-static.input-lg,
.form-control-static.input-sm {
  padding-right: 0;
  padding-left: 0;
}

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-sm {
  height: 30px;
  line-height: 30px;
}

select[multiple].input-sm,
textarea.input-sm {
  height: auto;
}

.form-group-sm .form-control {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.form-group-sm select.form-control {
  height: 30px;
  line-height: 30px;
}

.form-group-sm select[multiple].form-control,
.form-group-sm textarea.form-control {
  height: auto;
}

.form-group-sm .form-control-static {
  height: 30px;
  min-height: 32px;
  padding: 6px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

select.input-lg {
  height: 46px;
  line-height: 46px;
}

select[multiple].input-lg,
textarea.input-lg {
  height: auto;
}

.form-group-lg .form-control {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.form-group-lg select.form-control {
  height: 46px;
  line-height: 46px;
}

.form-group-lg select[multiple].form-control,
.form-group-lg textarea.form-control {
  height: auto;
}

.form-group-lg .form-control-static {
  height: 46px;
  min-height: 38px;
  padding: 11px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.has-feedback {
  position: relative;
}

.has-feedback .form-control {
  padding-right: 42.5px;
}

.form-control-feedback {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  display: block;
  width: 34px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  pointer-events: none;
}

.form-group-lg .form-control+.form-control-feedback,
.input-group-lg+.form-control-feedback,
.input-lg+.form-control-feedback {
  width: 46px;
  height: 46px;
  line-height: 46px;
}

.form-group-sm .form-control+.form-control-feedback,
.input-group-sm+.form-control-feedback,
.input-sm+.form-control-feedback {
  width: 30px;
  height: 30px;
  line-height: 30px;
}

.has-success .checkbox,
.has-success .checkbox-inline,
.has-success .control-label,
.has-success .help-block,
.has-success .radio,
.has-success .radio-inline,
.has-success.checkbox label,
.has-success.checkbox-inline label,
.has-success.radio label,
.has-success.radio-inline label {
  color: #3c763d;
}

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-success .form-control:focus {
  border-color: #2b542c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #67b168;
}

.has-success .input-group-addon {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #3c763d;
}

.has-success .form-control-feedback {
  color: #3c763d;
}

.has-warning .checkbox,
.has-warning .checkbox-inline,
.has-warning .control-label,
.has-warning .help-block,
.has-warning .radio,
.has-warning .radio-inline,
.has-warning.checkbox label,
.has-warning.checkbox-inline label,
.has-warning.radio label,
.has-warning.radio-inline label {
  color: #8a6d3b;
}

.has-warning .form-control {
  border-color: #8a6d3b;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-warning .form-control:focus {
  border-color: #66512c;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0a16b;
}

.has-warning .input-group-addon {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #8a6d3b;
}

.has-warning .form-control-feedback {
  color: #8a6d3b;
}

.has-error .checkbox,
.has-error .checkbox-inline,
.has-error .control-label,
.has-error .help-block,
.has-error .radio,
.has-error .radio-inline,
.has-error.checkbox label,
.has-error.checkbox-inline label,
.has-error.radio label,
.has-error.radio-inline label {
  color: #a94442;
}

.has-error .form-control {
  border-color: #a94442;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.has-error .form-control:focus {
  border-color: #843534;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #ce8483;
}

.has-error .input-group-addon {
  color: #a94442;
  background-color: #f2dede;
  border-color: #a94442;
}

.has-error .form-control-feedback {
  color: #a94442;
}

.has-feedback label~.form-control-feedback {
  top: 25px;
}

.has-feedback label.sr-only~.form-control-feedback {
  top: 0;
}

.help-block {
  display: block;
  margin-top: 5px;
  margin-bottom: 10px;
  color: #737373;
}

@media (min-width: 768px) {
  .form-inline .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .form-inline .form-control-static {
    display: inline-block;
  }

  .form-inline .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .form-inline .input-group .form-control,
  .form-inline .input-group .input-group-addon,
  .form-inline .input-group .input-group-btn {
    width: auto;
  }

  .form-inline .input-group>.form-control {
    width: 100%;
  }

  .form-inline .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .checkbox,
  .form-inline .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .form-inline .checkbox label,
  .form-inline .radio label {
    padding-left: 0;
  }

  .form-inline .checkbox input[type="checkbox"],
  .form-inline .radio input[type="radio"] {
    position: relative;
    margin-left: 0;
  }

  .form-inline .has-feedback .form-control-feedback {
    top: 0;
  }
}

.form-horizontal .checkbox,
.form-horizontal .checkbox-inline,
.form-horizontal .radio,
.form-horizontal .radio-inline {
  padding-top: 7px;
  margin-top: 0;
  margin-bottom: 0;
}

.form-horizontal .checkbox,
.form-horizontal .radio {
  min-height: 27px;
}

.form-horizontal .form-group {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 7px;
    margin-bottom: 0;
    text-align: right;
  }
}

.form-horizontal .has-feedback .form-control-feedback {
  right: 15px;
}

@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 11px;
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 6px;
    font-size: 12px;
  }
}

.btn {
  display: inline-block;
  padding: 6px 12px;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #333;
  text-decoration: none;
}

.btn.active,
.btn:active {
  background-image: none;
  outline: 0;
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  cursor: not-allowed;
  filter: alpha(opacity=65);
  -webkit-box-shadow: none;
  box-shadow: none;
  opacity: 0.65;
}

a.btn.disabled,
fieldset[disabled] a.btn {
  pointer-events: none;
}

.btn-default {
  color: #333;
  background-color: #fff;
  border-color: #ccc;
}

.btn-default.focus,
.btn-default:focus {
  color: #333;
  background-color: #e6e6e6;
  border-color: #8c8c8c;
}

.btn-default:hover {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
  color: #333;
  background-color: #e6e6e6;
  border-color: #adadad;
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.dropdown-toggle.btn-default.focus,
.open>.dropdown-toggle.btn-default:focus,
.open>.dropdown-toggle.btn-default:hover {
  color: #333;
  background-color: #d4d4d4;
  border-color: #8c8c8c;
}

.btn-default.active,
.btn-default:active,
.open>.dropdown-toggle.btn-default {
  background-image: none;
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
  background-color: #fff;
  border-color: #ccc;
}

.btn-default .badge {
  color: #fff;
  background-color: #333;
}

.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary.focus,
.btn-primary:focus {
  color: #fff;
  background-color: #286090;
  border-color: #122b40;
}

.btn-primary:hover {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  color: #fff;
  background-color: #286090;
  border-color: #204d74;
}

.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open>.dropdown-toggle.btn-primary.focus,
.open>.dropdown-toggle.btn-primary:focus,
.open>.dropdown-toggle.btn-primary:hover {
  color: #fff;
  background-color: #204d74;
  border-color: #122b40;
}

.btn-primary.active,
.btn-primary:active,
.open>.dropdown-toggle.btn-primary {
  background-image: none;
}

.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
  background-color: #337ab7;
  border-color: #2e6da4;
}

.btn-primary .badge {
  color: #337ab7;
  background-color: #fff;
}

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success.focus,
.btn-success:focus {
  color: #fff;
  background-color: #449d44;
  border-color: #255625;
}

.btn-success:hover {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
  color: #fff;
  background-color: #449d44;
  border-color: #398439;
}

.btn-success.active.focus,
.btn-success.active:focus,
.btn-success.active:hover,
.btn-success:active.focus,
.btn-success:active:focus,
.btn-success:active:hover,
.open>.dropdown-toggle.btn-success.focus,
.open>.dropdown-toggle.btn-success:focus,
.open>.dropdown-toggle.btn-success:hover {
  color: #fff;
  background-color: #398439;
  border-color: #255625;
}

.btn-success.active,
.btn-success:active,
.open>.dropdown-toggle.btn-success {
  background-image: none;
}

.btn-success.disabled.focus,
.btn-success.disabled:focus,
.btn-success.disabled:hover,
.btn-success[disabled].focus,
.btn-success[disabled]:focus,
.btn-success[disabled]:hover,
fieldset[disabled] .btn-success.focus,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:hover {
  background-color: #5cb85c;
  border-color: #4cae4c;
}

.btn-success .badge {
  color: #5cb85c;
  background-color: #fff;
}

.btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info.focus,
.btn-info:focus {
  color: #fff;
  background-color: #31b0d5;
  border-color: #1b6d85;
}

.btn-info:hover {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
  color: #fff;
  background-color: #31b0d5;
  border-color: #269abc;
}

.btn-info.active.focus,
.btn-info.active:focus,
.btn-info.active:hover,
.btn-info:active.focus,
.btn-info:active:focus,
.btn-info:active:hover,
.open>.dropdown-toggle.btn-info.focus,
.open>.dropdown-toggle.btn-info:focus,
.open>.dropdown-toggle.btn-info:hover {
  color: #fff;
  background-color: #269abc;
  border-color: #1b6d85;
}

.btn-info.active,
.btn-info:active,
.open>.dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled.focus,
.btn-info.disabled:focus,
.btn-info.disabled:hover,
.btn-info[disabled].focus,
.btn-info[disabled]:focus,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info.focus,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:hover {
  background-color: #5bc0de;
  border-color: #46b8da;
}

.btn-info .badge {
  color: #5bc0de;
  background-color: #fff;
}

.btn-warning {
  color: #fff;
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning.focus,
.btn-warning:focus {
  color: #fff;
  background-color: #ec971f;
  border-color: #985f0d;
}

.btn-warning:hover {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
  color: #fff;
  background-color: #ec971f;
  border-color: #d58512;
}

.btn-warning.active.focus,
.btn-warning.active:focus,
.btn-warning.active:hover,
.btn-warning:active.focus,
.btn-warning:active:focus,
.btn-warning:active:hover,
.open>.dropdown-toggle.btn-warning.focus,
.open>.dropdown-toggle.btn-warning:focus,
.open>.dropdown-toggle.btn-warning:hover {
  color: #fff;
  background-color: #d58512;
  border-color: #985f0d;
}

.btn-warning.active,
.btn-warning:active,
.open>.dropdown-toggle.btn-warning {
  background-image: none;
}

.btn-warning.disabled.focus,
.btn-warning.disabled:focus,
.btn-warning.disabled:hover,
.btn-warning[disabled].focus,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:hover,
fieldset[disabled] .btn-warning.focus,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:hover {
  background-color: #f0ad4e;
  border-color: #eea236;
}

.btn-warning .badge {
  color: #f0ad4e;
  background-color: #fff;
}

.btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger.focus,
.btn-danger:focus {
  color: #fff;
  background-color: #c9302c;
  border-color: #761c19;
}

.btn-danger:hover {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
  color: #fff;
  background-color: #c9302c;
  border-color: #ac2925;
}

.btn-danger.active.focus,
.btn-danger.active:focus,
.btn-danger.active:hover,
.btn-danger:active.focus,
.btn-danger:active:focus,
.btn-danger:active:hover,
.open>.dropdown-toggle.btn-danger.focus,
.open>.dropdown-toggle.btn-danger:focus,
.open>.dropdown-toggle.btn-danger:hover {
  color: #fff;
  background-color: #ac2925;
  border-color: #761c19;
}

.btn-danger.active,
.btn-danger:active,
.open>.dropdown-toggle.btn-danger {
  background-image: none;
}

.btn-danger.disabled.focus,
.btn-danger.disabled:focus,
.btn-danger.disabled:hover,
.btn-danger[disabled].focus,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:hover,
fieldset[disabled] .btn-danger.focus,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:hover {
  background-color: #d9534f;
  border-color: #d43f3a;
}

.btn-danger .badge {
  color: #d9534f;
  background-color: #fff;
}

.btn-link {
  font-weight: 400;
  color: #337ab7;
  border-radius: 0;
}

.btn-link,
.btn-link.active,
.btn-link:active,
.btn-link[disabled],
fieldset[disabled] .btn-link {
  background-color: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn-link,
.btn-link:active,
.btn-link:focus,
.btn-link:hover {
  border-color: transparent;
}

.btn-link:focus,
.btn-link:hover {
  color: #23527c;
  text-decoration: underline;
  background-color: transparent;
}

.btn-link[disabled]:focus,
.btn-link[disabled]:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:hover {
  color: #777;
  text-decoration: none;
}

.btn-group-lg>.btn,
.btn-lg {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

.btn-group-sm>.btn,
.btn-sm {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-group-xs>.btn,
.btn-xs {
  padding: 1px 5px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block+.btn-block {
  margin-top: 5px;
}

input[type="button"].btn-block,
input[type="reset"].btn-block,
input[type="submit"].btn-block {
  width: 100%;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-timing-function: ease;
  -o-transition-timing-function: ease;
  transition-timing-function: ease;
  -webkit-transition-duration: 0.35s;
  -o-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-property: height, visibility;
  -o-transition-property: height, visibility;
  transition-property: height, visibility;
}

.caret {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 2px;
  vertical-align: middle;
  border-top: 4px dashed;
  border-top: 4px solid;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
}

.dropdown,
.dropup {
  position: relative;
}

.dropdown-toggle:focus {
  outline: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  border-radius: 12px;
  /* light elevation */
  box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.dropdown-menu.pull-right {
  right: 0;
  left: auto;
}

.dropdown-menu .divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.dropdown-menu>li>a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.dropdown-menu>li>a:focus,
.dropdown-menu>li>a:hover {
  color: #262626;
  text-decoration: none;
  background-color: #f5f5f5;
  border-radius: 12px;
}

.dropdown-menu>.active>a,
.dropdown-menu>.active>a:focus,
.dropdown-menu>.active>a:hover {
  color: #fff;
  text-decoration: none;
  background-color: #337ab7;
  outline: 0;
}

.dropdown-menu>.disabled>a,
.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
  color: #777;
}

.dropdown-menu>.disabled>a:focus,
.dropdown-menu>.disabled>a:hover {
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
  background-image: none;
  filter: progid:DXImageTransform.Microsoft.gradient(enabled=false);
}

.open>.dropdown-menu {
  display: block;
}

.open>a {
  outline: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-header {
  display: block;
  padding: 3px 20px;
  font-size: 12px;
  line-height: 1.42857143;
  color: #777;
  white-space: nowrap;
}

.dropdown-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 990;
}

.pull-right>.dropdown-menu {
  right: 0;
  left: auto;
}

.dropup .caret,
.navbar-fixed-bottom .dropdown .caret {
  content: "";
  border-top: 0;
  border-bottom: 4px dashed;
  border-bottom: 4px solid\9;
}

.dropup .dropdown-menu,
.navbar-fixed-bottom .dropdown .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

@media (min-width: 768px) {
  .navbar-right .dropdown-menu {
    right: 0;
    left: auto;
  }

  .navbar-right .dropdown-menu-left {
    right: auto;
    left: 0;
  }
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-block;
  vertical-align: middle;
}

.btn-group-vertical>.btn,
.btn-group>.btn {
  position: relative;
  float: left;
}

.btn-group-vertical>.btn.active,
.btn-group-vertical>.btn:active,
.btn-group-vertical>.btn:focus,
.btn-group-vertical>.btn:hover,
.btn-group>.btn.active,
.btn-group>.btn:active,
.btn-group>.btn:focus,
.btn-group>.btn:hover {
  z-index: 2;
}

.btn-group .btn+.btn,
.btn-group .btn+.btn-group,
.btn-group .btn-group+.btn,
.btn-group .btn-group+.btn-group {
  margin-left: -1px;
}

.btn-toolbar {
  margin-left: -5px;
}

.btn-toolbar .btn,
.btn-toolbar .btn-group,
.btn-toolbar .input-group {
  float: left;
}

.btn-toolbar>.btn,
.btn-toolbar>.btn-group,
.btn-toolbar>.input-group {
  margin-left: 5px;
}

.btn-group>.btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0;
}

.btn-group>.btn:first-child {
  margin-left: 0;
}

.btn-group>.btn:first-child:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn:last-child:not(:first-child),
.btn-group>.dropdown-toggle:not(:first-child) {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group>.btn-group {
  float: left;
}

.btn-group>.btn-group:not(:first-child):not(:last-child)>.btn {
  border-radius: 0;
}

.btn-group>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.btn-group>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group .dropdown-toggle:active,
.btn-group.open .dropdown-toggle {
  outline: 0;
}

.btn-group>.btn+.dropdown-toggle {
  padding-right: 8px;
  padding-left: 8px;
}

.btn-group>.btn-lg+.dropdown-toggle {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-group.open .dropdown-toggle {
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-group.open .dropdown-toggle.btn-link {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.btn .caret {
  margin-left: 0;
}

.btn-lg .caret {
  border-width: 5px 5px 0;
  border-bottom-width: 0;
}

.dropup .btn-lg .caret {
  border-width: 0 5px 5px;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn-group,
.btn-group-vertical>.btn-group>.btn {
  display: block;
  float: none;
  width: 100%;
  max-width: 100%;
}

.btn-group-vertical>.btn-group>.btn {
  float: none;
}

.btn-group-vertical>.btn+.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
  margin-top: -1px;
  margin-left: 0;
}

.btn-group-vertical>.btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.btn-group-vertical>.btn:first-child:not(:last-child) {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn:last-child:not(:first-child) {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.btn-group-vertical>.btn-group:not(:first-child):not(:last-child)>.btn {
  border-radius: 0;
}

.btn-group-vertical>.btn-group:first-child:not(:last-child)>.btn:last-child,
.btn-group-vertical>.btn-group:first-child:not(:last-child)>.dropdown-toggle {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.btn-group-vertical>.btn-group:last-child:not(:first-child)>.btn:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.btn-group-justified>.btn,
.btn-group-justified>.btn-group {
  display: table-cell;
  float: none;
  width: 1%;
}

.btn-group-justified>.btn-group .btn {
  width: 100%;
}

.btn-group-justified>.btn-group .dropdown-menu {
  left: auto;
}

[data-toggle="buttons"]>.btn input[type="checkbox"],
[data-toggle="buttons"]>.btn input[type="radio"],
[data-toggle="buttons"]>.btn-group>.btn input[type="checkbox"],
[data-toggle="buttons"]>.btn-group>.btn input[type="radio"] {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}

.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}

.input-group[class*="col-"] {
  float: none;
  padding-right: 0;
  padding-left: 0;
}

.input-group .form-control {
  position: relative;
  z-index: 2;
  float: left;
  width: 100%;
  margin-bottom: 0;
}

.input-group .form-control:focus {
  z-index: 3;
}

.input-group-lg>.form-control,
.input-group-lg>.input-group-addon,
.input-group-lg>.input-group-btn>.btn {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px;
}

select.input-group-lg>.form-control,
select.input-group-lg>.input-group-addon,
select.input-group-lg>.input-group-btn>.btn {
  height: 46px;
  line-height: 46px;
}

select[multiple].input-group-lg>.form-control,
select[multiple].input-group-lg>.input-group-addon,
select[multiple].input-group-lg>.input-group-btn>.btn,
textarea.input-group-lg>.form-control,
textarea.input-group-lg>.input-group-addon,
textarea.input-group-lg>.input-group-btn>.btn {
  height: auto;
}

.input-group-sm>.form-control,
.input-group-sm>.input-group-addon,
.input-group-sm>.input-group-btn>.btn {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}

select.input-group-sm>.form-control,
select.input-group-sm>.input-group-addon,
select.input-group-sm>.input-group-btn>.btn {
  height: 30px;
  line-height: 30px;
}

select[multiple].input-group-sm>.form-control,
select[multiple].input-group-sm>.input-group-addon,
select[multiple].input-group-sm>.input-group-btn>.btn,
textarea.input-group-sm>.form-control,
textarea.input-group-sm>.input-group-addon,
textarea.input-group-sm>.input-group-btn>.btn {
  height: auto;
}

.input-group .form-control,
.input-group-addon,
.input-group-btn {
  display: table-cell;
}

.input-group .form-control:not(:first-child):not(:last-child),
.input-group-addon:not(:first-child):not(:last-child),
.input-group-btn:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.input-group-addon,
.input-group-btn {
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.input-group-addon.input-sm {
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 3px;
}

.input-group-addon.input-lg {
  padding: 10px 16px;
  font-size: 18px;
  border-radius: 6px;
}

.input-group-addon input[type="checkbox"],
.input-group-addon input[type="radio"] {
  margin-top: 0;
}

.input-group .form-control:first-child,
.input-group-addon:first-child,
.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group>.btn,
.input-group-btn:first-child>.dropdown-toggle,
.input-group-btn:last-child>.btn-group:not(:last-child)>.btn,
.input-group-btn:last-child>.btn:not(:last-child):not(.dropdown-toggle) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.input-group-addon:first-child {
  border-right: 0;
}

.input-group .form-control:last-child,
.input-group-addon:last-child,
.input-group-btn:first-child>.btn-group:not(:first-child)>.btn,
.input-group-btn:first-child>.btn:not(:first-child),
.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group>.btn,
.input-group-btn:last-child>.dropdown-toggle {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group-addon:last-child {
  border-left: 0;
}

.input-group-btn {
  position: relative;
  font-size: 0;
  white-space: nowrap;
}

.input-group-btn>.btn {
  position: relative;
}

.input-group-btn>.btn+.btn {
  margin-left: -1px;
}

.input-group-btn>.btn:active,
.input-group-btn>.btn:focus,
.input-group-btn>.btn:hover {
  z-index: 2;
}

.input-group-btn:first-child>.btn,
.input-group-btn:first-child>.btn-group {
  margin-right: -1px;
}

.input-group-btn:last-child>.btn,
.input-group-btn:last-child>.btn-group {
  z-index: 2;
  margin-left: -1px;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav>li {
  position: relative;
  display: block;
}

.nav>li>a {
  position: relative;
  display: block;
  padding: 10px 15px;
}

.nav>li>a:focus,
.nav>li>a:hover {
  text-decoration: none;
  background-color: #eee;
}

.nav>li.disabled>a {
  color: #777;
}

.nav>li.disabled>a:focus,
.nav>li.disabled>a:hover {
  color: #777;
  text-decoration: none;
  cursor: not-allowed;
  background-color: transparent;
}

.nav .open>a,
.nav .open>a:focus,
.nav .open>a:hover {
  background-color: #eee;
  border-color: #337ab7;
}

.nav .nav-divider {
  height: 1px;
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.nav>li>a>img {
  max-width: none;
}

.nav-tabs {
  border-bottom: 1px solid #ddd;
}

.nav-tabs>li {
  float: left;
  margin-bottom: -1px;
}

.nav-tabs>li>a {
  margin-right: 2px;
  line-height: 1.42857143;
  border: 1px solid transparent;
  border-radius: 4px 4px 0 0;
}

.nav-tabs>li>a:hover {
  border-color: #eee #eee #ddd;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
  color: #555;
  cursor: default;
  background-color: #fff;
  border: 1px solid #ddd;
  border-bottom-color: transparent;
}

.nav-tabs.nav-justified {
  width: 100%;
  border-bottom: 0;
}

.nav-tabs.nav-justified>li {
  float: none;
}

.nav-tabs.nav-justified>li>a {
  margin-bottom: 5px;
  text-align: center;
}

.nav-tabs.nav-justified>.dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-tabs.nav-justified>li {
    display: table-cell;
    width: 1%;
  }

  .nav-tabs.nav-justified>li>a {
    margin-bottom: 0;
  }
}

.nav-tabs.nav-justified>li>a {
  margin-right: 0;
  border-radius: 4px;
}

.nav-tabs.nav-justified>.active>a,
.nav-tabs.nav-justified>.active>a:focus,
.nav-tabs.nav-justified>.active>a:hover {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs.nav-justified>li>a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }

  .nav-tabs.nav-justified>.active>a,
  .nav-tabs.nav-justified>.active>a:focus,
  .nav-tabs.nav-justified>.active>a:hover {
    border-bottom-color: #fff;
  }
}

.nav-pills>li {
  float: left;
}

.nav-pills>li>a {
  border-radius: 4px;
}

.nav-pills>li+li {
  margin-left: 2px;
}

.nav-pills>li.active>a,
.nav-pills>li.active>a:focus,
.nav-pills>li.active>a:hover {
  color: #fff;
  background-color: #337ab7;
}

.nav-stacked>li {
  float: none;
}

.nav-stacked>li+li {
  margin-top: 2px;
  margin-left: 0;
}

.nav-justified {
  width: 100%;
}

.nav-justified>li {
  float: none;
}

.nav-justified>li>a {
  margin-bottom: 5px;
  text-align: center;
}

.nav-justified>.dropdown .dropdown-menu {
  top: auto;
  left: auto;
}

@media (min-width: 768px) {
  .nav-justified>li {
    display: table-cell;
    width: 1%;
  }

  .nav-justified>li>a {
    margin-bottom: 0;
  }
}

.nav-tabs-justified {
  border-bottom: 0;
}

.nav-tabs-justified>li>a {
  margin-right: 0;
  border-radius: 4px;
}

.nav-tabs-justified>.active>a,
.nav-tabs-justified>.active>a:focus,
.nav-tabs-justified>.active>a:hover {
  border: 1px solid #ddd;
}

@media (min-width: 768px) {
  .nav-tabs-justified>li>a {
    border-bottom: 1px solid #ddd;
    border-radius: 4px 4px 0 0;
  }

  .nav-tabs-justified>.active>a,
  .nav-tabs-justified>.active>a:focus,
  .nav-tabs-justified>.active>a:hover {
    border-bottom-color: #fff;
  }
}

.tab-content>.tab-pane {
  display: none;
}

.tab-content>.active {
  display: block;
}

.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar {
  position: relative;
  min-height: 50px;
  margin-bottom: 20px;
  border: 1px solid transparent;
}

@media (min-width: 768px) {
  .navbar {
    border-radius: 4px;
  }
}

@media (min-width: 768px) {
  .navbar-header {
    float: left;
  }
}

.navbar-collapse {
  padding-right: 15px;
  padding-left: 15px;
  overflow-x: visible;
  -webkit-overflow-scrolling: touch;
  border-top: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

.navbar-collapse.in {
  overflow-y: auto;
}

@media (min-width: 768px) {
  .navbar-collapse {
    width: auto;
    border-top: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-collapse.collapse {
    display: block !important;
    height: auto !important;
    padding-bottom: 0;
    overflow: visible !important;
  }

  .navbar-collapse.in {
    overflow-y: visible;
  }

  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse,
  .navbar-static-top .navbar-collapse {
    padding-right: 0;
    padding-left: 0;
  }
}

.navbar-fixed-bottom .navbar-collapse,
.navbar-fixed-top .navbar-collapse {
  max-height: 340px;
}

@media (max-device-width: 480px) and (orientation: landscape) {

  .navbar-fixed-bottom .navbar-collapse,
  .navbar-fixed-top .navbar-collapse {
    max-height: 200px;
  }
}

.container-fluid>.navbar-collapse,
.container-fluid>.navbar-header,
.container>.navbar-collapse,
.container>.navbar-header {
  margin-right: -15px;
  margin-left: -15px;
}

@media (min-width: 768px) {

  .container-fluid>.navbar-collapse,
  .container-fluid>.navbar-header,
  .container>.navbar-collapse,
  .container>.navbar-header {
    margin-right: 0;
    margin-left: 0;
  }
}

.navbar-static-top {
  z-index: 1000;
  border-width: 0 0 1px;
}

@media (min-width: 768px) {
  .navbar-static-top {
    border-radius: 0;
  }
}

.navbar-fixed-bottom,
.navbar-fixed-top {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 1030;
}

@media (min-width: 768px) {

  .navbar-fixed-bottom,
  .navbar-fixed-top {
    border-radius: 0;
  }
}

.navbar-fixed-top {
  top: 0;
  border-width: 0 0 1px;
}

.navbar-fixed-bottom {
  bottom: 0;
  margin-bottom: 0;
  border-width: 1px 0 0;
}

.navbar-brand {
  float: left;
  height: 50px;
  padding: 15px 15px;
  font-size: 18px;
  line-height: 20px;
}

.navbar-brand:focus,
.navbar-brand:hover {
  text-decoration: none;
}

.navbar-brand>img {
  display: block;
}

@media (min-width: 768px) {

  .navbar>.container .navbar-brand,
  .navbar>.container-fluid .navbar-brand {
    margin-left: -15px;
  }
}

.navbar-toggle {
  position: relative;
  float: right;
  padding: 9px 10px;
  margin-top: 8px;
  margin-right: 15px;
  margin-bottom: 8px;
  background-color: transparent;
  background-image: none;
  border: 1px solid transparent;
  border-radius: 4px;
}

.navbar-toggle:focus {
  outline: 0;
}

.navbar-toggle .icon-bar {
  display: block;
  width: 22px;
  height: 2px;
  border-radius: 1px;
}

.navbar-toggle .icon-bar+.icon-bar {
  margin-top: 4px;
}

@media (min-width: 768px) {
  .navbar-toggle {
    display: none;
  }
}

.navbar-nav {
  margin: 7.5px -15px;
}

.navbar-nav>li>a {
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 20px;
}

@media (max-width: 767px) {
  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: transparent;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }

  .navbar-nav .open .dropdown-menu .dropdown-header,
  .navbar-nav .open .dropdown-menu>li>a {
    padding: 5px 15px 5px 25px;
  }

  .navbar-nav .open .dropdown-menu>li>a {
    line-height: 20px;
  }

  .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-nav .open .dropdown-menu>li>a:hover {
    background-image: none;
  }
}

@media (min-width: 768px) {
  .navbar-nav {
    float: left;
    margin: 0;
  }

  .navbar-nav>li {
    float: left;
  }

  .navbar-nav>li>a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.navbar-form {
  padding: 10px 15px;
  margin-top: 8px;
  margin-right: -15px;
  margin-bottom: 8px;
  margin-left: -15px;
  border-top: 1px solid transparent;
  border-bottom: 1px solid transparent;
  -webkit-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1), 0 1px 0 rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .navbar-form .form-group {
    display: inline-block;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .navbar-form .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }

  .navbar-form .form-control-static {
    display: inline-block;
  }

  .navbar-form .input-group {
    display: inline-table;
    vertical-align: middle;
  }

  .navbar-form .input-group .form-control,
  .navbar-form .input-group .input-group-addon,
  .navbar-form .input-group .input-group-btn {
    width: auto;
  }

  .navbar-form .input-group>.form-control {
    width: 100%;
  }

  .navbar-form .control-label {
    margin-bottom: 0;
    vertical-align: middle;
  }

  .navbar-form .checkbox,
  .navbar-form .radio {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .navbar-form .checkbox label,
  .navbar-form .radio label {
    padding-left: 0;
  }

  .navbar-form .checkbox input[type="checkbox"],
  .navbar-form .radio input[type="radio"] {
    position: relative;
    margin-left: 0;
  }

  .navbar-form .has-feedback .form-control-feedback {
    top: 0;
  }
}

@media (max-width: 767px) {
  .navbar-form .form-group {
    margin-bottom: 5px;
  }

  .navbar-form .form-group:last-child {
    margin-bottom: 0;
  }
}

@media (min-width: 768px) {
  .navbar-form {
    width: auto;
    padding-top: 0;
    padding-bottom: 0;
    margin-right: 0;
    margin-left: 0;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
  }
}

.navbar-nav>li>.dropdown-menu {
  margin-top: 0;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.navbar-fixed-bottom .navbar-nav>li>.dropdown-menu {
  margin-bottom: 0;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.navbar-btn {
  margin-top: 8px;
  margin-bottom: 8px;
}

.navbar-btn.btn-sm {
  margin-top: 10px;
  margin-bottom: 10px;
}

.navbar-btn.btn-xs {
  margin-top: 14px;
  margin-bottom: 14px;
}

.navbar-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .navbar-text {
    float: left;
    margin-right: 15px;
    margin-left: 15px;
  }
}

@media (min-width: 768px) {
  .navbar-left {
    float: left !important;
  }

  .navbar-right {
    float: right !important;
    margin-right: -15px;
  }

  .navbar-right~.navbar-right {
    margin-right: 0;
  }
}

.navbar-default {
  background-color: #f8f8f8;
  border-color: #e7e7e7;
}

.navbar-default .navbar-brand {
  color: #777;
}

.navbar-default .navbar-brand:focus,
.navbar-default .navbar-brand:hover {
  color: #5e5e5e;
  background-color: transparent;
}

.navbar-default .navbar-text {
  color: #777;
}

.navbar-default .navbar-nav>li>a {
  color: #777;
}

.navbar-default .navbar-nav>li>a:focus,
.navbar-default .navbar-nav>li>a:hover {
  color: #333;
  background-color: transparent;
}

.navbar-default .navbar-nav>.active>a,
.navbar-default .navbar-nav>.active>a:focus,
.navbar-default .navbar-nav>.active>a:hover {
  color: #555;
  background-color: #e7e7e7;
}

.navbar-default .navbar-nav>.disabled>a,
.navbar-default .navbar-nav>.disabled>a:focus,
.navbar-default .navbar-nav>.disabled>a:hover {
  color: #ccc;
  background-color: transparent;
}

.navbar-default .navbar-toggle {
  border-color: #ddd;
}

.navbar-default .navbar-toggle:focus,
.navbar-default .navbar-toggle:hover {
  background-color: #ddd;
}

.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}

.navbar-default .navbar-collapse,
.navbar-default .navbar-form {
  border-color: #e7e7e7;
}

.navbar-default .navbar-nav>.open>a,
.navbar-default .navbar-nav>.open>a:focus,
.navbar-default .navbar-nav>.open>a:hover {
  color: #555;
  background-color: #e7e7e7;
}

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu>li>a {
    color: #777;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>li>a:hover {
    color: #333;
    background-color: transparent;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>.active>a,
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>.active>a:hover {
    color: #555;
    background-color: #e7e7e7;
  }

  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a,
  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:focus,
  .navbar-default .navbar-nav .open .dropdown-menu>.disabled>a:hover {
    color: #ccc;
    background-color: transparent;
  }
}

.navbar-default .navbar-link {
  color: #777;
}

.navbar-default .navbar-link:hover {
  color: #333;
}

.navbar-default .btn-link {
  color: #777;
}

.navbar-default .btn-link:focus,
.navbar-default .btn-link:hover {
  color: #333;
}

.navbar-default .btn-link[disabled]:focus,
.navbar-default .btn-link[disabled]:hover,
fieldset[disabled] .navbar-default .btn-link:focus,
fieldset[disabled] .navbar-default .btn-link:hover {
  color: #ccc;
}

.navbar-inverse {
  background-color: #222;
  border-color: #080808;
}

.navbar-inverse .navbar-brand {
  color: #9d9d9d;
}

.navbar-inverse .navbar-brand:focus,
.navbar-inverse .navbar-brand:hover {
  color: #fff;
  background-color: transparent;
}

.navbar-inverse .navbar-text {
  color: #9d9d9d;
}

.navbar-inverse .navbar-nav>li>a {
  color: #9d9d9d;
}

.navbar-inverse .navbar-nav>li>a:focus,
.navbar-inverse .navbar-nav>li>a:hover {
  color: #fff;
  background-color: transparent;
}

.navbar-inverse .navbar-nav>.active>a,
.navbar-inverse .navbar-nav>.active>a:focus,
.navbar-inverse .navbar-nav>.active>a:hover {
  color: #fff;
  background-color: #080808;
}

.navbar-inverse .navbar-nav>.disabled>a,
.navbar-inverse .navbar-nav>.disabled>a:focus,
.navbar-inverse .navbar-nav>.disabled>a:hover {
  color: #444;
  background-color: transparent;
}

.navbar-inverse .navbar-toggle {
  border-color: #333;
}

.navbar-inverse .navbar-toggle:focus,
.navbar-inverse .navbar-toggle:hover {
  background-color: #333;
}

.navbar-inverse .navbar-toggle .icon-bar {
  background-color: #fff;
}

.navbar-inverse .navbar-collapse,
.navbar-inverse .navbar-form {
  border-color: #101010;
}

.navbar-inverse .navbar-nav>.open>a,
.navbar-inverse .navbar-nav>.open>a:focus,
.navbar-inverse .navbar-nav>.open>a:hover {
  color: #fff;
  background-color: #080808;
}

@media (max-width: 767px) {
  .navbar-inverse .navbar-nav .open .dropdown-menu>.dropdown-header {
    border-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu .divider {
    background-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a {
    color: #9d9d9d;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu>li>a:hover {
    color: #fff;
    background-color: transparent;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.active>a:hover {
    color: #fff;
    background-color: #080808;
  }

  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:focus,
  .navbar-inverse .navbar-nav .open .dropdown-menu>.disabled>a:hover {
    color: #444;
    background-color: transparent;
  }
}

.navbar-inverse .navbar-link {
  color: #9d9d9d;
}

.navbar-inverse .navbar-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link {
  color: #9d9d9d;
}

.navbar-inverse .btn-link:focus,
.navbar-inverse .btn-link:hover {
  color: #fff;
}

.navbar-inverse .btn-link[disabled]:focus,
.navbar-inverse .btn-link[disabled]:hover,
fieldset[disabled] .navbar-inverse .btn-link:focus,
fieldset[disabled] .navbar-inverse .btn-link:hover {
  color: #444;
}

.breadcrumb {
  padding: 8px 15px;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
}

.breadcrumb>li {
  display: inline-block;
}

.breadcrumb>li+li:before {
  padding: 0 5px;
  color: #ccc;
  content: "/\00a0";
}

.breadcrumb>.active {
  color: #777;
}

.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

.pagination>li {
  display: inline;
}

.pagination>li>a,
.pagination>li>span {
  position: relative;
  float: left;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  color: #337ab7;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #ddd;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
  margin-left: 0;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination>li:last-child>a,
.pagination>li:last-child>span {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.pagination>li>a:focus,
.pagination>li>a:hover,
.pagination>li>span:focus,
.pagination>li>span:hover {
  z-index: 2;
  color: #23527c;
  background-color: #eee;
  border-color: #ddd;
}

.pagination>.active>a,
.pagination>.active>a:focus,
.pagination>.active>a:hover,
.pagination>.active>span,
.pagination>.active>span:focus,
.pagination>.active>span:hover {
  z-index: 3;
  color: #fff;
  cursor: default;
  background-color: #337ab7;
  border-color: #337ab7;
}

.pagination>.disabled>a,
.pagination>.disabled>a:focus,
.pagination>.disabled>a:hover,
.pagination>.disabled>span,
.pagination>.disabled>span:focus,
.pagination>.disabled>span:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
  border-color: #ddd;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
}

.pagination-lg>li:first-child>a,
.pagination-lg>li:first-child>span {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.pagination-lg>li:last-child>a,
.pagination-lg>li:last-child>span {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
}

.pagination-sm>li:first-child>a,
.pagination-sm>li:first-child>span {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.pagination-sm>li:last-child>a,
.pagination-sm>li:last-child>span {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.pager {
  padding-left: 0;
  margin: 20px 0;
  text-align: center;
  list-style: none;
}

.pager li {
  display: inline;
}

.pager li>a,
.pager li>span {
  display: inline-block;
  padding: 5px 14px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
}

.pager li>a:focus,
.pager li>a:hover {
  text-decoration: none;
  background-color: #eee;
}

.pager .next>a,
.pager .next>span {
  float: right;
}

.pager .previous>a,
.pager .previous>span {
  float: left;
}

.pager .disabled>a,
.pager .disabled>a:focus,
.pager .disabled>a:hover,
.pager .disabled>span {
  color: #777;
  cursor: not-allowed;
  background-color: #fff;
}

.label {
  display: inline;
  padding: 0.2em 0.6em 0.3em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
}

a.label:focus,
a.label:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.label:empty {
  display: none;
}

.btn .label {
  position: relative;
  top: -1px;
}

.label-default {
  background-color: #777;
}

.label-default[href]:focus,
.label-default[href]:hover {
  background-color: #5e5e5e;
}

.label-primary {
  background-color: #337ab7;
}

.label-primary[href]:focus,
.label-primary[href]:hover {
  background-color: #286090;
}

.label-success {
  background-color: #5cb85c;
}

.label-success[href]:focus,
.label-success[href]:hover {
  background-color: #449d44;
}

.label-info {
  background-color: #5bc0de;
}

.label-info[href]:focus,
.label-info[href]:hover {
  background-color: #31b0d5;
}

.label-warning {
  background-color: #f0ad4e;
}

.label-warning[href]:focus,
.label-warning[href]:hover {
  background-color: #ec971f;
}

.label-danger {
  background-color: #d9534f;
}

.label-danger[href]:focus,
.label-danger[href]:hover {
  background-color: #c9302c;
}

.badge {
  display: inline-block;
  min-width: 10px;
  padding: 3px 7px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  background-color: #777;
  border-radius: 10px;
}

.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.btn-group-xs>.btn .badge,
.btn-xs .badge {
  top: 0;
  padding: 1px 5px;
}

a.badge:focus,
a.badge:hover {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
}

.list-group-item.active>.badge,
.nav-pills>.active>a>.badge {
  color: #337ab7;
  background-color: #fff;
}

.list-group-item>.badge {
  float: right;
}

.list-group-item>.badge+.badge {
  margin-right: 5px;
}

.nav-pills>li>a>.badge {
  margin-left: 3px;
}

.jumbotron {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  color: inherit;
  background-color: #eee;
}

.jumbotron .h1,
.jumbotron h1 {
  color: inherit;
}

.jumbotron p {
  margin-bottom: 15px;
  font-size: 21px;
  font-weight: 200;
}

.jumbotron>hr {
  border-top-color: #d5d5d5;
}

.container .jumbotron,
.container-fluid .jumbotron {
  padding-right: 15px;
  padding-left: 15px;
  border-radius: 6px;
}

.jumbotron .container {
  max-width: 100%;
}

@media screen and (min-width: 768px) {
  .jumbotron {
    padding-top: 48px;
    padding-bottom: 48px;
  }

  .container .jumbotron,
  .container-fluid .jumbotron {
    padding-right: 60px;
    padding-left: 60px;
  }

  .jumbotron .h1,
  .jumbotron h1 {
    font-size: 63px;
  }
}

.thumbnail {
  display: block;
  padding: 4px;
  margin-bottom: 20px;
  line-height: 1.42857143;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-transition: border 0.2s ease-in-out;
  -o-transition: border 0.2s ease-in-out;
  transition: border 0.2s ease-in-out;
}

.thumbnail a>img,
.thumbnail>img {
  margin-right: auto;
  margin-left: auto;
}

a.thumbnail.active,
a.thumbnail:focus,
a.thumbnail:hover {
  border-color: #337ab7;
}

.thumbnail .caption {
  padding: 9px;
  color: #333;
}

.alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  border-radius: 4px;
}

.alert h4 {
  margin-top: 0;
  color: inherit;
}

.alert .alert-link {
  font-weight: 700;
}

.alert>p,
.alert>ul {
  margin-bottom: 0;
}

.alert>p+p {
  margin-top: 5px;
}

.alert-dismissable,
.alert-dismissible {
  padding-right: 35px;
}

.alert-dismissable .close,
.alert-dismissible .close {
  position: relative;
  top: -2px;
  right: -21px;
  color: inherit;
}

.alert-success {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.alert-success hr {
  border-top-color: #c9e2b3;
}

.alert-success .alert-link {
  color: #2b542c;
}

.alert-info {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.alert-info hr {
  border-top-color: #a6e1ec;
}

.alert-info .alert-link {
  color: #245269;
}

.alert-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.alert-warning hr {
  border-top-color: #f7e1b5;
}

.alert-warning .alert-link {
  color: #66512c;
}

.alert-danger {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.alert-danger hr {
  border-top-color: #e4b9c0;
}

.alert-danger .alert-link {
  color: #843534;
}

@-webkit-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@-o-keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

@keyframes progress-bar-stripes {
  from {
    background-position: 40px 0;
  }

  to {
    background-position: 0 0;
  }
}

.progress {
  height: 20px;
  margin-bottom: 20px;
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.progress-bar {
  float: left;
  width: 0;
  height: 100%;
  font-size: 12px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  background-color: #337ab7;
  -webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
  -webkit-transition: width 0.6s ease;
  -o-transition: width 0.6s ease;
  transition: width 0.6s ease;
}

.progress-bar-striped,
.progress-striped .progress-bar {
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: -o-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  -webkit-background-size: 40px 40px;
  background-size: 40px 40px;
}

.progress-bar.active,
.progress.active .progress-bar {
  -webkit-animation: progress-bar-stripes 2s linear infinite;
  -o-animation: progress-bar-stripes 2s linear infinite;
  animation: progress-bar-stripes 2s linear infinite;
}

.progress-bar-success {
  background-color: #5cb85c;
}

.progress-striped .progress-bar-success {
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: -o-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
}

.progress-bar-info {
  background-color: #5bc0de;
}

.progress-striped .progress-bar-info {
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: -o-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
}

.progress-bar-warning {
  background-color: #f0ad4e;
}

.progress-striped .progress-bar-warning {
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: -o-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
}

.progress-bar-danger {
  background-color: #d9534f;
}

.progress-striped .progress-bar-danger {
  background-image: -webkit-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: -o-linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
  background-image: linear-gradient(45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent);
}

.media {
  margin-top: 15px;
}

.media:first-child {
  margin-top: 0;
}

.media,
.media-body {
  overflow: hidden;
  zoom: 1;
}

.media-body {
  width: 10000px;
}

.media-object {
  display: block;
}

.media-object.img-thumbnail {
  max-width: none;
}

.media-right,
.media>.pull-right {
  padding-left: 10px;
}

.media-left,
.media>.pull-left {
  padding-right: 10px;
}

.media-body,
.media-left,
.media-right {
  display: table-cell;
  vertical-align: top;
}

.media-middle {
  vertical-align: middle;
}

.media-bottom {
  vertical-align: bottom;
}

.media-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.media-list {
  padding-left: 0;
  list-style: none;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.list-group-item:first-child {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.list-group-item:last-child {
  margin-bottom: 0;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

a.list-group-item,
button.list-group-item {
  color: #555;
}

a.list-group-item .list-group-item-heading,
button.list-group-item .list-group-item-heading {
  color: #333;
}

a.list-group-item:focus,
a.list-group-item:hover,
button.list-group-item:focus,
button.list-group-item:hover {
  color: #555;
  text-decoration: none;
  background-color: #f5f5f5;
}

button.list-group-item {
  width: 100%;
  text-align: left;
}

.list-group-item.disabled,
.list-group-item.disabled:focus,
.list-group-item.disabled:hover {
  color: #777;
  cursor: not-allowed;
  background-color: #eee;
}

.list-group-item.disabled .list-group-item-heading,
.list-group-item.disabled:focus .list-group-item-heading,
.list-group-item.disabled:hover .list-group-item-heading {
  color: inherit;
}

.list-group-item.disabled .list-group-item-text,
.list-group-item.disabled:focus .list-group-item-text,
.list-group-item.disabled:hover .list-group-item-text {
  color: #777;
}

.list-group-item.active,
.list-group-item.active:focus,
.list-group-item.active:hover {
  z-index: 2;
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.list-group-item.active .list-group-item-heading,
.list-group-item.active .list-group-item-heading>.small,
.list-group-item.active .list-group-item-heading>small,
.list-group-item.active:focus .list-group-item-heading,
.list-group-item.active:focus .list-group-item-heading>.small,
.list-group-item.active:focus .list-group-item-heading>small,
.list-group-item.active:hover .list-group-item-heading,
.list-group-item.active:hover .list-group-item-heading>.small,
.list-group-item.active:hover .list-group-item-heading>small {
  color: inherit;
}

.list-group-item.active .list-group-item-text,
.list-group-item.active:focus .list-group-item-text,
.list-group-item.active:hover .list-group-item-text {
  color: #c7ddef;
}

.list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

a.list-group-item-success,
button.list-group-item-success {
  color: #3c763d;
}

a.list-group-item-success .list-group-item-heading,
button.list-group-item-success .list-group-item-heading {
  color: inherit;
}

a.list-group-item-success:focus,
a.list-group-item-success:hover,
button.list-group-item-success:focus,
button.list-group-item-success:hover {
  color: #3c763d;
  background-color: #d0e9c6;
}

a.list-group-item-success.active,
a.list-group-item-success.active:focus,
a.list-group-item-success.active:hover,
button.list-group-item-success.active,
button.list-group-item-success.active:focus,
button.list-group-item-success.active:hover {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

a.list-group-item-info,
button.list-group-item-info {
  color: #31708f;
}

a.list-group-item-info .list-group-item-heading,
button.list-group-item-info .list-group-item-heading {
  color: inherit;
}

a.list-group-item-info:focus,
a.list-group-item-info:hover,
button.list-group-item-info:focus,
button.list-group-item-info:hover {
  color: #31708f;
  background-color: #c4e3f3;
}

a.list-group-item-info.active,
a.list-group-item-info.active:focus,
a.list-group-item-info.active:hover,
button.list-group-item-info.active,
button.list-group-item-info.active:focus,
button.list-group-item-info.active:hover {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

a.list-group-item-warning,
button.list-group-item-warning {
  color: #8a6d3b;
}

a.list-group-item-warning .list-group-item-heading,
button.list-group-item-warning .list-group-item-heading {
  color: inherit;
}

a.list-group-item-warning:focus,
a.list-group-item-warning:hover,
button.list-group-item-warning:focus,
button.list-group-item-warning:hover {
  color: #8a6d3b;
  background-color: #faf2cc;
}

a.list-group-item-warning.active,
a.list-group-item-warning.active:focus,
a.list-group-item-warning.active:hover,
button.list-group-item-warning.active,
button.list-group-item-warning.active:focus,
button.list-group-item-warning.active:hover {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

a.list-group-item-danger,
button.list-group-item-danger {
  color: #a94442;
}

a.list-group-item-danger .list-group-item-heading,
button.list-group-item-danger .list-group-item-heading {
  color: inherit;
}

a.list-group-item-danger:focus,
a.list-group-item-danger:hover,
button.list-group-item-danger:focus,
button.list-group-item-danger:hover {
  color: #a94442;
  background-color: #ebcccc;
}

a.list-group-item-danger.active,
a.list-group-item-danger.active:focus,
a.list-group-item-danger.active:hover,
button.list-group-item-danger.active,
button.list-group-item-danger.active:focus,
button.list-group-item-danger.active:hover {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.list-group-item-heading {
  margin-top: 0;
  margin-bottom: 5px;
}

.list-group-item-text {
  margin-bottom: 0;
  line-height: 1.3;
}

.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.05);
}

.panel-body {
  padding: 15px;
}

.panel-heading {
  padding: 10px 15px;
  border-bottom: 1px solid transparent;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel-heading>.dropdown .dropdown-toggle {
  color: inherit;
}

.panel-title {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: inherit;
}

.panel-title>.small,
.panel-title>.small>a,
.panel-title>a,
.panel-title>small,
.panel-title>small>a {
  color: inherit;
}

.panel-footer {
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-top: 1px solid #ddd;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel>.list-group,
.panel>.panel-collapse>.list-group {
  margin-bottom: 0;
}

.panel>.list-group .list-group-item,
.panel>.panel-collapse>.list-group .list-group-item {
  border-width: 1px 0;
  border-radius: 0;
}

.panel>.list-group:first-child .list-group-item:first-child,
.panel>.panel-collapse>.list-group:first-child .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel>.list-group:last-child .list-group-item:last-child,
.panel>.panel-collapse>.list-group:last-child .list-group-item:last-child {
  border-bottom: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel>.panel-heading+.panel-collapse>.list-group .list-group-item:first-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.panel-heading+.list-group .list-group-item:first-child {
  border-top-width: 0;
}

.list-group+.panel-footer {
  border-top-width: 0;
}

.panel>.panel-collapse>.table,
.panel>.table,
.panel>.table-responsive>.table {
  margin-bottom: 0;
}

.panel>.panel-collapse>.table caption,
.panel>.table caption,
.panel>.table-responsive>.table caption {
  padding-right: 15px;
  padding-left: 15px;
}

.panel>.table-responsive:first-child>.table:first-child,
.panel>.table:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:first-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:first-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:first-child {
  border-top-left-radius: 3px;
}

.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table-responsive:first-child>.table:first-child>thead:first-child>tr:first-child th:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child td:last-child,
.panel>.table:first-child>tbody:first-child>tr:first-child th:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child td:last-child,
.panel>.table:first-child>thead:first-child>tr:first-child th:last-child {
  border-top-right-radius: 3px;
}

.panel>.table-responsive:last-child>.table:last-child,
.panel>.table:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child {
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:first-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:first-child {
  border-bottom-left-radius: 3px;
}

.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table-responsive:last-child>.table:last-child>tfoot:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tbody:last-child>tr:last-child th:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child td:last-child,
.panel>.table:last-child>tfoot:last-child>tr:last-child th:last-child {
  border-bottom-right-radius: 3px;
}

.panel>.panel-body+.table,
.panel>.panel-body+.table-responsive,
.panel>.table+.panel-body,
.panel>.table-responsive+.panel-body {
  border-top: 1px solid #ddd;
}

.panel>.table>tbody:first-child>tr:first-child td,
.panel>.table>tbody:first-child>tr:first-child th {
  border-top: 0;
}

.panel>.table-bordered,
.panel>.table-responsive>.table-bordered {
  border: 0;
}

.panel>.table-bordered>tbody>tr>td:first-child,
.panel>.table-bordered>tbody>tr>th:first-child,
.panel>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-bordered>thead>tr>td:first-child,
.panel>.table-bordered>thead>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:first-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:first-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:first-child {
  border-left: 0;
}

.panel>.table-bordered>tbody>tr>td:last-child,
.panel>.table-bordered>tbody>tr>th:last-child,
.panel>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-bordered>thead>tr>td:last-child,
.panel>.table-bordered>thead>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tbody>tr>th:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>td:last-child,
.panel>.table-responsive>.table-bordered>tfoot>tr>th:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>td:last-child,
.panel>.table-responsive>.table-bordered>thead>tr>th:last-child {
  border-right: 0;
}

.panel>.table-bordered>tbody>tr:first-child>td,
.panel>.table-bordered>tbody>tr:first-child>th,
.panel>.table-bordered>thead>tr:first-child>td,
.panel>.table-bordered>thead>tr:first-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:first-child>th,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>td,
.panel>.table-responsive>.table-bordered>thead>tr:first-child>th {
  border-bottom: 0;
}

.panel>.table-bordered>tbody>tr:last-child>td,
.panel>.table-bordered>tbody>tr:last-child>th,
.panel>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-bordered>tfoot>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tbody>tr:last-child>th,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>td,
.panel>.table-responsive>.table-bordered>tfoot>tr:last-child>th {
  border-bottom: 0;
}

.panel>.table-responsive {
  margin-bottom: 0;
  border: 0;
}

.panel-group {
  margin-bottom: 20px;
}

.panel-group .panel {
  margin-bottom: 0;
  border-radius: 4px;
}

.panel-group .panel+.panel {
  margin-top: 5px;
}

.panel-group .panel-heading {
  border-bottom: 0;
}

.panel-group .panel-heading+.panel-collapse>.list-group,
.panel-group .panel-heading+.panel-collapse>.panel-body {
  border-top: 1px solid #ddd;
}

.panel-group .panel-footer {
  border-top: 0;
}

.panel-group .panel-footer+.panel-collapse .panel-body {
  border-bottom: 1px solid #ddd;
}

.panel-default {
  border-color: #ddd;
}

.panel-default>.panel-heading {
  color: #333;
  background-color: #f5f5f5;
  border-color: #ddd;
}

.panel-default>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ddd;
}

.panel-default>.panel-heading .badge {
  color: #f5f5f5;
  background-color: #333;
}

.panel-default>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ddd;
}

.panel-primary {
  border-color: #337ab7;
}

.panel-primary>.panel-heading {
  color: #fff;
  background-color: #337ab7;
  border-color: #337ab7;
}

.panel-primary>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #337ab7;
}

.panel-primary>.panel-heading .badge {
  color: #337ab7;
  background-color: #fff;
}

.panel-primary>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #337ab7;
}

.panel-success {
  border-color: #d6e9c6;
}

.panel-success>.panel-heading {
  color: #3c763d;
  background-color: #dff0d8;
  border-color: #d6e9c6;
}

.panel-success>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #d6e9c6;
}

.panel-success>.panel-heading .badge {
  color: #dff0d8;
  background-color: #3c763d;
}

.panel-success>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #d6e9c6;
}

.panel-info {
  border-color: #bce8f1;
}

.panel-info>.panel-heading {
  color: #31708f;
  background-color: #d9edf7;
  border-color: #bce8f1;
}

.panel-info>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #bce8f1;
}

.panel-info>.panel-heading .badge {
  color: #d9edf7;
  background-color: #31708f;
}

.panel-info>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #bce8f1;
}

.panel-warning {
  border-color: #faebcc;
}

.panel-warning>.panel-heading {
  color: #8a6d3b;
  background-color: #fcf8e3;
  border-color: #faebcc;
}

.panel-warning>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #faebcc;
}

.panel-warning>.panel-heading .badge {
  color: #fcf8e3;
  background-color: #8a6d3b;
}

.panel-warning>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #faebcc;
}

.panel-danger {
  border-color: #ebccd1;
}

.panel-danger>.panel-heading {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
}

.panel-danger>.panel-heading+.panel-collapse>.panel-body {
  border-top-color: #ebccd1;
}

.panel-danger>.panel-heading .badge {
  color: #f2dede;
  background-color: #a94442;
}

.panel-danger>.panel-footer+.panel-collapse>.panel-body {
  border-bottom-color: #ebccd1;
}

.embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

.embed-responsive .embed-responsive-item,
.embed-responsive embed,
.embed-responsive iframe,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

.embed-responsive-4by3 {
  padding-bottom: 75%;
}

.well {
  min-height: 20px;
  padding: 19px;
  margin-bottom: 20px;
  background-color: #f5f5f5;
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.05);
}

.well blockquote {
  border-color: #ddd;
  border-color: rgba(0, 0, 0, 0.15);
}

.well-lg {
  padding: 24px;
  border-radius: 6px;
}

.well-sm {
  padding: 9px;
  border-radius: 3px;
}

.close {
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2;
}

.close:focus,
.close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

button.close {
  -webkit-appearance: none;
  padding: 0;
  cursor: pointer;
  background: 0 0;
  border: 0;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  outline: 0;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.42857143;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer .btn+.btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn+.btn {
  margin-left: -1px;
}

.modal-footer .btn-block+.btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width:70%;
    margin: 30px auto;
    margin-top: 50px;
  }

  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }

  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  filter: alpha(opacity=0);
  opacity: 0;
  line-break: auto;
}

.tooltip.in {
  filter: alpha(opacity=90);
  opacity: 0.9;
}

.tooltip.top {
  padding: 5px 0;
  margin-top: -3px;
}

.tooltip.right {
  padding: 0 5px;
  margin-left: 3px;
}

.tooltip.bottom {
  padding: 5px 0;
  margin-top: 3px;
}

.tooltip.left {
  padding: 0 5px;
  margin-left: -3px;
}

.tooltip-inner {
  max-width: 500px;
  padding: 3px 8px;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 4px;
}

.tooltip-arrow {
  position: absolute;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.tooltip.top .tooltip-arrow {
  bottom: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-left .tooltip-arrow {
  right: 5px;
  bottom: 0;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.top-right .tooltip-arrow {
  bottom: 0;
  left: 5px;
  margin-bottom: -5px;
  border-width: 5px 5px 0;
  border-top-color: #000;
}

.tooltip.right .tooltip-arrow {
  top: 50%;
  left: 0;
  margin-top: -5px;
  border-width: 5px 5px 5px 0;
  border-right-color: #000;
}

.tooltip.left .tooltip-arrow {
  top: 50%;
  right: 0;
  margin-top: -5px;
  border-width: 5px 0 5px 5px;
  border-left-color: #000;
}

.tooltip.bottom .tooltip-arrow {
  top: 0;
  left: 50%;
  margin-left: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-left .tooltip-arrow {
  top: 0;
  right: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.tooltip.bottom-right .tooltip-arrow {
  top: 0;
  left: 5px;
  margin-top: -5px;
  border-width: 0 5px 5px;
  border-bottom-color: #000;
}

.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  max-width: 276px;
  padding: 1px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.42857143;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  word-wrap: normal;
  white-space: normal;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  line-break: auto;
}

.popover.top {
  margin-top: -10px;
}

.popover.right {
  margin-left: 10px;
}

.popover.bottom {
  margin-top: 10px;
}

.popover.left {
  margin-left: -10px;
}

.popover-title {
  padding: 8px 14px;
  margin: 0;
  font-size: 14px;
  background-color: #f7f7f7;
  border-bottom: 1px solid #ebebeb;
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

.popover>.arrow,
.popover>.arrow:after {
  position: absolute;
  display: block;
  width: 0;
  height: 0;
  border-color: transparent;
  border-style: solid;
}

.popover>.arrow {
  border-width: 11px;
}

.popover>.arrow:after {
  content: "";
  border-width: 10px;
}

.popover.top>.arrow {
  bottom: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-color: #999;
  border-top-color: rgba(0, 0, 0, 0.25);
  border-bottom-width: 0;
}

.popover.top>.arrow:after {
  bottom: 1px;
  margin-left: -10px;
  content: " ";
  border-top-color: #fff;
  border-bottom-width: 0;
}

.popover.right>.arrow {
  top: 50%;
  left: -11px;
  margin-top: -11px;
  border-right-color: #999;
  border-right-color: rgba(0, 0, 0, 0.25);
  border-left-width: 0;
}

.popover.right>.arrow:after {
  bottom: -10px;
  left: 1px;
  content: " ";
  border-right-color: #fff;
  border-left-width: 0;
}

.popover.bottom>.arrow {
  top: -11px;
  left: 50%;
  margin-left: -11px;
  border-top-width: 0;
  border-bottom-color: #999;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}

.popover.bottom>.arrow:after {
  top: 1px;
  margin-left: -10px;
  content: " ";
  border-top-width: 0;
  border-bottom-color: #fff;
}

.popover.left>.arrow {
  top: 50%;
  right: -11px;
  margin-top: -11px;
  border-right-width: 0;
  border-left-color: #999;
  border-left-color: rgba(0, 0, 0, 0.25);
}

.popover.left>.arrow:after {
  right: 1px;
  bottom: -10px;
  content: " ";
  border-right-width: 0;
  border-left-color: #fff;
}

.carousel {
  position: relative;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-inner>.item {
  position: relative;
  display: none;
  -webkit-transition: 0.6s ease-in-out left;
  -o-transition: 0.6s ease-in-out left;
  transition: 0.6s ease-in-out left;
}

.carousel-inner>.item>a>img,
.carousel-inner>.item>img {
  line-height: 1;
}

@media all and (transform-3d),
(-webkit-transform-3d) {
  .carousel-inner>.item {
    -webkit-transition: -webkit-transform 0.6s ease-in-out;
    -o-transition: -o-transform 0.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }

  .carousel-inner>.item.active.right,
  .carousel-inner>.item.next {
    left: 0;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
  }

  .carousel-inner>.item.active.left,
  .carousel-inner>.item.prev {
    left: 0;
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }

  .carousel-inner>.item.active,
  .carousel-inner>.item.next.left,
  .carousel-inner>.item.prev.right {
    left: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.carousel-inner>.active,
.carousel-inner>.next,
.carousel-inner>.prev {
  display: block;
}

.carousel-inner>.active {
  left: 0;
}

.carousel-inner>.next,
.carousel-inner>.prev {
  position: absolute;
  top: 0;
  width: 100%;
}

.carousel-inner>.next {
  left: 100%;
}

.carousel-inner>.prev {
  left: -100%;
}

.carousel-inner>.next.left,
.carousel-inner>.prev.right {
  left: 0;
}

.carousel-inner>.active.left {
  left: -100%;
}

.carousel-inner>.active.right {
  left: 100%;
}

.carousel-control {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 15%;
  font-size: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  background-color: rgba(0, 0, 0, 0);
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.carousel-control.left {
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0.0001)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.0001) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#80000000', endColorstr='#00000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control.right {
  right: 0;
  left: auto;
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.0001)), to(rgba(0, 0, 0, 0.5)));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.0001) 0, rgba(0, 0, 0, 0.5) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#80000000', GradientType=1);
  background-repeat: repeat-x;
}

.carousel-control:focus,
.carousel-control:hover {
  color: #fff;
  text-decoration: none;
  filter: alpha(opacity=90);
  outline: 0;
  opacity: 0.9;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next,
.carousel-control .icon-prev {
  position: absolute;
  top: 50%;
  z-index: 5;
  display: inline-block;
  margin-top: -10px;
}

.carousel-control .glyphicon-chevron-left,
.carousel-control .icon-prev {
  left: 50%;
  margin-left: -10px;
}

.carousel-control .glyphicon-chevron-right,
.carousel-control .icon-next {
  right: 50%;
  margin-right: -10px;
}

.carousel-control .icon-next,
.carousel-control .icon-prev {
  width: 20px;
  height: 20px;
  font-family: serif;
  line-height: 1;
}

.carousel-control .icon-prev:before {
  content: "\2039";
}

.carousel-control .icon-next:before {
  content: "\203a";
}

.carousel-indicators {
  position: absolute;
  bottom: 10px;
  left: 50%;
  z-index: 15;
  width: 60%;
  padding-left: 0;
  margin-left: -30%;
  text-align: center;
  list-style: none;
}

.carousel-indicators li {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 1px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #0009;
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #fff;
  border-radius: 10px;
}

.carousel-indicators .active {
  width: 12px;
  height: 12px;
  margin: 0;
  background-color: #fff;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 20px;
  left: 15%;
  z-index: 10;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
}

.carousel-caption .btn {
  text-shadow: none;
}

@media screen and (min-width: 768px) {

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next,
  .carousel-control .icon-prev {
    width: 30px;
    height: 30px;
    margin-top: -10px;
    font-size: 30px;
  }

  .carousel-control .glyphicon-chevron-left,
  .carousel-control .icon-prev {
    margin-left: -10px;
  }

  .carousel-control .glyphicon-chevron-right,
  .carousel-control .icon-next {
    margin-right: -10px;
  }

  .carousel-caption {
    right: 20%;
    left: 20%;
    padding-bottom: 30px;
  }

  .carousel-indicators {
    bottom: 20px;
  }
}

.btn-group-vertical>.btn-group:after,
.btn-group-vertical>.btn-group:before,
.btn-toolbar:after,
.btn-toolbar:before,
.clearfix:after,
.clearfix:before,
.container-fluid:after,
.container-fluid:before,
.container:after,
.container:before,
.dl-horizontal dd:after,
.dl-horizontal dd:before,
.form-horizontal .form-group:after,
.form-horizontal .form-group:before,
.modal-footer:after,
.modal-footer:before,
.modal-header:after,
.modal-header:before,
.nav:after,
.nav:before,
.navbar-collapse:after,
.navbar-collapse:before,
.navbar-header:after,
.navbar-header:before,
.navbar:after,
.navbar:before,
.pager:after,
.pager:before,
.panel-body:after,
.panel-body:before,
.row:after,
.row:before {
  display: table;
  content: " ";
}

.btn-group-vertical>.btn-group:after,
.btn-toolbar:after,
.clearfix:after,
.container-fluid:after,
.container:after,
.dl-horizontal dd:after,
.form-horizontal .form-group:after,
.modal-footer:after,
.modal-header:after,
.nav:after,
.navbar-collapse:after,
.navbar-header:after,
.navbar:after,
.pager:after,
.panel-body:after,
.row:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: inline-block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

@-ms-viewport {
  width: device-width;
}

.visible-lg,
.visible-md,
.visible-sm,
.visible-xs {
  display: none !important;
}

.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }

  table.visible-xs {
    display: table !important;
  }

  tr.visible-xs {
    display: table-row !important;
  }

  td.visible-xs,
  th.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }

  .third-corp-row {
    h2 {
      padding: 0 !important;
    }
  }

  table.visible-sm {
    display: table !important;
  }

  tr.visible-sm {
    display: table-row !important;
  }

  td.visible-sm,
  th.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }

  table.visible-md {
    display: table !important;
  }

  tr.visible-md {
    display: table-row !important;
  }

  td.visible-md,
  th.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }

  table.visible-lg {
    display: table !important;
  }

  tr.visible-lg {
    display: table-row !important;
  }

  td.visible-lg,
  th.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }

  table.visible-print {
    display: table !important;
  }

  tr.visible-print {
    display: table-row !important;
  }

  td.visible-print,
  th.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Webkit browsers like Safari and Chrome */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

////////////////////////////////////////NEW CSS//////////////////////////////
/* width */

::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */

// ::-webkit-scrollbar-track {
//   box-shadow: inset 5px 5px 5px #f2fbfc;
// }

/* Handle */

::-webkit-scrollbar-thumb {
  background: grey;
  // border-radius: 8px;
}

/* Handle on hover */

// ::-webkit-scrollbar-thumb:hover {
//   background: $orangecolor;
// }
.margin-0 {
  margin: 0 !important;
}

.margin-0-auto {
  margin: 0 auto !important;
}

.margin-10px {
  margin: 10px !important;
}

.validation-color {
  color: rgb(218, 0, 0) !important;
  text-align: center;
}

///////////////////////////////////MENU CSS/////////////////////////////
.phocket_menu {
  position: fixed;
  top: 70px;
}

///////////////////////////////////MENU CSS/////////////////////////////
.home_main_tagline {
  color: $orangecolor;
  font-size: 60px;
  font-weight: bold;
  margin: 0;
}

.home-calc-btn,
.apply-button {
  border: 2px solid $bluecolor;
  color: $bluecolor;
  background: $whitecolor !important;
  display: inline-block;
  font-weight: bold;
  padding: 0 10px;
  height: 50px;
  line-height: 1;
  border-radius: 5px;
  margin: 10px 15px;
  cursor: pointer;
  box-shadow: 5px 5px 10px #ccc;

  &:hover {
    color: $orangecolor;
    border: 2px solid $orangecolor;
    box-shadow: none;
  }

  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.phocket_menu {
  position: fixed;
  top: 70px;
}

.phocket_header_website {
  height: 70px;
  top: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: $whitecolor;
  border-bottom: solid 1px $bluecolor;
  line-height: 70px;
  z-index: 999;

  a {
    text-decoration: none;
    color: $bluecolor;
  }

  img,
  svg {
    margin-top: 5px;
    display: block;
    width: 200px;
    max-width: 100%;
    cursor: pointer;
  }

  label {
    line-height: 1;
    display: block;
    margin-top: -8px;
    margin-bottom: 0px;
    font-size: 10px;
    width: 200px;
    text-align: right;
    color: #3d3d3d;
  }
}

.phocket_menuBar {
  position: fixed;
  right: 0;

  a {
    color: $bluecolor;
    font-weight: bold;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  #menu-bar {
    font-size: 32px;
  }

  #close-btn {
    font-size: 50px;
  }
}

.menu-content {
  width: 300px;
  background: $whitecolor;
  position: fixed;
  right: 0;
  top: 70px;
  bottom: 0;
  z-index: 999;
  border-left: solid 1px $bluecolor;

  span {
    width: 25px !important;
  }

  ul {
    list-style-type: none;
    margin-top: 25px;

    li {
      a {
        font-size: 18px;
        font-weight: bold;
        line-height: 2.3;
        color: $bluecolor;
        cursor: pointer;

        &:hover {
          text-decoration: none;
          color: $orangecolor;
        }
      }
    }
  }
}

.orange-text {
  color: $orangecolor;
}

.darkBlue-text {
  color: $bluecolor;
}

.darkGreen-text {
  color: green;
}

.menu_animateLeft {
  animation: animateLeft 0.5s !important;
  -webkit-animation: animateLeft 0.5s !important;
}

@keyframes animateLeft {
  from {
    right: -300px;
  }

  to {
    right: 0;
  }
}

@-webkit-keyframes animateLeft {
  from {
    right: -300px;
  }

  to {
    right: 0;
  }
}

.menu_animateRight {
  animation: animateRight 0.8s !important;
  -webkit-animation: animateRight 0.8s !important;
}

@keyframes rotateInUpRight {
  from {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate3d(0, 0, 1, -90deg);
    transform: rotate3d(0, 0, 1, -90deg);
    opacity: 0;
  }

  to {
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

.rotateInUpRight {
  -webkit-animation-name: rotateInUpRight;
  animation-name: rotateInUpRight;
}

@keyframes animateRight {
  from {
    right: 0;
  }

  to {
    right: -100%;
  }
}

@-webkit-keyframes animateRight {
  from {
    right: 0;
  }

  to {
    right: -100%;
  }
}

////////////////////////////////////////////////////////////////////////
/////////////////////////////HOW IT WORKS CSS//////////////////////////////////
.phocket-how-it-works {
  .row {
    margin: 0 5%;

    p {
      margin-top: 15px;
      line-height: 1.5;
      margin-left: 60px;
    }
  }

  .working-arrow-first {
    position: absolute;
    right: 135px;
    margin-top: 10px;
  }

  .working-arrow-second {
    margin-top: 20px;

    .arw-img-2 {
      position: absolute;
      left: 155px;
      margin-top: -30px;
    }
  }

  .working-third-row {
    margin: 0;
  }

  .working-img-2 {
    padding-right: 0;
    margin-top: 60px;
    text-align: right;
  }

  .serialNum {
    border: solid 2px $bluecolor;
    border-radius: 50%;
    padding: 4px 15px;
    margin-right: 15px;
    color: $bluecolor;
  }

  .working-step-1 {
    h2 {
      margin: 0;
    }
  }

  .working-step-2 {
    padding-left: 0;
  }
}

@media (max-width: 991px) {

  .working-first-row,
  .working-second-row,
  .working-third-row {
    margin-bottom: 30px !important;
    text-align: center !important;
    margin: 8% 0 0 0 !important;

    p {
      margin: 20px 0 0 0 !important;
    }

    h2 {
      font-size: 20px !important;
    }
  }

  .working-img-2 {
    margin: 0 !important;
  }

  .working-img-1,
  .working-img-2,
  .working-img-3 {
    text-align: center !important;
  }

  .working-third-row {
    .working-img-3 {
      padding: 0 15px !important;
    }
  }

  .serialNum {
    padding: 5px 12px !important;
  }
}

//////////////////////////////////END OF HOW IT WORKS////////////////////////////////
.padding-left-5perc {
  padding-left: 5%;
}

.lines-img {
  background: url(./images/lines.png) center no-repeat;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  height: 100px;
}

.margin-bottom-2perc {
  margin-bottom: 2%;
}

.margin-bottom-6perc {
  margin-bottom: 6% !important;
}

.blue-text {
  color: $bluecolor !important;
}

.white-text {
  color: $whitecolor;
}

.padding-right-25px {
  padding-right: 25px;
}

.width-100perc {
  width: 100%;
}

.width-50perc {
  width: 50% !important;
}

.width-30px {
  width: 30px !important;
}

.width-90perc {
  width: 90%;
  margin: 0 auto;
}

.main_tagline {
  padding-top: 1%;
  font-weight: bold;
  color: $bluecolor;
}

.phocket_tagline {
  img {
    max-height: 450px;
    max-width: 100%;
  }

  h4 {
    font-size: 25px;
    line-height: 2;
  }
}

.phocket_feature {
  .features_content {
    font-size: 22px;
    text-align: center;
    font-weight: bold;
    padding: 0 10%;
  }

  .feature_row {
    p {
      font-weight: bold;
    }
  }
}

.padding-15px {
  padding-top: 15px;
}

.phocket_footer {
  margin-top: 60px;
  background-image: linear-gradient(to right, #39a7fa, #28589e);
  color: $whitecolor;

  h4 {
    font-weight: bold;
  }

  a {
    cursor: pointer;
    color: $whitecolor !important;

    &:hover {
      text-decoration: underline $whitecolor;
    }
  }

  .list-unstyled>li {
    line-height: 2;
  }

  .width-height-25px {
    width: 25px;
    height: 25px;
  }

  .width-height-25px {
    width: 25px;
    height: 25px;
  }

  .margin-top0-btm20 {
    margin: 0 0 10px 0;
  }

  .margin-top20-auto {
    margin: 20px auto 0 auto;
  }
}

// .phocket_footer {
//   margin-top: 60px;
//   background: url('./images/footer.png') no-repeat center;
//   -webkit-background-size: 100% 100%;
//   -moz-background-size: 100% 100%;
//   -o-background-size: 100% 100%;
//   background-size: 100% 100%;
//   text-align: center;
//   color: $bluecolor;
//   .main_tagline {
//     padding-top: 5%;
//   }
//   hr {
//     margin: 0 !important;
//     border: solid 0.5px $bluecolor;
//   }
//   a {
//     color: $bluecolor !important;
//     cursor: pointer;
//     &:hover {
//       color: $orangecolor !important;
//       text-decoration: none;
//     }
//   }
//   .phocket_footer_icon {
//     img {
//       width: 200px;
//     }
//     label {
//       line-height: 2;
//       display: block;
//       margin-bottom: 0px;
//       margin-left: 100px;
//       font-size: 10px;
//       text-align: center;
//       color: #3d3d3d;
//     }
//   }
//   .footer-foot {
//     margin: 10px auto;
//   }
//   h5 {
//     padding: 0 !important;
//     font-weight: normal !important;
//     img {
//       width: 25px;
//       height: 25px;
//       margin-right: 20px;
//     }

//   }
//   .icon_footer_ul,
//   .link_footer_ul {
//     color: $bluecolor;
//     font-weight: bold;
//     text-align: center;
//     ul {
//       margin-top: 20px;
//       padding: 0;
//       li {
//         display: inline-block;
//         margin-right: 10px;
//         margin-left: 10px;
//         img {
//           width: 25px;
//           height: 25px;
//           margin-right: 20px;
//         }
//       }
//     }
//   }
// }

.relative_position {
  position: relative;
}

.absolute_position {
  position: absolute;
}

.light-blue-bg {
  background: #d6ecf2;
}

.light-orange-bg {
  background: #f4d7c3;
}

.light-green-bg {
  background: #c3fcca;
}

.phocket_process {
  height: 100%;

  p {
    padding: 6% 15%;
  }
}

.phocket_working {
  height: 100%;
  width: 100%;
  overflow: hidden;

  .tab-img {
    z-index: 1;
    left: -30px;
    top: 18%;
    -webkit-animation: mymoveTop 2s infinite;
    -webkit-animation-timing-function: linear;
    animation: mymoveTop 2s infinite;
    animation-timing-function: linear;
  }

  .phone-img {
    z-index: 1;
    right: -200px;
    top: 12%;
    -webkit-animation: mymoveTop 1s infinite;
    -webkit-animation-timing-function: linear;
    animation: mymoveTop 1s infinite;
    animation-timing-function: linear;
  }

  @-webkit-keyframes mymoveTop {
    0% {
      top: 0px;
    }

    50% {
      top: 60px;
    }

    100% {
      top: 0px;
    }
  }

  @keyframes mymoveTop {
    0% {
      top: 0px;
    }

    50% {
      top: 60px;
    }

    100% {
      top: 0px;
    }
  }

  p {
    padding: 3% 25%;
    font-size: 18px;
  }

  h2 {
    font-weight: bold;
    margin-top: 15%;
    padding-left: 25%;
  }
}

.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}

.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slider {
  height: 100%;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
  height: 100%;
}

.slick-list:focus {
  outline: 0;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  top: 0;
  left: 0;
  height: 100%;
}

.slick-track:after,
.slick-track:before {
  display: table;
  content: "";
}

.slick-track:after {
  clear: both;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  width: 100%;
  min-height: 1px;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.slick-dots,
.slick-next,
.slick-prev {
  position: absolute;
  display: block;
  padding: 0;
}

.slick-dots li button:before,
.slick-next:before,
.slick-prev:before {
  font-family: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-next,
.slick-prev {
  font-size: 0;
  line-height: 0;
  top: 50%;
  width: 20px;
  height: 21px;
  border-radius: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: rgba(0, 0, 0, 0.5);
}

.slick-next:focus,
.slick-next:hover,
.slick-prev:focus,
.slick-prev:hover {
  color: transparent;
  outline: 0;
  background: 0;
}

.slick-next:focus:before,
.slick-next:hover:before,
.slick-prev:focus:before,
.slick-prev:hover:before {
  opacity: 1;
}

.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  opacity: 0.25;
}

.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 0.2;
  opacity: 0.75;
  color: $whitecolor;
}

.slick-prev {
  left: 10px;
  z-index: 1;
}

[dir="rtl"] .slick-prev {
  right: 10px;
  left: auto;
}

.slick-prev:before {
  content: "‹";
}

.slick-next:before,
[dir="rtl"] .slick-prev:before {
  content: "›";
}

.slick-next {
  right: 10px;
}

[dir="rtl"] .slick-next {
  right: auto;
  left: 10px;
}

[dir="rtl"] .slick-next:before {
  content: "≫ ";
}

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  bottom: -25px;
  width: 100%;
  margin: 0;
  list-style: none;
  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: 0;
  background: 0;
}

.slick-dots li button:focus,
.slick-dots li button:hover {
  outline: 0;
}

.slick-dots li button:focus:before,
.slick-dots li button:hover:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  content: "≪";
  text-align: center;
  opacity: 0.25;
  color: #000;
}

.slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #000;
}

.animaterightCalc {
  animation: animaterightCalc 1s !important;
  -webkit-animation: animaterightCalc 1s !important;
}

@keyframes animaterightCalc {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}

@-webkit-keyframes animaterightCalc {
  from {
    left: 100%;
  }

  to {
    left: 0;
  }
}

.animateLeftCalc {
  animation: animateLeftCalc 1s !important;
  -webkit-animation: animateLeftCalc 1s !important;
}

@keyframes animateLeftCalc {
  from {
    left: 0;
  }

  to {
    left: 100%;
  }
}

@-webkit-keyframes animateLeftCalc {
  from {
    left: 0;
  }

  to {
    left: 100%;
  }
}

.calc-condition {
  padding: 0 20px;
  text-align: center;
  color: $bluecolor;
  margin-bottom: 0;
}

@media only screen and (max-width: 767px) {
  .customer-feedback {
    ul {
      li {
        display: inline-block !important;
      }
    }
  }

  .cashImg {
    background: none !important;

    h1 {
      font-size: 40px !important;
      margin-top: 85px !important;
    }
  }

  .features_content {
    p {
      font-size: 18px !important;
    }
  }

  .Phocket-customer-header {
    .mainIcon_span {
      font-size: 9px !important;
      margin-left: 35px !important;
    }
  }

  .dashboard-content-div {
    height: 500px !important;
  }

  .customer-main-page {
    height: 100% !important;
  }

  .calc-main-row {
    padding: 0 !important;
  }

  .calc-main-row {
    margin-top: 25px !important;
  }

  .calc-content {
    border-right: 0 !important;
  }

  .home-calc-close {
    top: 0 !important;
    left: 30px !important;
    font-size: 40px !important;
  }

  .serialNum {
    padding: 5px 12px !important;
  }

  .phocket_footer {
    background-size: cover !important;

    .link_footer_ul {
      ul {
        li {
          display: block !important;
          line-height: 2 !important;
        }
      }
    }
  }

  .working-first-row,
  .working-second-row,
  .working-third-row {
    img {
      width: 50% !important;
    }
  }

  .working-step-1 {
    h2 {
      margin-top: 20px !important;
    }
  }

  .Phocket-customer-feedback {
    .feedback-btn-p {
      margin-top: 0 !important;
    }
  }

  .res-details {
    border-right: 0 !important;
  }

  .phocket_working {
    p {
      padding: 0 15% !important;
    }

    h2 {
      padding-left: 15% !important;
      font-size: 25px !important;
    }
  }

  .apply-button {
    top: 70px !important;
    right: 0 !important;
  }

  .phocket-signin {
    font-size: 17px !important;
    width: 65% !important;
    margin: 5% auto !important;
  }

  .phocket_signIn {
    width: 80% !important;
    margin: 5% 10% !important;

    ul {
      li {
        font-size: 15px !important;
      }
    }

    .icon-img {
      border-right: none !important;
    }
  }

  .phocket_tagline {
    .main_tagline {
      margin-top: 90px;
    }
  }

  .rangeslider__labels .rangeslider__label-item {
    font-size: 10px !important;
  }

  .height-77 {
    height: 58% !important;
  }

  .phocket-coming-soon {
    .gif_player.playing {
      img {
        width: 100%;
      }
    }
  }

  .margin-left-30 {
    margin-left: 15% !important;
  }

  .phocket-loader-img {
    left: 35% !important;
  }

  .phocket-corporate-page {
    h1 {
      font-size: 40px !important;
    }

    h2 {
      font-size: 18px !important;
      padding: 0 2% !important;
    }

    .first-corp-row,
    .second-corp-row,
    .fifth-corp-row,
    .third-corp-row,
    .fourth-corp-row {
      h2 {
        font-size: 35px !important;
      }
    }

    .fourth-corp-row {
      .corp-terms {
        padding: 1% 5% !important;
        text-align: left !important;
      }

      li {
        line-height: 1.5 !important;
      }
    }

    .first-corp-row {
      p {
        padding: 5% !important;
        line-height: 1.5 !important;
      }
    }
  }

  .customer-dashbord-view-box {
    margin: 10px 7% !important;

    .chat-first-div {
      padding: 10% 0 !important;
      border-right: none !important;
      height: 150px !important;
    }
  }

  ul.Phocket-customer-menu {
    li {
      width: 50% !important;
      border-left: 1px solid $bodycolor;
    }
  }

  .phocket-crm-pages {
    overflow: initial !important;
  }

  .phocket_icon {
    img {
      max-width: 100px !important;
    }
  }

  .phocket-crm-login {
    label {
      float: left !important;
    }
  }

  .loan-lead-search,
  .bulk-communication-filter {
    li {
      width: 100% !important;
    }
  }
}

@media (max-width: 410px) {
  .phocket_tagline {
    h4 {
      font-size: 20px;
    }
  }

  .customer-dashbord-view-box {
    margin: 10px 5% !important;
  }

  .phocket-signin {
    width: 100% !important;
    margin: 3% auto !important;
  }
}

/////////////////////////////////OLD CSS////////////////////////////////////
.right-10px {
  margin-right: 10px;
}

.phocketCustomer-signIn {
  background: url(./images/pattern-bg.png) center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-color: $bluecolor;
}

.phocket_signIn {
  color: $bluecolor;
  background: #f2fbfc; // border: solid 1px #66cde3;
  width: 50%;
  margin-left: 25%;
  margin-top: 15%;

  .btns-set-padding {
    padding: 12% 5%;
  }

  .set-padding {
    padding: 2%;
  }

  .amb-icon-img {
    border-right: 1px solid #66cde3;
    padding: 0 3%;
  }

  .icon-img {
    border-right: 1px solid #66cde3;
    padding: 14% 3%;
  }

  ul {
    list-style-type: none;

    li {
      text-align: left;
      line-height: 3;
      font-size: 18px;
      font-weight: bold;
    }
  }

  .phocket-mobile-validation {
    input {
      border: none;
      border-bottom: solid 1px #66cde3;
      width: 100%;
      margin-top: 5%;
      background: transparent;

      &:focus {
        outline: none !important;
      }
    }

    .check {
      text-align: left;
      font-size: 12px;
      font-weight: normal;
      color: $fontcolor;
      line-height: 1.8;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    .icon-img {
      padding: 102px 3% !important;

      img,
      svg {
        width: 100% !important;
      }
    }

    .working-step-1 {
      h2 {
        margin-top: 20px !important;
      }
    }
  }

  @media (max-width: 430px) {
    .icon-img {

      img,
      svg {
        width: 100% !important;
      }
    }
  }

  .phocket-signin {
    width: 100%;
    padding: 7px;
    color: $whitecolor;
    text-decoration: none;
    line-height: 2.1;
    border-radius: 5px;
    cursor: pointer;
    display: block;
    margin-bottom: 10px;
  }

  .form-box {
    input {
      margin-top: 7px;
      border: solid 1px $bodycolor;
      padding: 4px;
      width: 99%;
      border-radius: 5px;

      &:focus {
        outline: none;
      }
    }

    button {
      margin-top: 5px;
      color: $whitecolor;
      background: $bluecolor;
      border: none;
      width: 100px;
      padding: 5px;

      &:hover {
        background: $orangecolor;
        color: $whitecolor;
      }
    }
  }
}

.ambassador-login {
  .phocket_signIn {
    color: #006fbc;
    background: #f2fbfc;
    width: 100% !important;
    margin: 0 !important;
  }

  .container-login100 {
    background: transparent !important;
  }

  .btns-set-padding {
    padding: 5% !important;
  }

  h4 {
    margin: 3px auto !important;
  }
}

.amb-header {
  .modal-body {
    line-height: 1.5;
  }
}

.otp-btn {
  background: $bluecolor;
  width: 100px;
  margin: 10px auto 0 auto;
  text-align: center;
  color: $whitecolor;
  border-radius: 5px;
  padding: 10px;
  border: 0;

  &:hover {
    text-decoration: none;
    color: $whitecolor;
  }

  &:focus {
    outline: none;
  }
}

button.phocket-disabled {
  opacity: 0.5;
  pointer-events: none;
}

.google-p {
  border: solid 2px rgb(224, 74, 50);
  background: rgb(224, 74, 50);
}

.facebook-p {
  border: solid 2px rgb(63, 94, 149);
  background: rgb(63, 94, 149);
}

.background-white {
  background-color: $whitecolor;
}

.background-orange {
  background-color: $orangecolor !important;
}

.phocket-calc-main {
  width: 100%;
  padding: 2% 10%;

  .calc-main-row {
    border: 1px solid $bluecolor;
  }

  .calc-content {
    padding: 2% 0;
    border-right: solid 1px $bluecolor;
    background: #f5f6f7;

    .loan-variables {
      border: solid 1px #ccc;
      box-shadow: 0px 0px 2px 2px #ccc;
      padding: 5px 10px;
      float: right;
      display: block;
      color: $bluecolor;
      margin-top: 10px;
    }
  }

  .phocket-calc-header {
    padding: 15px 0;
    text-align: center;
    background-color: $bluecolor;
    color: $whitecolor;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 1px;
  }

  .phocket-calc-body {
    background-color: transparent;
    border: solid 1px $bluecolor;
    color: $fontcolor;

    h4 {
      color: $bluecolor;
      text-align: center;
      margin-top: 25px;
    }
  }

  .phocket-calc-footer {
    background-color: $bluecolor;
    padding: 15px 0;
    color: $whitecolor;
  }
}

.phocket-slider {
  min-height: 40px;
  max-width: 96%;
}

.max-width-750px {
  max-width: 750px;
}

.max-width-100px {
  max-width: 100px;
}

.max-width-500px {
  max-width: 500px;
  margin: auto;
}

.display-flex {
  display: flex;
}

.display-block {
  display: block;
}

.display-inline {
  display: inline-block;
}

.top-bottom-7px {
  padding: 7px 0;
}

.top-bottom-10px {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.top-bottom-20px {
  padding: 20px 0;
}

.display-none {
  display: none !important;
}

.font-15px {
  font-size: 15px !important;
}

.font-25px {
  font-size: 25px !important;
}

.font-12px {
  font-size: 12px !important;
}

.react-datepicker-wrapper,
.react-datepicker__input-container {
  width: 100%;
}

.react-datepicker-popper {
  min-width: 230px;

  .react-datepicker,
  .react-datepicker__month-container {
    width: 100%;
    background: $fieldrow;
    border: 1px solid $bluecolor;
  }

  .react-datepicker__header {
    background-color: $bluecolor;
    color: $whitecolor;
    font-weight: bold;

    .react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
      font-size: 11px;
    }

    .react-datepicker__day-name {
      color: $whitecolor !important;
    }

    .react-datepicker__current-month {
      margin-top: 0;
      color: $whitecolor;
      font-size: 12px;
    }
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    color: $fontcolor;
    display: inline-block;
    width: 14%;
    line-height: 25px;
    text-align: center;
    margin: 0;
    font-size: 10px;
  }

  .react-datepicker__month {
    margin: 0;
    background-color: $whitecolor;
    font-weight: bold;
  }

  .react-datepicker__triangle,
  .react-datepicker__triangle::before {
    border-bottom-color: $bluecolor !important;
    border-top-color: $bluecolor !important;
  }
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range {
  background: $bluecolor !important;
  color: $whitecolor !important;
}

.phocket-app-form {
  background-color: transparent;
  background-image: url("./images/pattern-bg.png");
  background-repeat: no-repeat;
  background-size: cover;

  .phocket-form-header {
    padding: 15px;
    font-size: 20px;
    color: $whitecolor;
    padding-left: 5%;
    background-color: $fieldrow;
    font-weight: bold;
    border-bottom: 1px solid $orangecolor;
  }

  .phocket-form-body {

    // height: 80%;
    .phocket-forms {
      padding: 20px 70px;
      background-color: $whitecolor;
      margin-top: 5%;
      height: 100%;
      max-width: 700px;

      .phocket-form-field {
        height: 34px;
        border: none;
        width: 100%;
        padding: 3px 6px;
        background-color: $whitecolor;
      }

      // select.phocket-form-field {
      //   color: #ccc;
      // }
      .phocket-form-field:focus {
        outline: none;
      }

      select {
        -moz-appearance: none;
        -webkit-appearance: none;
      }

      select::-ms-expand {
        display: none;
      }

      label {
        font-size: 13px;
        height: 32px;

        .input-field-label-new {
          height: 100% !important;
        }
      }

      .phocket-form-field-padding {
        margin: 30px 0;
        padding: 15px 0;
        background-color: $fieldrow;
      }

      .phocket-form-btn {
        border: none;
        border-radius: 10px;
        height: 30px;
        width: 40px;
        background: transparent;
        margin: 5px;
        background-position: center;
        background-size: cover;
      }

      .phocket-form-btn:focus {
        outline: none;
      }

      .phocket-form-btn.next {
        background-image: url("./images/next.svg");
      }

      .phocket-form-btn.back {
        background-image: url("./images/back.svg");
      }

      .checkbox-label {
        float: left;

        label {
          margin: 0 10px 0 0;
        }
      }
    }
  }
}

select:required:invalid {
  color: #ccc;
}

option[value=""][disabled] {
  display: none;
}

option {
  color: $fontcolor;
}

.no-padding {
  padding: 0px !important;
}

.phocket-checkbox {
  display: none !important;
}

.phocket-checkbox+label span {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 1px solid $fontcolor;
  border-radius: 2px;
  margin-top: 2px;
}

.phocket-checkbox:checked+label span {
  background-image: url(./images/green-tick-small-new.png);
  border: 0;
  background-color: transparent;
}

.top-39px {
  margin-top: 39px;
}

.border-blue {
  border: 1px solid $bluecolor;
}

.border-right-blue {
  border-right: 1px solid $bluecolor;
}

.border-bottom-blue {
  border-bottom: 1px solid $bluecolor;
}

.border-bottom-orange {
  border-bottom: solid $orangecolor;
}

.margin-bottom-20px {
  margin-bottom: 20px !important;
}

.margin-bottom-25px {
  margin-bottom: 25px !important;
}

.margin-top-29per {
  margin-top: 29%;
}

.margin-top-10per {
  margin-top: 10% !important;
}

.margin-top-20px {
  margin-top: 20px !important;
}

.top-10px {
  margin-top: 10px !important;
}

.margin-top-60px {
  margin-top: 60px !important;
}

.phocket_thanks {
  h1 {
    line-height: 1.5;
  }
}

/**
* Rangeslider
*/

.rangeslider {
  margin: 20px 0;
  position: relative;
  background: #e6e6e6;
  -ms-touch-action: none;
  touch-action: none;
}

.rangeslider,
.rangeslider .rangeslider__fill {
  display: block;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle {
  background: $whitecolor;
  border: 1px solid #ccc;
  cursor: pointer;
  display: inline-block;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4), 0 -1px 3px rgba(0, 0, 0, 0.4);
}

.rangeslider .rangeslider__handle .rangeslider__active {
  opacity: 1;
}

.rangeslider .rangeslider__handle-tooltip {
  width: 40px;
  height: 40px;
  text-align: center;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  font-weight: normal;
  font-size: 14px;
  transition: all 100ms ease-in;
  border-radius: 4px;
  display: inline-block;
  color: white;
  left: 50%;
  transform: translate 3d (-50%, 0, 0);
}

.rangeslider .rangeslider__handle-tooltip span {
  margin-top: 12px;
  display: inline-block;
  line-height: 100%;
}

.rangeslider .rangeslider__handle-tooltip:after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
}

/**
* Rangeslider - Horizontal slider
*/

.rangeslider-horizontal {
  height: 12px;
  border-radius: 10px;
}

.rangeslider-horizontal .rangeslider__fill {
  height: 100%;
  background-color: $bluecolor;
  border-radius: 10px;
  top: 0;
}

.rangeslider-horizontal .rangeslider__handle {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);

  &:focus {
    outline: none;
  }
}

.rangeslider-horizontal .rangeslider__handle:after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 6px;
  left: 6px;
  border-radius: 50%;
  background-color: $orangecolor;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.4) inset, 0 -1px 3px rgba(0, 0, 0, 0.4) inset;
}

.rangeslider-horizontal .rangeslider__handle-tooltip {
  top: -55px;
}

.rangeslider-horizontal .rangeslider__handle-tooltip:after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid rgba(0, 0, 0, 0.8);
  left: 50%;
  bottom: -8px;
  transform: translate 3d (-50%, 0, 0);
}

/**
* Rangeslider - Vertical slider
*/

.rangeslider-vertical {
  margin: 20px auto;
  height: 150px;
  max-width: 10px;
  background-color: transparent;
}

.rangeslider-vertical .rangeslider__fill,
.rangeslider-vertical .rangeslider__handle {
  position: absolute;
}

.rangeslider-vertical .rangeslider__fill {
  width: 100%;
  background-color: #7cb342;
  box-shadow: none;
  bottom: 0;
}

.rangeslider-vertical .rangeslider__handle {
  width: 30px;
  height: 10px;
  left: -10px;
  box-shadow: none;
}

.rangeslider-vertical .rangeslider__handle-tooltip {
  left: -100%;
  top: 50%;
  transform: translate3d(-50%, -50%, 0);
}

.rangeslider-vertical .rangeslider__handle-tooltip:after {
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-left: 8px solid rgba(0, 0, 0, 0.8);
  left: 100%;
  top: 12px;
}

/**
* Rangeslider - Reverse
*/

.rangeslider-reverse.rangeslider-horizontal .rangeslider__fill {
  right: 0;
}

.rangeslider-reverse.rangeslider-vertical .rangeslider__fill {
  top: 0;
  bottom: inherit;
}

/**
* Rangeslider - Labels
*/

.rangeslider__labels {
  position: relative;
}

.rangeslider-vertical .rangeslider__labels {
  position: relative;
  list-style-type: none;
  margin: 0 0 0 24px;
  padding: 0;
  text-align: left;
  width: 250px;
  height: 100%;
  left: 10px;
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item {
  position: absolute;
  transform: translate3d(0, -50%, 0);
}

.rangeslider-vertical .rangeslider__labels .rangeslider__label-item::before {
  content: "";
  width: 10px;
  height: 2px;
  background: black;
  position: absolute;
  left: -14px;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}

.rangeslider__labels .rangeslider__label-item {
  position: absolute;
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  top: 10px;
  transform: translate 3d (-50%, 0, 0);
}

.height-20px {
  height: 20px;
}

.height-25px {
  height: 25px;
}

.height-15px {
  height: 15px !important;
}

.text-white {
  color: $whitecolor !important;
}

.block {
  display: block !important;
}

.input-file-label {
  width: 70px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 1px solid $bluecolor;
  font-weight: bold;
  border-radius: 5px;
  margin-right: 5px;
}

.phocket_border_blue {
  border: solid 1px $bluecolor;
}

.margin-left-25px {
  margin-left: 25px;
}

.margin-left-30 {
  margin-left: 30%;
}

.animate-right {
  position: relative;
  animation: animateright 1s !important;
  -webkit-animation: animateright 1s !important;
}

@keyframes animateright {
  from {
    left: 100%;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

@-webkit-keyframes animateright {
  from {
    left: 100%;
    opacity: 0;
  }

  to {
    left: 0;
    opacity: 1;
  }
}

.animate-left {
  position: relative;
  animation: animateleft 1s !important;
  -webkit-animation: animateleft 1s !important;
}

@keyframes animateleft {
  from {
    right: 100%;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

@-webkit-keyframes animateleft {
  from {
    right: 100%;
    opacity: 0;
  }

  to {
    right: 0;
    opacity: 1;
  }
}

.animate-fade {
  position: relative;
  animation: animatefade 1s !important;
  -webkit-animation: animatefade 1s !important;
}

@keyframes animatefade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@-webkit-keyframes animatefade {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.overflow-hidden {
  overflow: hidden;
}

.height-89 {
  height: 89%;
}

.height-87 {
  height: 87%;
}

.height-70 {
  height: 70%;
}

.height-81 {
  height: 81%;
}

.height-450px {
  height: 450px !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.height-84px {
  height: 84px;
}

.height-70px {
  height: 70px;
}

.promotional-data-table {
  .tableHeading {
    color: $bluecolor;

    th {
      font-weight: 200;
      white-space: nowrap;
    }
  }

  .tablecontent {
    td {
      font-weight: 200;
    }
  }

  .footer {
    text-align: center;

    button {
      display: inline;
      margin: 0 10px;
    }

    button:hover,
    button:focus {
      color: white;
      outline: none !important;
    }
  }
}

.rangeslider__handle-tooltip {
  min-width: 70px !important;
}

.height-77 {
  height: 76%;
  overflow: auto;
}

////////////////////////////////////////////DASHBOARD CSS//////////////////////////////////
.customer-main-page {
  // background: url('./images/pattern-bg.png') no-repeat center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
  overflow: auto;
}

.content-left-right-padding {
  padding-left: 10%;
  padding-right: 10%;
}

.white-bg {
  background: $whitecolor;
}

.white-bg-transparent {
  background: rgba(255, 255, 255, 0.7);
}

.padding-top-btm-2per {
  padding-top: 2%;
  padding-bottom: 2%;
}

.bold {
  font-weight: bold;
}

.border-bottom-grey {
  border-bottom: solid 1px #eaeaea;
}

.Phocket-customer-header {
  height: 70px;
  background: $whitecolor !important;
  border-bottom: solid 1px #f9f9f9;
  line-height: 70px;

  .mainIcon_span {
    font-size: 11px;
    margin-left: 55px;
  }

  .mainIcon {
    width: 170px;
    max-width: 100%;
    max-height: 15px;

    svg {
      width: 170px;
      max-width: 100%;
    }
  }

  .phocket_header {
    background: $whitecolor !important;
    height: 100%;
    border-bottom: solid 0.5px #e8e8e8;
    line-height: 70px;
    z-index: 1;

    img.profileImage {
      width: 25px;
      height: 25px;
      border-radius: 50%; // border: solid 1px $bluecolor;
    }

    img {
      width: 170px;
    }
  }
}

.Phocket-dash-header {
  .apply-button {
    position: relative;
    right: 0;

    &:hover {
      background: transparent !important;
      color: $orangecolor;
    }
  }

  a {
    cursor: pointer;
    text-decoration: none;
  }
}

ul.Phocket-customer-menu {
  margin: 0;
  list-style-type: none;
  color: $bluecolor;
  background: #f9f9f9;
  padding: 0;
  border-left: 1px solid $bodycolor;
  text-align: center;

  li {
    display: inline-block;
    border-right: 1px solid $bodycolor;
    padding: 28px 1%;
    border-bottom: solid 3px $bodycolor;
    cursor: pointer;
    width: 162px !important;

    &:last-child {
      // padding-top: 10px !important;
    }

    a {
      margin: 0;
      text-decoration: none;
    }
  }

  li:first-child {
    border-left: 1px solid $bodycolor;
  }

  li.activeSubMenu {
    border-bottom: 3px solid $orangecolor !important;
    background: $whitecolor !important;

    a {
      color: $orangecolor !important;
    }
  }
}

.Phocket-customer-profile {
  color: $bluecolor;

  .edit-profile {
    background: white;
    text-align: left;
    height: 50px;
    line-height: 3;

    a {
      margin: 0;
      cursor: pointer;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        color: $orangecolor;
      }
    }
  }
}

.edit-profile-menu,
.coupon-details-menu {
  .dashboard-tabs {
    width: 222px;
    margin-left: 10px;
  }

  padding: 0;

  .nav {
    position: relative;
    z-index: 1;
    white-space: nowrap;
  }

  .nav a {
    position: relative;
    float: left;
    padding: 10px 22px 7px 22px;
    text-decoration: none;
    cursor: pointer;
    width: 140px;
    color: #000 !important;
    text-align: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .nav a::before {
    content: "";
    /* To generate the box */
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
    border-bottom: none !important;
    border: solid 1px $gradientBlue;
    border-radius: 10px 10px 0 0;
    background-image: linear-gradient($whitecolor, $gradientBlue);
    box-shadow: 0 2px hsla(0, 0%, 100%, 0.5) inset;
    transform: perspective(5px) rotateX(1deg);
    transform-origin: bottom left;
  }

  .nav a.selected {
    z-index: 2;
    color: $orangecolor !important;
  }

  .nav a.selected::before {
    margin-bottom: -1px;
    border-top-width: 1px;
    padding: 0 5%;
    border-bottom: none !important;
    border: solid 1px $gradientBlue;
    background: $whitecolor;
    overflow: auto;
  }
}

.edit-personal-details {
  color: $bluecolor;
  padding: 0 5%;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}

.profile-save-btn,
.admin-btn {
  background-image: linear-gradient($whitecolor, $gradientBlue);
  color: black !important;
  border: 1px solid $gradientBlue;
  margin-bottom: 10px;
  padding: 7px 20px;
  border-radius: 3px;
  margin-top: 20px;
  white-space: nowrap;
}

.profile-save-btn[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.max-height-365px {
  max-height: 365px;
}

.padding-bottom-5per {
  padding-bottom: 5% !important;
}

.border-bottom-underline {
  border-bottom: solid 1px $bluecolor !important;
}

.border-left-grey {
  border-left: 1px solid $bodycolor;
}

.border-bottom-grey {
  border-bottom: 1px solid $bodycolor;
}

.sm-padding-edit-profile1 {
  padding: 7% 0 0 0;
}

.disp-inline-flex {
  display: -webkit-inline-flex;
}

.sm-padding-edit-profile2 {
  padding: 5% 0 0 0;
}

.sm-padding-view-profile2 {
  padding: 3% 0 0 3%;
}

input.edit-profile-input {
  border: none;
  width: 100%;

  &:focus {
    outline: none;
  }
}

select.edit-profile-input {
  // color: #ccc;
  width: 100%;
  background: $whitecolor;
  border: none !important;
  -moz-appearance: none;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &:-ms-expand {
    display: none;
  }
}

.dashboard-content-div {
  height: 473px;
}

.padding-top-btm-6per {
  padding: 6% 0;
}

.border-radius-10px {
  border-radius: 10px;
}

.Phocket-customer-setting-menu {
  position: absolute;
  background: $whitecolor;
  border: 1px solid $lightblue;
  width: 150px;
  padding: 20px 15px;
  right: 25px;
  top: 70px;
  z-index: 999;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    li {
      line-height: 2;
      cursor: pointer;
    }

    a {
      text-decoration: none;
    }
  }
}

.Phocket-customer-setting-menu:after,
.Phocket-customer-setting-menu:before {
  bottom: 100%;
  left: 70%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.Phocket-customer-setting-menu:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $whitecolor;
  border-width: 15px;
  margin-left: -15px;
}

.Phocket-customer-setting-menu:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $lightblue;
  border-width: 16px;
  margin-left: -16px;
}

.Phocket-customer-feedback {
  margin: 0;
  padding: 3% 2%;
  overflow: auto;

  input {
    padding: 8px;
    width: 100%;
    border-radius: 5px;
    border: solid 1px #ccc;
  }

  textarea {
    width: 100%;
    border-radius: 5px;
    border: solid 1px #ccc;
    resize: none;
    padding: 8px;

    &:focus {
      outline: none;
    }
  }

  button {
    background-color: $bluecolor;
    border: none;
    color: $whitecolor;
    text-align: center;
    margin: 4px 2px;
    font-weight: bold;
    padding: 0 10px;
    height: 50px;
    line-height: 1;
    border-radius: 5px;
    width: 100px;
    text-transform: uppercase;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: $whitecolor;
      border: 1px solid $orangecolor;
      color: $orangecolor;
    }
  }
}

.nowrap {
  white-space: nowrap;
}

///////////////////////////////////END OF CUSTOMER DASHBOARD CSS///////////////////
///////////////////////////////////////CRM CSS/////////////////////////////////////////
.Phocket-crm-header {
  height: 70px;
  background-color: #223D64;
  line-height: 70px;
  // border-bottom: solid 1px $gradientBlue;
  box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.35);
  -webkit-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.35);
  -moz-box-shadow: 0px 2px 5px 1px rgba(0, 0, 0, 0.35);
  width: 100%;

  .bell-icon {
    color: #fff;
  }

  .fa {
    font-size: 21px;
  }

  .col-xs-6 {
    span {
      cursor: pointer;
    }
  }
}

.Phocket-crm-change-pass {
  width: 50%;
  min-width: 300px;
  margin: auto;

  a {
    line-height: 2;
    font-size: 17px;
  }
}

.phocket-crm-pages {
  // height: 100%;
}

// .phocket-crm-menu {
//   position: fixed;
//   top: 70px;
//   left: 0;
//   bottom: 0;
//   max-width: 200px;
//   z-index: 10;
//   background-color: $whitecolor;
//   color: $fontcolor;
//   width: 200px;
//   border-right: 3px double $bluecolor;
//   ul {
//     list-style-type: none;
//     padding-left: 10px;
//     padding: 5px 0 10px 20px;
//     line-height: 35px;
//     margin: 0;
//     li {
//       font-size: 16px;
//       a {
//         cursor: pointer;
//         text-decoration: none;
//         &:hover {
//           color: $orangecolor;
//         }
//       }
//       a.active {
//         color: $orangecolor;
//       }
//     }
//   }
// }

.phocket-crm-menu {
  position: fixed;
  top: 70px;
  left: 0px;
  bottom: 0px;
  z-index: 10;
  color: $fontcolor;

  // border-right: 1px solid $bluecolor;
  ul.mail-list {
    list-style: none;
    // margin-left: -42px;
  }

  ul.mail-list-new {

    margin: 0;
    padding: 10px;
    list-style-type: none;
    min-width: 295px;
    height: 100%;
    overflow: auto;
    background-color: #0c3953;

    li.mail-list-item {
      // border-bottom: 1px solid gray;
      padding: 12px;
      cursor: pointer;
      color: $whitecolor;
      font-size: 12px;

      &:hover {
        background-color: #50617e !important;
        transition: box-shadow 0.3s ease-in-out;
        font-weight: 700;
        border-radius: 5px;

        li.sub-list-item {
          color: black !important;
        }

        .fa {
          color: #fff !important;
        }
      }

      .fa {
        color: $whitecolor;
        font-size: 18px;
        // &:hover ,&:active{
        //   background-color: $whitecolor;
        //   color: #000 !important;
        // }
      }

      //     &:hover {
      //       border:none;
      //       box-shadow: 0 5px 15px rgba(0,0,0,0.3);
      // transition: box-shadow 0.3s ease-in-out;
      // font-weight: 700;
      // box-shadow: linear-gradient(#A7CDFE, $whitecolor);
      // }
      ul.sub-list {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li.sub-list-item {
          padding: 5px 0 5px 10px;
          position: relative;
          color: $whitecolor;

          &:hover,
          &:active {
            background-color: $whitecolor !important;
            color: black;
            border: none;
            box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
            transition: box-shadow 0.3s ease-in-out;
            font-weight: 700;
          }
        }
      }

      a {
        text-decoration: none;
        cursor: pointer;
      }
    }

    .caret {
      float: right;
      margin-top: 10px;
    }
  }
}

.animate-crm-menu {
  animation: animateCRMMenu 0.5s;
  -webkit-animation: animateCRMMenu 0.5s;
}

.animate-crm-menu-back {
  animation: animateCRMMenuBack 0.5s;
  -webkit-animation: animateCRMMenuBack 0.5s;
}

@keyframes animateCRMMenu {
  from {
    left: -200px;
  }

  to {
    left: 0px;
  }
}

@-webkit-keyframes animateCRMMenu {
  from {
    left: -200px;
  }

  to {
    left: 0px;
  }
}

@keyframes animateCRMMenuBack {
  from {
    left: 0px;
  }

  to {
    left: -200px;
  }
}

@-webkit-keyframes animateCRMMenuBack {
  from {
    left: 0px;
  }

  to {
    left: -200px;
  }
}

.phocket-crm-login {
  max-width: 600px;
  padding-top: 10%;

  .crm-login-logo {
    width: 200px;
    padding-bottom: 20px;
    margin: 0 auto 5% auto;
    display: block;
  }

  .crm-login-button {
    width: 75px;
    color: $whitecolor;
    background: $bluecolor;
  }

  .crm-login-button:hover,
  .crm-login-button:focus {
    color: $whitecolor !important;
    outline: none;
  }

  label {
    line-height: 34px;
    float: right;
  }
}

.crm-user-detail {
  background-color: $whitecolor;
  max-height: 100%;
  height: 100%;

  ul.crm-detail-tabs {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      float: left;

      a {
        cursor: pointer;
        color: $bluecolor;
        border-right: 1px solid $bluecolor;
        padding: 5px 10px;
        line-height: 2;
      }

      a:hover {
        text-decoration: none;
        color: $orangecolor;
      }

      a.active {
        color: $orangecolor;
      }
    }
  }

  .crm-user-head {
    background: #39383b;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    padding: 3px 6px;
    font-size: 17px;
    margin: 0 0 15px 0;
    color: #fff;
  }

  .crm-user-subHead {
    color: $bluecolor;
    font-weight: bold;
    font-size: 16px;
  }

  .crm-user-cibil {
    overflow: auto;

    object {
      height: 350px;
      width: 100%;
    }

    .cibil-score {
      height: 70px;
      width: 70px;
      margin: 10px auto;
      border: 3px solid $bluecolor;
      background: $fieldrow;
      border-radius: 50%;
      text-align: center;
      line-height: 70px;
      font-size: 20px;
      font-weight: bold;
    }

    img {
      height: 50px;
      width: 50px;
      border-radius: 50%;
      margin: auto;
      border: 1px solid $bluecolor;
      margin: 10px auto;
      display: block;
    }

    table.bank-enquiry-table {
      width: 100%;

      th,
      td {
        padding: 3px 0;
      }
    }
  }

  .crm-user-docs {
    .user-docs-img {
      min-height: 350px;
      overflow-y: auto;

      img {
        width: 80%;
        margin: auto;
        height: 100%;
        display: block;
      }

      .mgrpdf__wrapper {
        width: 100%;
        height: 100%;

        canvas {
          width: 100% !important;
          height: 350px !important;
        }
      }

      .pdf-viewer {
        position: fixed;
        z-index: 999;
        top: 10px;
        bottom: 30px;
        width: 60%;
        left: 20%;
        right: 20%;
        box-shadow: 0px 0px 10px $fontcolor;

        .close {
          z-index: 999;
          position: absolute;
          color: $fontcolor;
          right: 10px;
          font-size: 45px;
        }

        .close:focus {
          outline: none;
        }

        canvas {
          width: 100% !important;
          height: 95% !important;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.crm-edit-profile {
  .customer-dashbord-view-box {
    margin: 10px !important;
  }

  h3 {
    color: $orangecolor !important;
  }

  h4 {
    color: $fontcolor !important;
    border-bottom: none !important;
    padding: 10px 0 !important;
  }
}

.phocket_icon {
  img {

    width: 50%;
    // height: 25px;
    // max-width: 100%; // margin-left: 30px;
  }
}

.phocketAdmin_table {
  font-size: 14px;

  .-header {
    padding: 6px;
    background: $teal;
    color: $whitecolor;
  }

  // .-filters {
  //   background: $bluecolor;
  // }
  .rt-td {
    padding: 10px 5px !important;
    line-height: 1;

    button {
      border: none;
      background: none;
      color: $fontcolor;
      display: inline-block;

      &:focus {
        outline: none;
      }
    }
  }

  .-pagination {

    .-previous,
    .-next {
      button.-btn {
        background: $teal;
        color: $whitecolor;
      }

      .-btn:not([disabled]):hover {
        background: $teal;
        color: $whitecolor;
      }
    }
  }
}

// table.phocketAdmin_table {
//   width: 90%;
//   margin: 25px auto 0 auto;
//   border: solid 1px $bluecolor;
//   background: $whitecolor;
//   color: $bluecolor;
//   th {
//     text-transform: uppercase;
//     font-size: 15px;
//   }
//   td,
//   th {
//     border: solid 1px;
//     text-align: center;
//     textarea {
//       min-width: 150px;
//     }
//     button {
//       border: none;
//       background: $bluecolor;
//       color: $whitecolor;
//       padding: 3px 6px;
//       border-radius: 4px;
//       margin: 5px 0;
//       width: 70px;
//     }
//     a {
//       color: $fontcolor;
//       cursor: pointer;
//     }
//   }
// }
// .home-calc-btn {
//   padding: 10px;
//   position: fixed;
//   right: 0;
//   top: 50%;
//   z-index: 2;
//   font-weight: bold;
//   border-radius: 10px;
//   background: $whitecolor;
//   border: 1px solid $bluecolor;
//   border-right: none;
//   box-shadow: 0 0 10px $bluecolor;
//   img {
//     display: none;
//     height: 30px;
//     width: 30px;
//   }
// }
// .home-calc-btn:focus {
//   outline: none;
// }
.home-calc {
  background: $whitecolor;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 70px;
  line-height: 1;
  width: 100%;
  z-index: 1050;
  overflow-x: hidden;
}

.home-calc-close {
  float: left;
  position: absolute;
  z-index: 999;
  left: 50px;
  top: 10px;
  font-size: 60px;
  opacity: 1;
  color: $bluecolor;

  &:hover {
    color: #444;
  }
}

.home-calc-close:focus {
  outline: none;
}

.animaterightCalc {
  animation: animaterightCalc 1s !important;
  -webkit-animation: animaterightCalc 1s !important;
}

@keyframes animaterightCalc {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

@-webkit-keyframes animaterightCalc {
  from {
    right: -100%;
  }

  to {
    right: 0;
  }
}

.animateLeftCalc {
  animation: animateLeftCalc 1s !important;
  -webkit-animation: animateLeftCalc 1s !important;
}

@keyframes animateLeftCalc {
  from {
    right: 0;
  }

  to {
    right: -100%;
  }
}

@-webkit-keyframes animateLeftCalc {
  from {
    right: 0;
  }

  to {
    right: -100%;
  }
}

ul.phocket-step-bar {
  list-style-type: none;
  margin-top: 15px;
  margin-left: 0;
  padding: 0;
  margin-bottom: 30px;
  min-height: 60px;

  li {
    float: left;
    width: 16.65%;
    position: relative;
    text-align: center;
    color: $whitecolor;
    font-size: 10px;
  }

  li:before {
    content: "";
    width: 30px;
    height: 30px;
    border: 5px solid $bluecolor;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: $whitecolor;
  }

  li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: $whitecolor;
    top: 13px;
    left: -50%;
    z-index: -1;
  }

  li:first-child:after {
    content: none;
  }

  li.active {
    color: $whitecolor;
  }

  li.active:before {
    background: $orangecolor;
    background: radial-gradient($orangecolor, $whitecolor);
  }

  li.active+li:after {
    background: $bluecolor;
    background: linear-gradient(to right, $bluecolor, $whitecolor);
  }
}

.background-sky {
  background-color: #d7ecf2;
}

.background-blue {
  background-color: $bluecolor;
}

.text-black {
  color: $fontcolor;
}

.form-glyphicon {
  height: 34px;
  text-align: right;
  font-size: 16px;
  color: $bluecolor;
  line-height: 35px !important;
}

.tree-background {
  background-image: url("./images/tree.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  background-color: $bluecolor;
  position: relative;

  h1 {
    position: absolute;
    bottom: 30px;
    text-align: center;
    left: 20%;
    color: #ebf5f8;
    font-weight: bold;
  }
}

.phocket-step-bar-right {
  padding-top: 10%;

  img {
    width: 165px;
    margin: 20px auto;
    display: block;
  }

  ul {
    margin: auto;
    list-style-type: none;
    padding: 20px 30px;
    max-width: 300px;
    background-color: rgba(255, 255, 255, 0.7);
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(255, 255, 255, 0.3);
    border: 1px solid $whitecolor;

    .form-logo {
      cursor: pointer;
    }

    li {
      line-height: 2;
      font-size: 17px;
      color: $bluecolor;
      position: relative;
      padding-left: 30px;
      font-weight: bold;
      margin: 10px 0;
      cursor: default;
    }

    li:before {
      content: "";
      height: 20px;
      width: 20px;
      border-radius: 50%;
      border: 3px solid $bluecolor;
      top: 7px;
      position: absolute;
      left: 5px;
    }

    li.active {
      color: $whitecolor;
      background: $bluecolor;
      border-radius: 5px;
      cursor: pointer;
    }

    li.active:before {
      background-repeat: no-repeat;
      background-size: 80%;
      background-position: center;
      border-color: $orangecolor;
      background-color: $orangecolor;
    }
  }
}

.paddingTop_12 {
  padding-top: 12%;
}

.rocket-div {
  background-image: url("./images/rocket4.png");
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  font-size: 30px;
  padding-top: 450px;
  color: $whitecolor;
  font-weight: bold;

  img {
    height: 30px;
    width: 25px;
    margin-right: 10px;
  }
}

.phocket-right-border {
  border-right: 1px solid $bluecolor;
}

.phocket-left-border {
  border-left: 1px solid $bluecolor;
}

label.input-field-label-new {
  span {
    height: 25px;
    width: 25px;
    display: block;
    color: $bluecolor;
    text-align: center;
    font-size: 15px;
    border: 1px solid $bluecolor;
    margin-right: 10px;
    border-radius: 20%;
    line-height: 24px;
    cursor: pointer;
  }
}

.document-upload-ellipsis {
  max-width: 150px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

.calc-final-amount {
  height: 160px;
  width: 160px;
  margin: 5% auto 5% auto;
  border: 3px solid $bluecolor;
  border-radius: 50%;
  padding: 50px 0;
  text-align: center;
  font-size: 25px;
  font-weight: bold;
  color: $bluecolor;
  box-shadow: 0 0 10px 3px #ccc;
  position: relative;
  cursor: pointer;

  .calc-popup {
    position: absolute;
    // height: 105px;
    width: 185px;
    left: 112%;
    top: 40px;
    z-index: 2;
    border: 3px solid $bluecolor;
    box-shadow: 0 0 10px 3px #ccc;
    background: $whitecolor;
    font-size: 15px;
    padding: 10px;

    p {
      line-height: 20px;
      white-space: nowrap;
      text-align: left;
    }

    display: none;

    &::after,
    &::before {
      right: 100%;
      top: 50%;
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none;
    }

    &::after {
      border-right-color: $whitecolor;
      border-width: 15px;
      margin-top: -15px;
    }

    &::before {
      border-right-color: $bluecolor;
      border-width: 19px;
      margin-top: -19px;
    }
  }

  &:hover {
    .calc-popup {
      display: block;
    }
  }
}

.phocket-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: $fieldrow;

  .phocket-loader-img {
    // position: absolute;
    // left: 40%;
    margin-top: 300px;
    animation: coin-rotate 0.5s both infinite;
    -webkit-animation: coin-rotate 0.5s both infinite;
  }

  @keyframes coin-rotate {
    0% {
      transform: rotateY(0);
    }

    50% {
      transform: rotateY(180deg);
    }

    100% {
      transform: rotateY(360deg);
    }
  }

  @-webkit-keyframes coin-rotate {
    0% {
      transform: rotateY(0);
    }

    50% {
      transform: rotateY(180deg);
    }

    100% {
      transform: rotateY(360deg);
    }
  }
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #ccc;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #ccc;
}

:-ms-input-placeholder {
  /* IE 10+ */
  color: #ccc;
}

:-moz-placeholder {
  /* Firefox 18- */
  color: #ccc;
}

select option {
  color: $fontcolor !important;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow {
  top: 2px !important;
}

.react-datepicker__year-option,
.react-datepicker__month-option {
  color: $fontcolor !important;
  font-size: 10px !important;
}

.phocket-popup {
  position: fixed;
  z-index: 99999;
  height: 50px;
  border: 2px solid grey;
  border-radius: 4px;
  background-color: #DBE9FF;
  line-height: 48px;
  min-width: 300px;
  max-width: -moz-fit-content;
  font-weight: 700;
  max-width: fit-content;
  font-size: 20px;
  padding: 0 10px;
  white-space: nowrap;
  left: 20px;
  bottom: 30px;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Phocket-scoring-details {
  table {
    th {
      font-weight: normal !important;
    }

    td,
    th {
      padding: 5px !important;
    }
  }
}

.phocket-milestone {
  // background: linear-gradient(-90deg, #b8e5f0, #ffffff);
  height: 100% !important;
}

.Phocket-transaction-details {
  .form-control {
    width: 80%;
  }
}

.Phocket-scoring-details,
.Phocket-transaction-details,
.Phocket-promotional-details,
.phocket-milestone {
  h2 {
    color: $bluecolor;
    margin-bottom: 25px;
    text-align: center;
  }

  table {
    width: 90%;
    margin: auto;

    th {
      background: $bluecolor;
      color: $whitecolor;
    }

    td,
    th {
      text-align: center;
      padding: 15px;
      border: solid 1px $bluecolor;
    }
  }

  .trans-ip {
    width: 80%;
    border-radius: 10px;
    padding: 10px;
    border: solid 1px $bodycolor;

    &:focus {
      outline: none;
    }
  }
}

.customer-dashbord-view-box {
  min-height: 480px;
  border-radius: 10px;
  margin: 10px 9%;
  background-color: $whitecolor;
  color: $bluecolor;
  overflow: auto;

  h5 {
    font-weight: bold;
    margin-bottom: 0 !important;
    padding-left: 15px;
  }

  h4 {
    color: $orangecolor;
    margin: 0;
    padding: 20px;
    border-bottom: solid 2px $orangecolor;
  }

  h6 {
    margin: 20px 0 0 0;
  }

  p {
    margin-bottom: 15px;
  }

  .box-row-padding {
    padding: 3%;
  }

  .prepayment-details {
    margin: auto;
    text-align: center;

    h2 {
      margin-bottom: 10px;
    }

    h4 {
      border-bottom: 0;
      text-decoration: underline;
    }

    p {
      text-align: left;
      margin: 5px auto;
      width: 90%;
    }

    .edit-profile-input {
      border: solid 1px #ccc !important;
      padding: 10px;
      width: 90%;
      margin: 3px auto;
    }

    .prepay-amt {
      margin: 3px auto;

      .bg-blue {
        text-align: center;
        background: $bluecolor;
        color: $whitecolor;
        cursor: pointer;
      }

      .joined-td {
        width: 70%;

        .date-picker {
          background: #000 !important;
          width: 100%;
          border: 0;

          &:focus {
            outline: none;
          }
        }
      }
    }

    table {
      width: 90%;
      margin: auto;

      td {
        text-align: left;
        padding: 5px;

        &:first-child {
          background: #f9f9f9;
          color: $bluecolor;
          width: 40%;
        }
      }
    }
  }

  .customer-feedback {
    padding: 25px;
    border: solid 1px #939393;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border-radius: 10px;

    h2 {
      color: $fontcolor;
    }

    .gray-text {
      color: $fontcolor;
      margin-bottom: 0 !important;
      line-height: 2.5;
    }

    .small-textarea {
      width: 90% !important;
      margin-top: 15px;
    }

    .width-95per {
      width: 95% !important;
    }

    textarea {
      padding: 10px;
      border: solid 1px #939393;
      border-radius: 10px;

      &:focus {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }

    textarea {
      padding: 5px;
      border: solid 1px #939393;
      border-radius: 10px;
      width: 90%;

      &:focus {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      }
    }

    ul {
      list-style-type: none;

      li {
        float: left;
        margin-right: 20px;
        cursor: pointer;

        a {
          font-size: 25px;
        }
      }
    }

    // table{
    //   width: 60%;
    //   margin: auto;
    //   border-radius: 10px;
    //   td{
    //     text-align: left;
    //   }
    // }
    .rating {
      line-height: 1.6;

      input {
        display: none !important;
      }

      label {
        color: #939393;
        font-size: 30px;
        cursor: pointer;
        margin: 0;
      }
    }

    .thumb {
      color: #939393;
    }

    .happySad {
      margin: 0 !important;
      padding: 0 !important;

      img {
        width: 25px;
        height: 25px;
        cursor: pointer;
      }
    }
  }

  textarea {
    width: 100%;
    border: 0;
    resize: none;

    &:focus {
      outline: none;
    }
  }

  .loan-activity {
    h3 {
      text-align: center;
      border-bottom: none;
      font-weight: bold;
    }
  }

  table {
    width: 100%;
    color: $bluecolor;

    th {
      padding: 15px 10px;
    }

    td {
      padding: 10px 10px;
    }

    th,
    td {
      text-align: center;
      border: 1px solid #68cde3;
      width: 85px;
    }
  }

  .chat-first-div {
    padding: 3.5% 0;
  }

  .chat-third-div {
    padding: 45% 0;

    .chat-logo {
      width: 210px;
      margin: auto;
    }
  }

  .chat-second-div {
    padding: 10.5% 1%;
    text-align: center;
    border-right: solid #eee;

    .qrCode-img {
      width: 200px;
      height: 200px;
    }

    .overlay {
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(0, 0, 0, 0.7);
      transition: opacity 500ms;
      visibility: hidden;
      opacity: 0;
    }

    .overlay:target {
      visibility: visible;
      opacity: 1;
    }

    .popup {
      margin: 20% auto;
      padding: 20px;
      background: #fff;
      border-radius: 5px;
      width: 30%;
      position: relative;
      border: double 6px $bluecolor;
      color: $bluecolor;
      transition: all 5s ease-in-out;
    }

    .popup .close {
      position: absolute;
      top: 0px;
      right: 5px;
      transition: all 200ms;
      font-size: 30px;
      font-weight: bold;
      text-decoration: none;
      color: $bluecolor;
      opacity: 1;
    }

    .popup .close:hover {
      color: $orangecolor;
    }

    .popup .content {
      max-height: 30%;
      overflow: auto;
    }
  }
}

.customer-dashbord-box {
  background-color: $whitecolor;
  margin: 0 9.82% 10px 9.82%;
  color: $bluecolor;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
  min-height: 370px;

  .check-box-div {
    padding-top: 60px;

    label {
      font-size: 14px;
      color: $fontcolor;
    }
  }

  .heading-text {
    padding-top: 30px;
    font-weight: bold;
    color: $orangecolor;
    border-bottom: solid $orangecolor;
  }

  .dashboard-input-div {
    padding-top: 25px;
    width: 90%;
    margin: 0;
    display: flex;
    border-bottom: solid 1px $bluecolor !important;

    label {
      font-weight: normal;
      font-size: 13px;
      height: 32px;

      .input-field-label-new {
        height: 100% !important;
      }
    }
  }

  .fa {
    display: inline-block;
  }

  .doc_up {
    padding: 6% 4% 0 4%;
  }

  input,
  select {
    border: none;
    width: 90%;
    display: inline-block;

    &:focus {
      outline: none;
    }
  }

  h4 {
    padding-top: 50px;
  }
}

.pointer {
  cursor: pointer;
}

// coming Soon
.phocket-coming-soon {
  img.p-l {
    display: block;
    margin: 30px auto;
    width: 300px;
  }

  .gif_player.playing {
    position: fixed;
    z-index: -1;
    opacity: 0.2;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;

    img {
      margin: 0 auto;
      display: block;
      height: 100%;
    }
  }

  .coming-soon-text {
    height: 200px;
    width: 200px;
    line-height: 1;
    border: 5px dotted $orangecolor;
    border-radius: 50%;
    text-align: center;
    margin: 7% auto 20px auto;
    font-size: 40px;
    padding: 55px 0;
    color: $bluecolor;
    font-weight: bold;
  }

  .countdown-text {
    border: 3px solid $bluecolor;
    margin: 0 auto;
    max-width: 300px;
    text-align: center;
    padding: 15px 0;
    border-radius: 20px;
    color: $orangecolor;
    font-weight: bold;
    font-size: 30px;
  }
}

.red-text {
  color: #ff0000;
}

////////////////////////PRIVACY POLICY & TERMS CSS//////////////////
.phocket-privacy-terms {
  background: url(./images/logo-watermark.png) center no-repeat fixed;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
}

.padding-2-6perc {
  padding: 2% 6%;
}

// coming Soon
// Notification
.customer-notification {
  top: 70px;
  width: 250px;
  max-height: 300px;
  overflow: auto;
  right: 140px;
  z-index: 999;
  position: absolute;
  background: #fff;
  border: 1px solid #d8ecf2;
  padding: 10px 15px;
  border-radius: 12px;
  background: var(--white, #FFF);
  /* light elevation */
  box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);



  div.customer-notification-heading {
    line-height: 1;
    font-weight: bold;
    font-size: 12px;
    padding: 7px 0;
    float: left;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      label {
        font-size: 12px;
        margin-bottom: 0;

        .customer-notification-date {
          position: absolute;
          right: 20px;
          top: 0;
          z-index: 1;
          font-size: 11px;
        }
      }

      line-height: 1;
      // border-bottom: 1px solid #ddd;
      padding: 5px 5px 0px 5px;

      p {
        margin-bottom: 0;
        padding: 5px 0 10px 0;
        font-size: 13px;
        word-wrap: break-word;

        button.close {
          position: absolute;
          right: 0;
          top: 0;
          left: 95px;
          z-index: 1;
        }
      }
    }

    li:last-child {
      border: none;
    }

    li:empty {
      display: none;
    }
  }
}

.customer-notification:after,
.customer-notification:before {
  bottom: 100%;
  left: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.customer-notification:after {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $whitecolor;
  border-width: 15px;
  margin-left: -15px;
}

.customer-notification:before {
  border-color: rgba(255, 255, 255, 0);
  border-bottom-color: $lightblue;
  border-width: 16px;
  margin-left: -16px;
}

.not-count-circle {
  position: absolute;
  top: -9px;
  right: -5px;
  height: 15px;
  width: 15px;
  background: $orangecolor;
  border-radius: 50%;
  color: $whitecolor;
  line-height: 15px;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}

.not-count-circle-bell {
  // transform: rotate(-31deg);
  position: absolute;
  top: 0px;
  right: 5px;
  height: 15px;
  width: 15px;
  background: $orangecolor;
  border-radius: 50%;
  color: $whitecolor;
  line-height: 15px;
  font-size: 10px;
  text-align: center;
  font-weight: bold;
}

// Notification
.margin-top-70px {
  margin-top: 70px;
}

//////////////////////FAQ CSS///////////////////
.phocket-faq {
  color: #000;

  ul {
    list-style-type: none;
    padding: 0;
  }

  .accordion {
    margin: 0;
    background-color: #fff;
    color: $bluecolor;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    border: solid 2px $bluecolor;
    transition: 0.4s;
  }

  .active,
  .accordion:hover {
    border: solid 2px $orangecolor;
    color: $orangecolor;
  }

  .accordion:after {
    content: "\002B";
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }

  .active:after {
    content: "\2212";
  }

  .panel {
    background-color: white;
    max-height: 0;
    overflow: hidden;
    padding: 3px 10px;
    transition: max-height 0.2s ease-out;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;

    ul {
      list-style-type: disc;
      padding: 0px 20px;
    }

    table {
      margin: 10px 0px 10px 40px;

      td,
      th {
        padding: 5px;
        border: solid 1px $bluecolor;
      }
    }
  }
}

.upload-docs {
  margin-bottom: 15px;

  h6 {
    margin-bottom: 10px;
  }

  .mgrpdf__wrapper {
    text-align: left !important;
  }

  img,
  canvas {
    width: 100%;
    max-height: 214px !important;
    display: block;
    margin: auto;
    float: left;
  }

  object {
    height: 210px !important;
    width: 100%;
  }
}

// .mgrpdf-navigation__controls--wrapper{
//   display: none !important;
// }
.height-380px {
  height: 380px;
}

.height-300px {
  height: 300px;
}

///////////////////////CORPORATE PAGE CSS////////////
.phocket-corporate-page {
  font-size: 17px;
  line-height: 2;
  text-align: justify;
  margin-top: 70px;

  h1,
  h2 {
    font-weight: bold;
    margin: 20px;
    text-align: center;
    color: $bluecolor;
  }

  h2 {
    font-size: 40px;
    padding-bottom: 25px;
  }

  .intro-row {
    h1 {
      font-size: 45px;
      color: $orangecolor;
    }

    h2 {
      font-size: 25px;
      padding: 2% 5%;
      text-align: justify;
      font-weight: normal;
      line-height: 1.5;
    }
  }

  .first-corp-row {
    background: linear-gradient(-90deg, #9bbdf9, #6792e8);
    color: $whitecolor;

    h2 {
      font-size: 70px;
      padding: 21% 10%;
      color: $whitecolor;
    }

    p {
      padding: 5% 12%;
      line-height: 2.5;
    }
  }

  .second-corp-row {
    margin-top: 30px;
    margin-bottom: 30px;

    h2 {
      padding: 0 12%;
      color: $bluecolor;
    }

    p {
      padding: 2% 5%;
    }

    .corp-benefits {
      padding: 1% 8%;

      .ben {
        max-width: 250px;
        margin: 1% auto;
        height: 250px;
        border: solid 1px $bluecolor;
        border-radius: 10px;
        text-align: center;
      }

      img {
        width: 75%;
        margin: 12%;
      }
    }
  }

  .third-corp-row {
    color: $bluecolor;
    margin-top: 50px;
    margin-bottom: 50px;

    h2 {
      font-size: 60px;
      padding: 20% 10%;
    }

    .third-title {
      background: url(./images/shapes.png) center no-repeat;
      -webkit-background-size: 100% 100%;
      -moz-background-size: 100% 100%;
      -o-background-size: 100% 100%;
      background-size: 100% 100%;
    }

    img {
      display: block;
      float: right;
      width: 100%;
    }
  }

  .fourth-corp-row {
    margin-bottom: 30px;
    line-height: 3;
    font-size: 18px;
    color: #000;
    background: linear-gradient(#fafafa, #fff);

    h2 {
      color: $bluecolor;
    }

    .corp-terms {
      padding: 1% 15%;

      ul {
        list-style-type: decimal;
        line-height: 1.5;

        .sub-ul {
          list-style-type: disc;
          line-height: 1.5;
          padding: 0 0 0 15px;
        }
      }
    }
  }

  .corp-how-it-works {
    width: 80%;
    margin: 60px auto;

    h4 {
      color: $bluecolor;
    }

    .corp-work-div {
      border: 2px solid rgba(237, 238, 242, 1);
      background: $whitecolor;
      margin: 10px auto;
      max-width: 280px;
      width: 100%;
      height: 300px;
      text-align: center;
      padding: 25px 10px;
      box-shadow: 5px 5px 5px 1px rgba(237, 238, 242, 1);

      p {
        padding: 10px;
        line-height: 1.5;
      }
    }
  }

  .fifth-corp-row {
    padding-right: 0 !important;
    text-align: left;
    margin: 40px auto 60px auto;

    svg {
      width: 100%; //   g:nth-child(3){
      //     g:nth-child(2){
      //   image{
      //     opacity: 0;
      //   }
      // }
      // }
    }

    .first-p {
      text-align: center;
      font-size: 25px;
    }
  }
}

.no-doc-upload {
  margin: 0 auto;
  display: block;
  font-size: 20px;
  padding: 20px;
  font-weight: bold;
  text-align: center;
}

.area-35px {
  max-width: 35px;
  max-height: 35px;
}

.status-div-1 {
  background: #80ced6;
}

.status-div {
  color: $whitecolor;
  height: 70px;
  width: 98%;
  margin: 8px auto;
  padding: 5px;
  text-align: center;

  h3,
  h5 {
    margin: 1px auto;
  }
}

// .status-div-2 {
//   background: #b5e7a0;
// }

// .status-div-3 {
//   background: #d5e1df;
// }

// .status-div-4 {
//   background: #eca1a6;
// }

// .status-div-5 {
//   background: #dac292;
// }

// .status-div-6 {
//   background: #effb91;
// }

// .status-div-7 {
//   background: #f7cac9;
// }

// .status-div-8 {
//   background: #96ceb4;
// }

// .status-div-9 {
//   background: #e4d1d1;
// }

// .status-div-10 {
//   background: #ffeead;
// }

// .status-div-11 {
//   background: #b8a9c9;
// }

// .status-div-12 {
//   background: #f4e1d2;
// }

////////////////////PRICING//////////////////////////
.phocket-pricing-div {
  border: 1px solid $bluecolor;
  background: $whitecolor;
  margin: 10px auto;
  max-width: 350px;
  width: 100%;
  text-align: center;

  p {
    padding: 10px 20px;
    text-align: justify;
  }

  .pricing-div-header {
    font-size: 30px;
    background: linear-gradient($bluecolor, #18a1d1);
    text-decoration: none;
    padding: 7% 2%;
    color: $whitecolor;
    height: 150px;
  }

  .pricing-div-footer {
    display: block;
    background: $bluecolor;
    text-decoration: none;
    padding: 20px;
    color: white;
    position: relative;
    overflow: hidden;
    transition: all 0.3s ease-in-out;

    &:before {
      position: absolute;
      left: -30%;
      top: -10%;
      content: "";
      width: 60%;
      height: 220%;
      transform: rotate(-30deg);
      background: white;
      opacity: 0.3;
      transition: all 0.3s ease-in-out;
    }

    &::after {
      position: absolute;
      content: ">";
      top: 0;
      right: 10%;
      font-size: 25px;
      padding: 15px;
      padding-right: 40px;
      color: white;
      opacity: 0;
      transition: all 0.3s ease-in-out;
    }
  }

  .pricing-div-list {
    padding: 0 30px;
    color: $bluecolor;
    list-style-type: none;

    li {
      padding: 10px;
      border-bottom: solid 1px $bluecolor;

      &:first-child,
      &:last-child {
        border-bottom: 0;
      }

      h3 {
        font-weight: bold;
        color: $orangecolor;
        margin-bottom: 0;
      }

      span {
        width: 12px !important;
      }
    }
  }

  &:hover {
    box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);

    .pricing-div-footer {
      padding-left: 0;
      padding-right: 35px;

      &::before {
        top: -80%;
        transform: rotate(0deg);
        width: 100%;
      }

      &::after {
        opacity: 1;
        padding-right: 15px;
      }

      &:hover {
        background: $orangecolor;
      }
    }
  }
}

.calc-icon img {
  width: 160px !important;
  max-width: 100% !important;
  margin-top: 10px;
}

table.loan-detail-table {
  width: 100%;
  color: $bluecolor;

  th {
    padding: 10px;
  }

  td {
    padding: 6px 10px;
  }

  th,
  td {
    text-align: center;
    border: 1px solid #68cde3;
  }
}

.back-btn {
  border: none;
  background: none;
  color: #3d3d3d;
}

.upload-document-mail {
  background-image: url("./images/pattern-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $bluecolor;
  padding-top: 3%;
  text-align: center;
  color: $bluecolor;
  min-height: 100%;
  height: 100%;
  overflow: auto;

  .container-fluid {
    background-color: rgba(255, 255, 255, 0.9);
    width: 90%;
    max-width: 900px;
    margin: auto;

    label {
      font-size: 13px;
      cursor: pointer;
    }

    img,
    canvas {
      margin: 0 auto;
      display: block;
      float: none;
      margin-bottom: 20px;
    }
  }
}

.communication-tab-list {
  list-style-type: none;
  padding: 0;
  margin: 0;

  li {
    display: inline-block;
    text-align: center;
    cursor: pointer;
    background: #eee;
    padding: 3px 10px;
    border-right: 1px solid $bluecolor;
    border-bottom: 1px solid $bluecolor;

    &:first-child {
      border-left: 1px solid $bluecolor;
    }
  }

  li.active {
    background: $bluecolor;
    color: $whitecolor;
  }
}

.user-msg-table {
  .ReactTable {

    .rt-td,
    .rt-th {
      white-space: normal !important;
    }

    .rt-tbody {
      .rt-td:last-child {
        text-align: justify !important;
      }
    }
  }
}

.background-blur {
  position: fixed;
  background: $fieldrow;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  // color: $bluecolor;
  overflow: auto;
}

.graph-main {
  z-index: 10;
  background-image: linear-gradient($whitecolor, #eeeeee);
  position: fixed;
  top: 15%;
  left: 15px;
  right: 15px;
  height: 480px;
  max-height: 480px;
  max-width: 80%;

  // border: solid 5px $gradientBlue;
  h3 {
    margin: 25px auto;
    color: $orangecolor;
  }

  .edit-profile-input {
    border: solid 1px #ccc !important;
    padding: 10px;
    width: 90%;
    margin: 3px auto;
    color: $orangecolor;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 5px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: $bluecolor;
    opacity: 1;
    z-index: 2;

    &:hover {
      color: $orangecolor;
    }
  }
}

.popup-main {
  z-index: 10;
  position: fixed;
  top: 9%;
  text-align: justify;
  left: 15px;
  right: 15px;
  height: 480px;
  // max-height: 480px;
  max-width: 80%;
  padding: 20px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  // box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);

  // border: solid 5px $gradientBlue;
  h3 {
    margin: 25px auto;
    color: $orangecolor;
  }

  .edit-profile-input {
    border: solid 1px #ccc !important;
    padding: 10px;
    width: 90%;
    margin: 3px auto;
    color: $orangecolor;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 5px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: $bluecolor;
    opacity: 1;
    z-index: 2;

    &:hover {
      color: $orangecolor;
    }
  }
}

.popup-main-new {
  z-index: 10;
  position: fixed;
  top: 0%;
  text-align: justify;
  // left: 15px;
  // right: 15px;
  height: 100%;
  // max-height: 480px;
  max-width: 100%;
  // padding: 20px 0px 20px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  // -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  // box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);

  // border: solid 5px $gradientBlue;
  h3 {
    margin: 25px auto;
    color: $orangecolor;
  }

  .edit-profile-input {
    border: solid 1px #ccc !important;
    padding: 10px;
    width: 90%;
    margin: 3px auto;
    color: $orangecolor;
  }

  .close {
    position: absolute;
    top: 0px;
    right: 5px;
    transition: all 200ms;
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    color: $bluecolor;
    opacity: 1;
    z-index: 2;

    &:hover {
      color: $orangecolor;
    }
  }
}

.admin-pagination-btn {
  border: none;
  background: none;
  color: $teal;
  padding: 0;
  font-size: 25px;
  width: 35px;
  border-radius: 50%;
  line-height: 39px;
  margin: 0 1px;

  &:focus {
    outline: none;
  }

  &:hover {
    background-color: $fieldrow;
  }
}

.send-notification-div-admin {
  width: 70%;
  margin: auto;
}

.doc-mail-admin {
  ul {
    list-style-type: none;
    padding: 0;
    margin: 5px 0 5px 0 !important;

    li {
      display: inline-block;
      margin-right: 20px;
    }
  }
}

.comment-details {
  border: 2px solid #ddd;
  margin-bottom: 15px;
  margin-top: 15px;
  height: 325px;
  width: 100%;
  border-radius: 5px;
  overflow-y: auto;

  hr {
    border: 1px solid $bodycolor;
  }

  ::-webkit-scrollbar-thumb {
    background: $bluecolor !important;
    border-radius: 0 !important;
  }

  h5 {
    padding: 5px;
  }

  .admin-detail-list {
    list-style-type: none;
    padding: 0 !important;

    li {
      display: block;
      border-bottom: 1px solid $bodycolor;

      div.main {
        padding: 17px 10px;
        position: relative;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        cursor: pointer;
        max-height: 55px;
        max-width: 500px;
      }

      .date,
      .mail,
      .type,
      .appid {
        position: absolute;
        font-size: 11px;
      }

      .date {
        top: 1px;
        right: 3px;
      }

      .mail {
        bottom: 1px;
        right: 3px;
      }

      .type {
        bottom: 1px;
        left: 3px;
      }

      .appid {
        top: 1px;
        left: 3px;
      }
    }

    li:nth-child(even) {
      background-color: #eee;
    }
  }

  .popup-bdy {
    word-wrap: break-word;
  }
}

.cashImg {
  color: $bluecolor;
  height: 100%;
  background: url(./images/characters_msg.png) center no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;

  .cash-qrCode-img {
    width: 200px;
  }

  h1 {
    text-align: center;
    font-size: 65px;
    font-style: italic;
  }

  .cash-body {
    margin-top: 6% !important;
  }
}

.cap {
  text-transform: capitalize;
}

.dot-loader {
  text-align: center;
  min-height: 124px;
  z-index: 999;

  span {
    display: inline-block;
    vertical-align: middle;
    width: 10px;
    height: 10px;
    margin: 50px auto;
    background: $bluecolor;
    border-radius: 50px;
    animation: loader 0.9s infinite alternate;
    -webkit-animation: loader 0.9s infinite alternate;
    -moz-animation: loader 0.9s infinite alternate;

    &:nth-of-type(2) {
      animation-delay: 0.3s;
      -webkit-animation-delay: 0.3s;
      -moz-animation-delay: 0.3s;
    }

    &:nth-of-type(3) {
      animation-delay: 0.6s;
      -webkit-animation-delay: 0.6s;
      -moz-animation-delay: 0.6s;
    }
  }

  @keyframes loader {
    0% {
      width: 10px;
      height: 10px;
      opacity: 0.9;
      -webkit-transform: translateY(0);
    }

    100% {
      width: 24px;
      height: 24px;
      opacity: 0.1;
      -webkit-transform: translateY(-5px);
    }
  }

  @-webkit-keyframes loader {
    0% {
      width: 10px;
      height: 10px;
      opacity: 0.9;
      -webkit-transform: translateY(0);
    }

    100% {
      width: 24px;
      height: 24px;
      opacity: 0.1;
      -webkit-transform: translateY(-5px);
    }
  }

  @-moz-keyframes loader {
    0% {
      width: 10px;
      height: 10px;
      opacity: 0.9;
      -moz-transform: translateY(0);
    }

    100% {
      width: 24px;
      height: 24px;
      opacity: 0.1;
      -moz-transform: translateY(-5px);
    }
  }
}

.crm-header-not-icon {
  margin-right: 30px;
  position: relative;
  margin-top: 24px;
}

.get-phocket-app {
  background: $whitecolor;
  position: fixed;
  border: double 6px $bluecolor;
  padding: 15px;
  z-index: 99999;
  width: 100%;
  max-width: 400px;
  top: 75px;
  right: 30px;

  h3 {
    margin-top: 0;
    text-align: center;
  }
}

.word-break {
  word-break: break-all;
}

.rt-resizable-header-content::before {
  content: "\21C5 ";
}

.content-none div.rt-resizable-header-content::before {
  content: "" !important;
}

.rt-th.rt-resizable-header {
  outline: none;
}

.menu-show {
  position: absolute; // top: 2px;
  left: 2px;
  z-index: 10;
}

.captcha-img {
  img {
    display: block;
    margin: auto;
  }

  input {
    margin-top: 33px;
  }
}

.max-width-100px {
  max-width: 100px;
}

.max-width-400px {
  max-width: 400px;
  margin: 0 auto;
}

.max-width-200px {
  max-width: 200px;
}

.max-width-50px {
  max-width: 50px;
}

.mw-100 {
  max-width: 100% !important;
}

//////////////////////////////ADMIN DASHBOARD////////////////////////
.dashbpard-reload {
  background: darkgray;
  padding: 7px;
  margin-top: 17px;
  border-radius: 23px;
}

.dashboard-tabs {
  background: darkgray;
  width: 139px;
  border-radius: 24px;
  padding: 7px;
  margin-top: 17px;

  .active {
    border-left: none !important;
    background: black;
    border-radius: 24px;
    color: white;
  }
}

.recovery-tabs {
  .dashboard-tabs {
    background: darkgray;
    width: 110px;
  }
}

.leadmanagement-tabs {
  background: darkgray;
  width: 405px;
  border-radius: 24px;
  margin-left: 10px;
  padding: 7px;
  margin-top: 17px;

  .active {
    border-left: none !important;
    background: black;
    border-radius: 24px;
    color: white;
  }
}

.admin-name {
  // background: #006fbc;
  // border-radius: 17px;
  color: #000;
  font-weight: 400;
  // padding: 7px;
  font-size: 10px;
}

.admin-dash {
  .bank-card {
    min-height: 99px !important;
    background: #fff !important;

    &:hover {
      background: #d7f4b2 !important;
    }
  }

  .db-filter-btn {
    background: #449d44;
    color: $whitecolor;
    padding: 10px 20px;
    margin-right: 25px;
    border: none;

    &:hover {
      background: #398439;
    }
  }

  .recharts-wrapper {
    max-width: 100% !important;

    svg.recharts-surface {
      max-width: 100% !important;

      g.recharts-layer.recharts-cartesian-axis {
        font-size: 10px !important;
      }
    }
  }
}

.admin-filter-content {
  width: 300px;
  background: $whitecolor;
  position: absolute;
  right: 0;
  top: 130px;
  bottom: 0;
  z-index: 999;
  box-shadow: inset 0 0 20px #5cb85c;
  border-top-left-radius: 10px;
  padding: 20px;

  .ryms-container {
    border: none !important;
    width: 100% !important;
  }

  .ryms-selected_cell {
    background: $bluecolor;
    font-style: normal;
  }

  .ryms-col_mp:hover {
    background: $bluecolor;
  }

  .ryms-selected_date_mp {
    color: $bluecolor;
    padding: 14px !important;
  }

  .ryms-col_mp {
    padding: 10px;
    border: solid 1px #ccc;
  }

  .filter-btn {
    background-color: $bluecolor;
    color: $whitecolor;
    padding: 15px 25px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s;
    margin: 10px 0;
  }

  .filter-btn:hover,
  .filter-btn:focus {
    border-radius: 0;
  }

  .filter-dropdown {
    position: relative;
    display: inline-block;
  }

  .filter-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .filter-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .filter-dropdown a:hover {
    background-color: #ddd;
  }

  .filter-show {
    display: block;
  }
}

.menu-by-status {
  .status {
    border: 1px solid $bluecolor;
    border-radius: 5px;
    width: 100%;
    height: 60px;
    line-height: 28px;
    padding: 0 10px;
    white-space: nowrap;
    margin: 10px auto;
    color: $bluecolor;
    max-width: 200px;
  }
}

.Phocket-crm-role-assign {
  ul.roles {
    list-style-type: none;
    padding: 0;
    margin: 0;
    text-align: center;

    li {
      float: left; // display: inline-block;
      padding: 0 20px;
    }
  }
}

.roleNames {
  span:after {
    content: ", ";
  }

  span:last-child:after {
    content: none;
  }
}

.statement-checkbox-div {
  display: none;
}

.loan-detail-table {
  tbody {
    tr {
      td:first-child {
        .statement-checkbox-div {
          margin-right: 6px;
          display: block;
        }
      }
    }
  }
}

.incom-app {
  text-align: center;
  color: $bluecolor;

  h4 {
    line-height: 2.7;
    font-weight: bold;
  }
}

.comm-btn {
  padding: 10px;
  border: solid 1px $bluecolor;
  background: $whitecolor;
  color: $bluecolor;
  margin: 10px 0 20px 0;

  &:hover {
    color: $orangecolor;
  }

  &:focus {
    background: $bluecolor;
    color: $whitecolor;
  }
}

.phocket-blogs {
  margin: 60px auto;
  height: 100%;

  h4 {
    color: $bluecolor;
  }

  .blog-btns {
    padding: 15% 2px;

    span {
      font-size: 25px;
    }
  }

  .phocket-blog-div {
    border: 2px solid rgba(237, 238, 242, 1);
    background: $whitecolor;
    margin: 10px auto;
    max-width: 350px;
    width: 100%;
    height: 400px;
    text-align: center;
    padding: 25px 10px;
    box-shadow: 5px 5px 5px 1px rgba(237, 238, 242, 1);

    .header-img {
      height: 200px;
    }

    .blog-body {
      height: 125px;

      p {
        padding: 10px;
        line-height: 1.5;
        text-align: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}

.crm-personal-profile {
  text-align: right;

  a {
    text-decoration: none;
    text-transform: uppercase;
  }

  .dropdown-menu {
    left: -100px !important;
    top: 45px !important;
    padding: 0px;
  }
}

.richText {
  height: 150px;
  width: 100%;
  border: 1px solid #ccc;
  outline: none;
  border-radius: 4px;
  padding: 6px 12px;
  overflow: auto;
}

.sound-switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 3px;

  input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 20px;
    left: 0px;
    bottom: -8px;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked+.slider {
    background-color: #2196f3;
  }

  input:focus+.slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked+.slider:before {
    -webkit-transform: translateX(30px);
    -ms-transform: translateX(30px);
    transform: translateX(30px);
    background-color: #2196f3;
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

ul.admin-msg-bank-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid #ccc;
  margin-top: 15px;

  li {
    padding: 10px;
    cursor: pointer;
  }

  li:nth-child(even) {
    background-color: #ccc;
  }
}

.white-space-normal {
  white-space: normal !important;
}

.credit-detail-popup {
  position: fixed;
  z-index: 99;
  border: 1px solid $bluecolor;
  box-shadow: -5px -5px 10px #ccc;
  bottom: 0px;
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  overflow: auto;
  resize: both;
  max-width: 820px;
  max-height: 600px;

  #creditScorePopupHeader {
    height: 10%;
    cursor: move;
    min-height: 30px;
    text-align: center;
  }

  .Credit-detail-body {
    height: 90%;
    max-height: 565px;
    overflow: auto;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 0px;
    font-size: 30px;
    color: $whitecolor;
    opacity: 1;
  }
}

/////////////////////////coupons/////////////
.coupon-container {
  background: $whitecolor;
  position: fixed;
  border: double 6px $bluecolor;
  padding: 15px;
  z-index: 99999;
  width: 100%;
  max-width: 500px;
  top: 10%;
  right: 20%;

  h3 {
    margin-top: 0;
    text-align: center;
  }

  .coupon-close {
    display: none;
  }

  table {
    width: 90%;
    margin: auto;

    th {
      background: $bluecolor;
      color: $whitecolor;
    }

    td,
    th {
      text-align: center;
      padding: 15px;
      border: solid 1px $lightblue;
    }
  }
}

.btn-active {
  background-color: $orangecolor !important;
  color: $whitecolor !important;
  background-image: none;
}

.light-dark-background {
  background-color: $fieldrow;
}

.crm-activity-view {
  position: absolute;
  right: 0;
  top: 70px;
  background-color: #fff;
  border-left: 1px solid $bluecolor;
  bottom: 0;
  width: 300px;
  z-index: 2;
  overflow: auto;

  .slick-list {
    height: 100% !important;
  }

  .activity-list:nth-child(odd) {
    background-color: $fieldrow;
  }

  .activity-list {
    border-bottom: 1px solid $bluecolor;
    max-height: 60px;
    height: 60px;
    position: relative;
    overflow: hidden;

    .activity-absolute {
      position: absolute;
      font-size: 10px;
    }

    .activity-date {
      top: 1px;
      right: 5px;
    }

    .activity-to {
      bottom: 1px;
      left: 5px;
    }

    .activity-from {
      top: 1px;
      left: 5px;
    }

    .activity-subject {
      line-height: 60px;
      white-space: nowrap;
      font-size: 15px;
      padding-left: 5px;
      max-width: 100%;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}

.crm-activity-login {
  border: none;
  color: $bluecolor;
  background-color: $fieldrow;
  margin: 10px auto;
  padding: 5px 10px;
  max-width: 65px;
  display: block;
  cursor: pointer;
}

.activity-animate-left {
  animation: activityanimateLeft 0.5s !important;
  -webkit-animation: activityanimateLeft 0.5s !important;
}

.activity-animate-right {
  animation: activityanimateright 0.5s !important;
  -webkit-animation: activityanimateright 0.5s !important;
}

@keyframes activityanimateLeft {
  from {
    right: -300px;
  }

  to {
    right: 0px;
  }
}

@-webkit-keyframes activityanimateLeft {
  from {
    right: -300px;
  }

  to {
    right: 0px;
  }
}

@keyframes activityanimateright {
  from {
    right: 0px;
  }

  to {
    right: -300px;
  }
}

@-webkit-keyframes activityanimateright {
  from {
    right: 0px;
  }

  to {
    right: -300px;
  }
}

// .vertical-text {
// 	transform: rotate(90deg);
// 	transform-origin: left top 0;
// }

.activity-btn {
  position: absolute;
  right: 0;
  color: $teal;
  background-color: $gradientBlue;
  border: none;
  z-index: 1;
  border-radius: 4px;
  padding: 4px;
}

//////////////////////////COUPONS CSS//////////////////
.admin-coupon-details {
  h3 {
    color: $bluecolor;
    text-transform: uppercase;
  }

  .phocket-coupon-div {
    border: 1px solid $bodycolor;
    background: $whitecolor;
    margin: 10px auto;
    max-width: 390px;
    width: 100%;

    text-align: center;

    .coupon-div-header {
      img {
        max-height: 100%;
        max-width: 100%;
        margin-top: 20px;
      }
    }

    .coupon-div-footer {
      display: block;
      background: $bluecolor;
      text-decoration: none;
      padding: 20px;
      color: white;
      position: relative;
      overflow: hidden;
      transition: all 0.3s ease-in-out;

      &:before {
        position: absolute;
        left: -30%;
        top: -10%;
        content: "";
        width: 60%;
        height: 220%;
        transform: rotate(-30deg);
        background: white;
        opacity: 0.3;
        transition: all 0.3s ease-in-out;
      }

      &::after {
        position: absolute;
        content: ">";
        top: 0;
        right: 10%;
        font-size: 25px;
        padding: 15px;
        padding-right: 40px;
        color: white;
        opacity: 0;
        transition: all 0.3s ease-in-out;
      }
    }

    .coupon-div-desc {
      color: $bluecolor;
      max-height: 350px;
      overflow: auto;
      text-align: left;
      padding: 0 10px;
    }

    &:hover {
      box-shadow: 0px 0px 19px -3px rgba(0, 0, 0, 0.36);

      .coupon-div-footer {
        padding-left: 0;
        padding-right: 35px;

        &::before {
          top: -80%;
          transform: rotate(0deg);
          width: 100%;
        }

        &::after {
          opacity: 1;
          padding-right: 15px;
        }

        &:hover {
          background: $orangecolor;
        }
      }
    }
  }
}

.coupon-blue-bg {
  background-color: rgba(0, 111, 188, 0.5);
  border-radius: 10px;
}

.offer-location {
  max-width: 400px;
  margin: 0 auto;
}

.multiline-ellipsis {
  display: -webkit-box;
  max-width: 400px;
  height: 110px;
  margin: 0 auto;
  line-height: 1.4;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.coupont-detail-box {
  max-width: 100%;
  border-top: 5px solid $orangecolor;
  box-shadow: 10px 10px 30px 10px #ccc;
  min-height: 400px;
}

.phocket-ambassador-page {
  margin-top: 70px;
  line-height: 1.8;
  color: $bluecolor;
  text-align: center;

  h1 {
    font-size: 45px;
    color: #f2483a;
  }

  .amb-second-row {
    text-align: left;
    color: #000;
    margin: 30px auto;
    border: 2px solid #edeef2;
    box-shadow: 5px 5px 5px 1px #edeef2;
    background: linear-gradient(-90deg, #f0f2f7, #cddeff);
    padding: 20px 10px;

    h2 {
      font-size: 30px;
      color: $bluecolor;
    }
  }

  .amb-how-it-works {
    width: 80%;
    margin: 30px auto;

    h4 {
      color: $bluecolor;
    }

    .amb-work-div {
      border: 2px solid rgba(237, 238, 242, 1);
      background: $whitecolor;
      margin: 10px auto;
      max-width: 280px;
      width: 100%;
      height: 270px;
      text-align: center;
      padding: 25px 10px;
      box-shadow: 5px 5px 5px 1px rgba(237, 238, 242, 1);

      small {
        font-size: 80%;
      }
    }
  }

  .amb-last-row {
    padding: 10px 10%;

    .support-and-training {
      text-align: left;

      ul {
        color: #000;
      }
    }
  }

  .profile-save-btn {
    padding: 15px;

    &:hover,
    &:focus {
      background: $orangecolor;
      text-decoration: none;
      color: $whitecolor;
    }
  }
}

.coupon-view-box {
  width: 100%;
  border: 1px solid #ccc;
  padding: 15px 0;
  margin-bottom: 20px;

  .offer-title {
    // height: 100px;
    width: 120px;
    border: 1px dashed #ccc;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    padding: 20px 0;
    min-height: 85px;
  }

  .complete-offer-detail {
    .coupon-heads {
      color: #968e8e;
      font-size: 14px;
    }
  }
}

.amb-profile {
  .profile-save-btn {
    margin: 5px;
  }

  .modal-footer {
    display: none !important;
  }

  .modal-header {
    border-bottom: none !important;

    h4 {
      padding: 0;
    }
  }

  .modal-body {
    width: 80%;
    margin: auto;

    input {
      margin: 7px;
    }
  }

  #mailModal .modal-body {
    width: 100%;

    input {
      margin: 10px auto;
    }
  }
}

.merchant-list {
  table {
    margin: 15px auto !important;
    width: 100%;
  }
}

#detailModal {

  h6,
  h3 {
    color: $orangecolor;
  }
}

.amb-skip-save {
  .profile-save-btn {
    margin-bottom: 40px !important;
  }
}

.amb-form-img {
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  margin-top: 10%;
}

.amb-thnks-media-btns {
  list-style-type: none;
  text-align: center;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    margin: 0 10px;
  }
}

.merchant-signin {
  .phocket_signIn {
    width: 40%;
    max-height: 300px;
    margin: 12% auto;
  }

  .logo {
    margin-top: 10px;
  }
}

.copy-btn {
  margin: 4% 7% !important;
}

.max-height-370px {
  max-height: 370px;
  overflow: auto;
}

.max-height-50px {
  max-height: 50px;
  overflow: auto;
}

.overall-score {
  background: #7f8c8d;
  color: #fff;
  width: 50px;
  margin: auto;
  margin-top: -30px !important;
  text-align: center;
  border-radius: 50%;
  height: 50px;
  line-height: 50px;
}

.auto-suggestion {
  max-width: 400px;
  width: 100%;
  position: absolute;
  z-index: 1000;
  left: 15px;
  top: 56%;
  border: 1px solid #ccc;
  background-color: $whitecolor;
  max-height: 200px;
  overflow: auto;
  overflow-x: visible;

  .auto-suggestion-close {
    margin: 0;
    padding: 0;
    line-height: 1;
    position: absolute;
    background: none;
    border: none;
    right: 0;
    z-index: 1001;
    font-size: 20px;
    top: 0px;
  }

  p {
    font-size: 13px;
    padding: 5px;
    border-bottom: 1px solid #ccc;
    margin: 0;
    cursor: pointer;
  }

  p:last-child {
    border-bottom: none;
  }
}

.contentEditable {
  height: 100px;
  overflow: auto;
  resize: both;
}

.coupon-delete-btn {
  position: absolute;
  left: 100%;
  bottom: 100%;
  border: 1px solid red !important;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 0px;
  background: red !important;
  color: $whitecolor !important;
  opacity: 1;
}

.state-color-list {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding: 10px 10px 10px 0;
    line-height: 20px;

    .state-color-box {
      height: 20px;
      width: 20px;
      border: 1px solid $bluecolor;
      display: block;
      float: left;
      margin-right: 5px;
      border-radius: 50%;
    }

    .rajasthan-color {
      background-color: #fbd6dc;
    }

    .west-bengal-color {
      background-color: #bfe1ef;
    }

    .Maharashtra-color {
      background-color: #daf7a6;
    }

    .Karnataka-color {
      background-color: #aac0aa;
    }

    .loan-adda-color {
      background-color: #f9f4ae;
    }
  }
}

.coupon-view-btns {
  cursor: pointer;
  text-align: center;
  float: right;
  padding: 0 15px;
}

.height-250px {
  height: 250px !important;
}

.fileUpload {
  position: relative;
  overflow: hidden;
  background: $bluecolor;
}

.external-header {
  padding-left: 15px;
  color: $orangecolor;
}

.fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
}

.field-one {
  margin-top: 10px;

  h6 {
    color: $bluecolor;
  }
}

.btn--browse {
  border-radius: 0 2px 2px 0;
  background-color: $bluecolor;
  color: $whitecolor;
  height: 42px;
  padding: 10px 14px;

  &:hover {
    background-color: $whitecolor;
    color: $bluecolor;
    border: 1px solid $bluecolor;
  }
}

.f-input {
  height: 42px;
  background-color: $whitecolor;
  border: 1px solid $bluecolor;
  width: 70%;
  max-width: 300px;
  float: left;
  padding: 0 14px;
}

.external-textarea {
  border: 1px solid $bluecolor;
  width: 100%;
}

.ql-container.ql-snow {
  height: 310px;
}

.whatsapp-msg {
  border: 1px solid #ccc;
  height: 230px;
  overflow: auto;
  border-radius: 5px;
  // background-color: #e3dbd3;
  word-break: break-all;

  // position: relative;
  .whatsappmsg {
    padding: 5px 5px 15px 5px;
    border-radius: 5px;
    margin: 5px 12px;
    position: relative;
    min-width: 100px;
    max-width: 370px;
    font-size: 14px;

    .msgDate {
      padding-top: 3px;
      position: absolute;
      bottom: 2px;
      right: 3px;
      font-size: 9px;
      white-space: nowrap;
      overflow: hidden;
      color: #fff;
    }

    .tail-container {
      content: "";
      position: absolute;
      top: -3px;
      width: 17px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: contain;
    }
  }

  .msg-left {
    background-color: #DBE9FF;
    float: left;


    .tail-container {
      left: -8px;
      top: -7.5px;
      background-image: url("./images/rewampimages/Polygon 3 (2).png");
    }
  }

  .msg-right {
    background-color: #509DE1;
    float: right;
    color: #fff;

    .tail-container {
      right: -8px;
      top: -7.5px;
      background-image: url("./images/rewampimages/Polygon 3 (1).png");
    }
  }
}

.isEmpty:empty {
  display: none;
}

.min-height-135px {
  min-height: 135px;
}

.loan-lead-search {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    width: 20%;
    padding: 0 15px;
    vertical-align: text-top;
  }
}

.loan-lead-search-new {
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    width: 14%;
    padding: 0 15px;
    vertical-align: text-top;
  }
}

.area-200px {
  max-width: 200px;
  max-height: 200px;
}

.resize-none {
  resize: none;
}

.whatsapp-loader {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 100%;
  background-color: $fieldrow;
  text-align: center;
  padding-top: 15%;
}

.whatsapp-doubble-tick {
  letter-spacing: -7px;
  margin-right: 5px;
}

.blue-tick {
  color: #52c4f6;
}

.id-verification-page {
  background-image: url("./images/pattern-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  height: 100% !important;

  .max-width-500px {
    margin-top: 18%;
  }

  p {
    border-radius: 50%;
    font-size: 50px;
    width: 70px;
    margin: auto;
    margin-top: 10px;
    background-color: $bluecolor;
    color: $whitecolor;
  }

  .row {
    background-color: rgba(255, 255, 255, 0.8);
    max-height: 300px;
  }

  h3 {
    color: $bluecolor;
  }
}

.whatsapp-input-container {
  min-height: 90px;
  overflow: auto;
}

.rc-time-picker-panel-select {
  overflow-x: hidden;

  li {
    padding-left: 0 !important;
    text-align: center !important;
  }
}

.height-42px {
  height: 42px;
}

.amb-comments {
  max-height: none !important;
  max-width: none !important;

  div.display-flex {
    white-space: normal;
  }
}

.lh-60px {
  line-height: 60px;
}

.emi-loader {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;
  background-color: $fieldrow;

  .fa {
    font-size: 60px;
    color: $bluecolor;
    margin: auto;
    max-width: 60px;
    display: block;
    margin-top: 30%;
  }
}

.calc-tabs {
  margin: 0;
  padding: 0;
  list-style-type: none;
  text-align: center;

  li {
    display: inline-block;
    padding: 10px 20px;
    color: $bluecolor;
    background-color: $whitecolor;
    border: 1px solid $bluecolor;
    cursor: pointer;
  }

  li.active {
    background-color: $lightblue;
    color: $bluecolor;
  }
}

.loan-id-input {
  max-width: 100%;
  width: 70%;
  height: 20px;
}

.loan-id-save-btn {
  line-height: 1 !important;
  font-size: 26px !important;
  opacity: 0.7;
  cursor: pointer;
  color: $orangecolor !important;
}

.bulk-communication-filter {
  li {
    width: calc(100% / 8) !important;
  }
}

li.rangeslider__label-item:after {
  content: "|";
  position: absolute;
  /* top: 0; */
  left: 0;
  bottom: 23px;
  z-index: 0;
}

.mt-20px {
  margin-top: 20px;
}

.mt-0px {
  margin-top: 0px !important;
}

.v-align {
  vertical-align: middle;
}

.lead-select {
  width: 100%;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.dashboard-table {
  table {
    border-collapse: unset !important;
  }

  tbody {
    border-collapse: unset !important;
  }

  width: 100%;
  margin-top: 15px;

  th,
  td {
    padding: 5px;
    text-align: center;
    border: 1px solid #ccc;
  }
}

.lgbg {
  background-color: #eee;
}

.leads-dashboard {
  text.recharts-text.recharts-cartesian-axis-tick-value {
    font-size: 12px;
  }
}

.lead-dashboard-card {
  // cursor:pointer;
  position: relative;
  min-height: 80px;
  width: 100%;
  box-shadow: 5px 5px 10px #ccc;
  border: 1px solid #ccc;
  margin-top: 30px;
  border-radius: 5px;
  max-width: 220px;
  margin: 30px auto 0 auto;

  .icon {
    position: absolute;
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    top: -20px;
    background-color: #f44336;
    color: #fff;
    border-radius: 5px;
    left: 15px;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.2), 0 2px 13px 0 rgba(0, 0, 0, 0.19);
  }

  .heading {
    float: right;
    margin: 5px 10px 0 81px;
    // margin: 5px 10px 0 0;
    color: #999999;
    font-size: 12px;
  }

  h2,
  .card-values {
    margin-top: 25px;
    text-align: right;
    padding-right: 20px;
  }
}

.form-box input {
  width: 100%;
  padding: 20px;
  text-align: auto;
  display: flex;
  justify-content: space-around;
  border: 0;
  box-shadow: 0 0 0;
  height: 40px;
  border-bottom: 2px solid gray;
  font-weight: bold;
  background: white;
}

.form-box .logi {
  color: white;
  margin-top: 30px;
  width: 50%;
  padding: 10px;
  text-align: center;
  height: 40px;
  border-radius: 25px;
  // background: #006fbc;
  // border-color: white;
  cursor: pointer;
}

//////////////////////////CAMPAIGN TRACK//////////////////////////
.track-box {
  width: 90%;
  margin: 5px auto;
  border-radius: 7px;
  padding: 10px;
  background-image: linear-gradient(-90deg, $whitecolor, rgba(0, 136, 254, 0.7));
}

.mm {
  margin-left: 53%;
}

.kk {
  width: 100%;
  background-color: #006fbc;
  padding: 5px;
  color: white;
}

.nn {
  margin-left: 32%;
  margin-top: -9%;
}

.area-100 {
  height: 100%;
  width: 100%;
}

.phocket-table {
  width: 100%;
  font-size: 14px;
  overflow-x: scroll;

  thead {
    background-color: #0c3953;
    color: $whitecolor;

    th {
      padding: 10px 5px !important;
    }
  }

  th {
    border: 1px solid #eee;
    padding: 5px 8px;
    text-align: center;
  }

  td {
    border: 1px solid #eee;
    padding: 5px 8px;
    text-align: center;
    white-space: nowrap;
  }
}

.phocket-table-tea {
  width: 100%;
  font-size: 14px;
  overflow-x: scroll;

  thead {
    th {
      background-color: #0c3953;
      color: $whitecolor;
      padding: 10px 5px !important;
      border: 1px solid #eee;
      padding: 5px 8px;
      text-align: center;
    }
  }

  td {
    border: 1px solid #eee;
    padding: 5px 8px;
    text-align: center;
    white-space: nowrap;
  }
}

.background-none-btn {
  border: none;
  background: none;

  &:focus {
    outline: none;
  }
}

.min-width-100px {
  min-width: 100px;
}

.phocket-pagination {
  text-align: center;
}

.pt-10px {
  padding-top: 10px;
}

.phoc {
  margin-top: 10px;
}

.multi-select {
  min-height: 34px !important;

  .selected-options {
    min-height: 34px !important;
  }

  .arrow {
    margin-top: 8px !important;
  }
}

.third-party {
  border: solid 1px $bodycolor;
  padding: 10px;
  width: 400px;
  margin: 30px auto;
}

.bank-skip-btn {
  float: right;
  border: 1px solid $bluecolor;
  background: no-repeat;
  color: $bluecolor;
  border-radius: 4px;
  padding: 5px 10px;
}

//////////////////////////////////NEW ADMIN CSS///////////////////////////
.eye-popup-menu {
  .edit-profile-menu {
    padding: 5px !important;
  }
}

.user-detail-tab {
  .edit-profile-menu {
    .nav {
      display: inline-block !important;

      a {
        width: 110px !important;
        padding: 5px !important;
      }
    }
  }
}

.main-search {
  display: flex;
  min-width: inherit !important;

  select {
    border-radius: 8px 0 0 8px;
    border-right: 0 !important;
    padding: 15px 14px 15px 11px;
    width: 40%;
    border: 1px solid #A8C8FF
  }

  input {
    border-radius: 0 8px 8px 0;
    width: 100% !important;
    border: 1px solid rgba(0, 0, 0, 0.25);
    padding: 14.5px;
    min-width: inherit !important;

  }

  select:focus,
  input:focus {
    outline: none;
    box-shadow: none !important;
  }
}

.btn-group {
  button {
    background: #39383b;
    border: 1px solid #39383b;
    color: white;
    padding: 10px;
    width: auto;
    cursor: pointer;
    float: left;

    &:not(:last-child) {
      border-right: none;
    }

    &:hover,
    .active {
      background: #95a5a6;
      color: #000;
    }

    &:focus {
      outline: 0;
    }
  }

  .btn-bg-new {
    border: none;
    background: #0c3953;
    padding: 8px !important;
  }

  btn-group &:after {
    content: "";
    clear: both;
    display: table;
  }

  .active {
    border-bottom: 6px solid #23a4cc;
  }
}

.nbfc-btn-group {
  button {
    background: #39383b;
    color: white;
    padding: 10px;
    width: 250px;
    cursor: pointer;
    float: left;

    &:not(:last-child) {
      border-right: none;
    }

    &:hover,
    .active {
      background: #95a5a6;
      color: #000;
    }

    &:focus {
      outline: 0;
    }
  }

  .btn-bg-new {
    border: none;
    background: #0c3953;
    padding: 8px !important;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  .active {
    background: #fff;
    color: #000;
  }
}

.btn-group-tab {

  // margin: 1px 1px 10px 1px !important;
  button {
    background: #fff;
    border: 1px solid #fff;
    color: black;
    padding: 6px;
    width: auto;
    min-width: 100px;
    cursor: pointer;
    float: left;

    &:not(:last-child) {
      border-right: none;
    }

    &:hover,
    .active {
      background: #23a4cc;
    }

    &:focus {
      outline: 0;
    }
  }

  .btn-bg-new {
    background-image: linear-gradient($whitecolor, $gradientBlue) !important;
  }

  &:after {
    content: "";
    clear: both;
    display: table;
  }

  .active {
    border-bottom: 6px solid #23a4cc;
  }
}

.grey {
  background-color: $teal;
  color: $whitecolor !important;
  border-radius: 0 !important;

  &:focus {
    outline: 0;
    background: $teal;
  }
}

.teal-text {
  color: $teal;
}

.teal-bg {
  background: $teal;
}

.small-popup {
  height: auto !important;
  width: 600px;
}

.small-popup-new {
  height: 529px;
  width: 600px;
  overflow: auto;
}

.profile-details-box {
  border: solid 1px #39383b;
  margin-bottom: 20px;
  border-radius: 10px;
  min-height: 410px;

  p {
    padding: 1px 10px;
  }
}

.comment-details-box {
  border: solid 1px #39383b;
  margin-bottom: 20px;
  height: 200px;
  border-radius: 10px;

  p {
    padding: 1px 10px;
  }
}

.graph-details-box {
  background: white;
  box-shadow: 8px 7px 7px 0px #d3d3d3;
  margin-bottom: 66px;
  max-width: 600px;
}

div.ReactTags__tags {
  position: relative;
  width: calc(100% - 30px) !important;
  margin-left: 30px !important;
  border-bottom: 1px solid #ced4da !important;
}

/* Styles for the input */
div.ReactTags__tagInput {
  width: 100%;
  border-radius: 2px;
  display: inline-block;
}

div.ReactTags__tagInput input.ReactTags__tagInputField,
div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
  height: 30px;
  margin: 0;
  font-size: 12px;
  width: calc(100% - 1.5rem);
  border: none;
  padding: 0.375rem 0.75rem;
  outline: none;
  background: transparent;
}

/* Styles for selected tags */
div.ReactTags__selected span.ReactTags__tag {
  border: 1px solid #242424;
  background-color: #4f4f4f;
  color: $whitecolor;
  font-size: 12px;
  padding: 5px;
  margin: 2px 5px;
  cursor: move;
  border-radius: 2px;
  float: left;
}

div.ReactTags__selected a.ReactTags__remove {
  color: $whitecolor;
  margin-left: 5px;
  cursor: pointer;
}

/* Styles for suggestions */
div.ReactTags__suggestions {
  position: absolute;
}

div.ReactTags__suggestions ul {
  list-style-type: none;
  box-shadow: 0.05em 0.01em 0.5em rgba(0, 0, 0, 0.2);
  background: white;
  width: 200px;
}

div.ReactTags__suggestions li {
  border-bottom: 1px solid #ddd;
  padding: 5px 10px;
  margin: 0;
}

div.ReactTags__suggestions li mark {
  text-decoration: underline;
  background: none;
  font-weight: 600;
}

div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
  background: #b7cfe0;
  cursor: pointer;
}

.ReactTags__tagInputField::-webkit-input-placeholder {
  font-size: 1rem;
}

.ReactTags__tagInputField::-moz-placeholder {
  font-size: 1rem;
}

.ReactTags__tagInputField:-ms-input-placeholder {
  font-size: 1rem;
}

.ReactTags__tagInputField::placeholder {
  font-size: 1rem;
}

.whatsapp-popup {
  p {
    margin: 0 0 10px;
    white-space: normal;
    text-align: left;
    line-height: 1.2;
    font-size: 13px;
    border-bottom: 1px solid #eee;

    &:first-child {
      margin-top: 10px;
    }
  }
}

.bank-analysis-label {
  tspan {
    font-size: 10px !important;
  }
}

.flags-info {
  p {
    margin: 2px !important;
  }

  .profile-details-box {
    min-height: 150px !important;
  }
}

.bank-analysis-dashboard {
  // background: #f2f2f2;
  height: 100%;

  .show {
    display: block !important;
  }

  .btn-danger {
    position: fixed;
    border-radius: 0;
    z-index: 999;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: 2px;
    -ms-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);

    &:focus {
      outline: 0;
    }
  }

  .flag-btn {
    right: -15px;
    top: 35%;
  }

  .app-btn {
    right: -62px;
    top: 60%;
  }

  .recharts-wrapper {
    margin: 10px auto;
  }

  .bank-analysis-table {
    table {
      margin: 5px auto;
      font-size: 12.5px;
      border: solid 1px #ccc;
      background: #fff;
    }

    th {
      background: #e5e5e5;
      padding: 15px 10px;
      text-transform: uppercase;
    }

    td {
      padding: 10px;
    }

    tr {
      border-bottom: solid 1px #ccc;
    }
  }

  .bank-details-box {
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.3);
    margin: 10px auto;
    width: 98%;
    padding: 10px;
    background: $whitecolor;
    display: block;
  }

  .check-container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .check-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #fff;
    border: solid 1px #2196f3;
  }

  .check-container:hover input~.checkmark {
    background-color: #fff;
  }

  .check-container input:checked~.checkmark {
    background-color: #2196f3;
  }

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .check-container input:checked~.checkmark:after {
    display: block;
  }

  .check-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

.negative-box {
  box-shadow: 0 0 4px 0 #d40202;
  background: #fddddd;
  padding: 10px;
}

.positive-box {
  box-shadow: 0 0 4px 0 #008c02;
  background: #daffb2;
  padding: 10px;
}

.green-bg-new {
  background: #e5f5ec;
  padding: 10px;

}

.general-box {
  box-shadow: 0 0 4px 0 #ccc;
  background: $whitecolor;
  padding: 10px;
}

.table {
  width: 100%;
  font-size: 14px;

  thead {
    background-color: #2c3e50;
    color: $whitecolor;
  }

  tbody {
    tr {
      &:nth-child(even) {
        background-color: #eee;

        td {
          border: 1px solid #fff;
        }
      }
    }
  }

  th,
  td {
    border: 1px solid #eee;
    padding: 4px 7px;
    text-align: center;
    white-space: nowrap;
  }
}

.white {
  color: $whitecolor;
}

.btn-nbfc {
  background: #e0525c;
}

.nbfc-button {
  position: relative;
  background: #0c3953;
  border: 1px solid black;
  font-size: 14px;
  color: #ffffff !important;
  padding: 8px;
  width: 100px;
  text-align: center;
  -webkit-transition-duration: 0.4s;
  /* Safari */
  transition-duration: 0.4s;
  text-decoration: none;
  overflow: hidden;
  cursor: pointer;
}

.nbfc-button:after {
  content: "";
  background: rgb(98, 151, 204);
  display: block;
  position: absolute;
  padding-left: 350%;
  margin-left: -20px !important;
  margin-top: -120%;
  opacity: 0;
  transition: all 0.8s;
}

.nbfc-button:active:after {
  padding: 0;
  margin: 0;
  opacity: 1;
  transition: 0s;

  &:hover {
    color: $orangecolor;
  }
}

.red {
  background-color: #7f8c8d;
  color: $whitecolor !important;
  border-radius: 0 !important;

  &:focus {
    outline: 0;
    background: rgb(129, 147, 148);
  }
}

.mage {
  background-color: #0c3953;
  color: $whitecolor !important;
  border-radius: 0 !important;

  &:focus {
    outline: 0;
    background: rgb(152, 169, 170);
  }
}

.bt-color {
  background: #39383b;
}

.tab-color {
  background: #fff;
}

.trans {
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.flowHide {
  overflow: hidden;
}

/*===========================NAVBAR START===========================*/

.bg-dark {
  background-color: #292929 !important;
}

.navbar {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

/*HAMBURGER START*************************/

.hamburger {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px 10px 0px 0px;
  margin-left: -15px;
  transition: all 1s ease;
}

.cta {
  height: 50px;
  width: 50px;
  cursor: pointer;
}

.toggle-btn {
  height: 2px;
  width: 25px;
  background-color: #fff;
  position: relative;
  top: 50%;
  left: 0;
  margin: auto;
  transition: all 0.3s ease-in-out;
}

.toggle-btn:before {
  content: "";
  height: 2px;
  width: 25px;
  box-shadow: 0 -10px 0 0 #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.toggle-btn:after {
  content: "";
  height: 2px;
  width: 25px;
  box-shadow: 0 10px 0 0 #fff;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s ease-in-out;
}

.active .type14 {
  background-color: #fff;
  width: 12px;
  margin: auto;
}

.active .type14:before {
  width: 6px;
}

.active .type14:after {
  width: 25px;
}

/*HAMBURGER END*************************/

.nav-item .nav-link {
  display: block;
  color: #c8c8c8;
  font-size: 15px;
  position: relative;
  white-space: nowrap;
}

.user-icon {
  width: 35px;
  height: 35px;
  display: inline-block;
  background-color: #b80100;
  border-radius: 50px;
}

// @media (max-width: 767px){
//   .crm-personal-profile {
//     text-align: right;
//     position: fixed;
// } .dropdown-menu>li>a {
//  z-index: 999;
// }
// }

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .nav-link {
    margin-left: 15px;
  }
}

/*============================SIDE BAR START============================*/
.sidebar-menu {
  position: fixed;
  z-index: 99;
  height: 100vh;
  display: inline-block;
  background-color: #292929;
  box-shadow: 9px 0px 16px 0 rgba(0, 0, 0, 0.2), 9px 0px 20px 0 rgba(0, 0, 0, 0.19);
  display: block;
  min-height: 100%;
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1);
  /*    transform: translate3d(-200px,0,0);*/
}

.sidebar-menu .navbar-nav .nav-link {
  padding: 15;
  position: relative;
  white-space: nowrap;
}

.sidebar-menu .navbar-nav .nav-link img {
  margin-bottom: 5px;
}

.sidebar-menu .nav-link:hover {
  background-color: #171717;
}

/*SMALL SIDE BAR****************/
.full-side-bar {
  width: 260px !important;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.small-side-bar {
  width: 50px;
  transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
}

.nav-link-name {
  position: relative;
  margin-left: 15px;
}

.nav-link-name {
  position: absolute;
  white-space: nowrap;
  transition: opacity 0.4s ease-out 0s;
  border-radius: 0.6rem;
  padding: 4px 10px;
  top: 9px;
  left: 50px;
}

.tax-active>.tax-show {
  display: inline-block !important;
  position: absolute;
  white-space: nowrap;
  transition: opacity 0.4s ease-out 0s;
  border-radius: 0.6rem;
  padding: 4px 10px;
  top: 9px;
  left: 50px;
  background-color: #171717;
}

.tax-active>.tax-show::after {
  content: "";
  position: absolute;
  top: 6px;
  left: -16px;
  width: 0;
  height: 0;
  border: 8px solid transparent;
  border-right: 15px solid #171717;
}

.name-hide {
  display: none;
}

.nbfc-block {
  margin-top: 8%;
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.sticky {
  position: fixed;
  top: 20%;
  width: 100%;
}

.nbfc-img {
  margin-top: 12px;
  height: 45px;
  margin-left: 415px;
}

.nbfc-bg {
  background: rgb(241, 243, 244);
}

.whats-app-nbfc {
  // background: green;
  color: green;
  width: 35px;
  margin: auto;
  text-align: center;
  border-radius: 50%;
  height: 35px;
  line-height: 36px;
}

.whats-app-approval {
  background: green;
  color: #fff;
  width: 20px;
  margin: auto;
  text-align: center;
  border-radius: 50%;
  height: 20px;
  position: relative;
  line-height: 20px;
}

.bell-nbfc {
  // background: $bluecolor;
  // transform: rotate(30deg);
  color: $bluecolor;
  width: 35px;
  margin: auto;
  text-align: center;
  border-radius: 50%;
  height: 35px;
  line-height: 36px;
}

.Appanalysis-table {
  min-width: 500px !important;
  display: block;
  margin: 0 auto;

  table {
    margin: 5px auto;
    font-size: 12.5px;
    border: solid 1px #ccc;
    background: #fff;
  }

  th {
    background: #e5e5e5;
    padding: 15px 10px;
    text-transform: uppercase;
  }

  td {
    padding: 10px;
  }

  tr {
    border-bottom: solid 1px #ccc;
  }

  tbody {
    td {
      &:first-child {
        background: #e5e5e5;
        padding: 15px 10px;
        text-transform: uppercase;
      }
    }
  }
}

.obj-doc {
  width: 100%;
  height: 350px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  50% {
    transform: rotate(180deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
    border-top-color: $teal;
  }

  50% {
    transform: rotate(180deg);
    border-top-color: $bluecolor;
  }

  100% {
    transform: rotate(360deg);
    border-top-color: $orangecolor;
  }
}

@mixin loaderDivMixin {
  border-radius: 50%;
  padding: 8px;
  border: 2px solid transparent;
  animation: rotate linear 3.5s infinite;
}

.loader {
  position: relative;
  margin: 75px auto;
  width: 120px;
  height: 120px;
  display: block;
  overflow: hidden;

  div {
    height: 100%;
  }
}

.loader4,
.loader4 div {
  @include loaderDivMixin;
  border-radius: 50%;
  padding: 4px;
  animation: rotate2 4s infinite linear;
}

.loader,
.loader * {
  will-change: transform;
}

.load {
  bottom: 0;
  left: 610px;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 450px;
  z-index: 99999;
}

.load-inner {
  bottom: 0;
  height: 60px;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.load-line-wrap {
  animation: spin 2000ms cubic-bezier(0.175, 0.885, 0.32, 1.275) infinite;
  box-sizing: border-box;
  height: 50px;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  transform-origin: 50% 100%;
  width: 100px;
}

.load-line {
  border: 4px solid transparent;
  border-radius: 100%;
  box-sizing: border-box;
  height: 100px;
  left: 0;
  margin: 0 auto;
  position: absolute;
  right: 0;
  top: 0;
  width: 100px;
}

.load-line-wrap:nth-child(1) {
  animation-delay: -50ms;
}

.load-line-wrap:nth-child(2) {
  animation-delay: -100ms;
}

.load-line-wrap:nth-child(3) {
  animation-delay: -150ms;
}

.load-line-wrap:nth-child(4) {
  animation-delay: -200ms;
}

.load-line-wrap:nth-child(5) {
  animation-delay: -250ms;
}

.load-line-wrap:nth-child(1) .load-line {
  border-color: hsl(0, 80%, 60%);
  height: 90px;
  width: 90px;
  top: 7px;
}

.load-line-wrap:nth-child(2) .load-line {
  border-color: hsl(60, 80%, 60%);
  height: 76px;
  width: 76px;
  top: 14px;
}

.load-line-wrap:nth-child(3) .load-line {
  border-color: hsl(120, 80%, 60%);
  height: 62px;
  width: 62px;
  top: 21px;
}

.load-line-wrap:nth-child(4) .load-line {
  border-color: hsl(180, 80%, 60%);
  height: 48px;
  width: 48px;
  top: 28px;
}

.load-line-wrap:nth-child(5) .load-line {
  border-color: hsl(240, 80%, 60%);
  height: 34px;
  width: 34px;
  top: 35px;
}

@keyframes spin {

  0%,
  15% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.register {
  background: -webkit-linear-gradient(left, #3931af, #00c6ff);
  margin-top: 3%;
  padding: 3%;
}

.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}

.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-20px);
  }
}

.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}

.register .register-form {
  padding: 10%;
  margin-top: 10%;
}

.btnRegister {
  float: right;
  margin-top: 10%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}

.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}

.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.register .nav-tabs .nav-link:hover {
  border: none;
}

.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}

.filter {
  color: #0c3953;
  font-size: 27px;
}

.view {
  margin: auto;
  width: 600px;
}

///////////////////////////ACTIVITY/////////////////////
.emp-activity {
  .phocket-table thead th {
    padding: 5px 8px !important;
  }

  .headcol {
    position: absolute;
    height: 2em;
    top: 60;
  }

  tbody {
    position: absolute;
    top: 90px;
  }
}

.app-comment-box {
  width: 50px;
  background-color: transparent;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 3px 6px;
  -webkit-transition: width 0.3s;
  /* For Safari 3.1 to 6.0 */
  transition: width 0.3s;

  &:focus {
    width: 200px;
    outline: none;
  }
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 100%;
  margin: 5px auto;
  border-radius: 7px;
  padding: 10px;
}

.card:hover {
  box-shadow: 0 8px 16px 0 $teal;
}

input {
  outline: none;
  border: none;
}

textarea {
  outline: none;
  border: none;
}

// textarea:focus, input:focus {
//   border-color: transparent !important;
// }

input:focus::-webkit-input-placeholder {
  color: transparent;
}

input:focus:-moz-placeholder {
  color: transparent;
}

input:focus::-moz-placeholder {
  color: transparent;
}

input:focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}

textarea:focus:-moz-placeholder {
  color: transparent;
}

textarea:focus::-moz-placeholder {
  color: transparent;
}

textarea:focus:-ms-input-placeholder {
  color: transparent;
}

input::-webkit-input-placeholder {
  color: #999999;
}

input:-moz-placeholder {
  color: #999999;
}

input::-moz-placeholder {
  color: #999999;
}

input:-ms-input-placeholder {
  color: #999999;
}

textarea::-webkit-input-placeholder {
  color: #999999;
}

textarea:-moz-placeholder {
  color: #999999;
}

textarea::-moz-placeholder {
  color: #999999;
}

textarea:-ms-input-placeholder {
  color: #999999;
}

/*---------------------------------------------*/
button {
  outline: none !important;
  border: none;
  background: transparent;
}

button:hover {
  cursor: pointer;
}

iframe {
  border: none !important;
}

/*//////////////////////////////////////////////////////////////////
[ Utility ]*/
.txt1 {
  font-size: 13px;
  line-height: 1.5;
  color: #999999;
}

.txt2 {
  font-size: 13px;
  line-height: 1.5;
  color: #666666;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-height: 99.9vh;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #9053c7;
  background: -webkit-linear-gradient(-135deg, #270d25, #41593a);
  background: -o-linear-gradient(-135deg, #270d25, #41593a);
  background: -moz-linear-gradient(-135deg, #270d25, #41593a);
  background: linear-gradient(-135deg, #270d25, #41593a);
}

.wrap-login100 {
  width: 960px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 27px 111px 28px 104px;
}

.login100-pic {
  margin-top: 135px;
  width: 316px;
}

.login100-pic img {
  max-width: 100%;
}

.login100-form {
  width: 290px;
}

.login100-form-title {
  font-size: 30px;
  color: #0041f3;
  line-height: 1.2;
  text-align: center;
  width: 100%;
  display: block;
}

/*---------------------------------------------*/
.wrap-input100 {
  position: relative;
  width: 100%;
  z-index: 1;
  margin-bottom: 15px;
}

.input100 {
  font-size: 15px;
  line-height: 1.5;
  color: #666666;

  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}

/*------------------------------------------------------------------
[ Focus ]*/
.focus-input100 {
  display: block;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 0px 0px;
  color: rgba(87, 184, 70, 0.8);
}

.input100:focus+.focus-input100 {
  -webkit-animation: anim-shadow 0.5s ease-in-out forwards;
  animation: anim-shadow 0.5s ease-in-out forwards;
}

@-webkit-keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

@keyframes anim-shadow {
  to {
    box-shadow: 0px 0px 70px 25px;
    opacity: 0;
  }
}

.symbol-input100 {
  font-size: 15px;
  display: flex;
  align-items: center;
  position: absolute;
  border-radius: 25px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-left: 35px;
  pointer-events: none;
  color: #666666;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.input100:focus+.focus-input100+.symbol-input100 {
  color: #57b846;
  padding-left: 28px;
}

/*------------------------------------------------------------------
[ Button ]*/
.container-login100-form-btn {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 20px;
}

.login100-form-btn {
  font-size: 15px;
  line-height: 1.5;
  color: #fff;
  text-transform: uppercase;

  width: 100%;
  height: 50px;
  border-radius: 25px;
  background: #57b846;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 25px;

  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -moz-transition: all 0.4s;
  transition: all 0.4s;
}

.login100-form-btn:hover {
  background: #333333;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 992px) {
  .wrap-login100 {
    padding: 65px 90px 33px 85px;
  }

  .login100-pic {
    width: 35%;
  }

  .login100-form {
    width: 60%;
  }
}

@media (max-width: 768px) {
  .icon-flipkart {
    text-align: center !important;
  }

  .wrap-login100 {
    padding: 100px 80px 33px 80px;
  }

  .login100-pic {
    display: none;
  }

  .login100-form {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .wrap-login100 {
    padding: 100px 15px 33px 15px;
  }
}

button.loan-product-button {
  background: #fff;
  padding: 5px 10px;
  display: block;
  margin: 0 auto;
  color: #0270bc;
  border-radius: 15px;
  border: 3px solid #0270bc;
  min-height: 160px;
  box-shadow: 5px 5px 30px 5px #ccc;
  margin-bottom: 15px;

  img {
    height: 70px;
    width: 70px;
    background-color: #fff;
    padding: 10px;
  }

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.rnc {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 291px;
  border-radius: 19px;
  padding: 10px;
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
}

.rnc-row {
  display: flex;
  align-items: stretch;
  margin: -21px 0px 4px 8px;
}

.rnc-canvas {
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 65px;
}

.rnc-column {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 0 0 10px;
}

.rnc-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  background: #fff;
  color: inherit;
  border: none;
  padding: 0;
  width: 25px;
  height: 20px;
  box-sizing: border-box;
  border-radius: 4px;

  &:focus {
    outline: none;
  }
}

.rnc-input {
  font-size: 15px;
  line-height: 1.5;
  color: #666666;
  display: block;
  width: 100%;
  background: #e6e6e6;
  height: 50px;
  border-radius: 25px;
  padding: 0 30px 0 68px;
}

.filter-btn-graph {
  color: #0bc9cd;
  font-size: 23px;
  margin-top: 10px;
}

.graph-filter-content {
  text-align: center;
  width: 450px;
  background: $whitecolor;
  position: absolute;
  left: 38%;
  height: auto;
  top: 10%;
  z-index: 999;
  box-shadow: 7px 20px 53px 2px #1f3f49;
  border-radius: 10px;
  border-color: black;
  padding: 3px 20px 8px 20px;

  .ryms-container {
    border: none !important;
    width: 100% !important;
  }

  .ryms-selected_cell {
    background: $bluecolor;
    font-style: normal;
  }

  .ryms-col_mp:hover {
    background: $bluecolor;
  }

  .ryms-selected_date_mp {
    color: $bluecolor;
    padding: 14px !important;
  }

  .ryms-col_mp {
    padding: 10px;
    border: solid 1px #ccc;
  }

  .filter-btn {
    background-color: $bluecolor;
    color: $whitecolor;
    padding: 15px 25px;
    border: none;
    cursor: pointer;
    border-radius: 10px;
    transition: 0.2s;
    margin: 10px 0;
  }

  .filter-btn:hover,
  .filter-btn:focus {
    border-radius: 0;
  }

  .filter-dropdown {
    position: relative;
    display: inline-block;
  }

  .filter-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    overflow: auto;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .filter-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    cursor: pointer;
  }

  .filter-dropdown a:hover {
    background-color: #ddd;
  }

  .filter-show {
    display: block;
  }
}

.graph_animateLeft {
  animation: animateLeft 0.5s !important;
  -webkit-animation: animateLeft 0.5s !important;
}

@keyframes animateLeft {
  from {
    left: -300px;
  }

  to {
    left: 0;
  }
}

@-webkit-keyframes animateLeft {
  from {
    left: -300px;
  }

  to {
    left: 0;
  }
}

.filter-input {
  color: #ccc;
  margin-top: 10px;
}

.sidebar1 {
  background: #046a81;
  padding: 0px;
  height: 598px;
  border-right: 1px solid black;
  overflow: auto;
}

.list-filter {
  color: #fff;
  list-style: none;
  padding-left: 0px;
  cursor: pointer;
}

.list-filter>li {
  padding: 27px 0px 6px 10px;
}

.list-filter>li:hover,
// .active {
//   background-color: $gradientBlue;
//   border-left: 5px solid black;
//   border-bottom: 1px solid black;
//   color: black;
//   font-weight: bolder;
// }

// .main-content{
//   margin-top: 61px;
// }
.graph-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: $fieldrow;

  .graph-loader-new {
    position: absolute;
    left: 46%;
    top: 30%;
  }
}

.graph-table {
  width: 100%;
  font-size: 14px;
  overflow-x: scroll;

  thead {
    background-color: #046a81;
    color: $whitecolor;

    th {
      padding: 10px 5px !important;
    }
  }

  th,
  td {
    border: 1px solid #eee;
    padding: 5px 8px;
    white-space: nowrap;
  }
}

.graph-btn {
  // background: #046a81;
  color: #046a81 !important;
  font-size: 32px !important;
  white-space: nowrap;
  // margin-top: -19px !important;
}

.filter-btn-bi {
  // background: #008c02;
  color: #008c02 !important;
  font-size: 26px !important;
  white-space: nowrap;
}

.popUp-close {
  color: red;
  float: right;
  font-size: 21px;
  font-weight: 700;
  line-height: 1;
}

.popUp-close:focus,
.popUp-close:hover {
  color: #000;
  text-decoration: none;
  cursor: pointer;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.unsubscribe-btn {
  padding: 5px !important;
  color: white !important;
  background: rgb(206, 54, 54) !important;
  border-radius: 3px !important;
}

.graph-discription {
  margin: 22px 0px -89px 213px;
  // font-style:italic;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}

.nav-side-menu {
  overflow: auto;
  font-family: verdana;
  font-size: 12px;
  font-weight: 200;
  // background-color: #2e353d;
  position: fixed;
  top: 0px;
  width: 300px;
  height: 100%;
  color: #e1ffff;
}

.nav-side-menu .brand {
  background-color: #23282e;
  line-height: 50px;
  display: block;
  text-align: center;
  font-size: 14px;
}

.nav-side-menu .toggle-btn {
  display: none;
}

.nav-side-menu ul,
.nav-side-menu li {
  list-style: none;
  padding: 0px;
  margin: 0px;
  line-height: 35px;
  cursor: pointer;

  .collapsed {
    .arrow:before {
      font-family: FontAwesome;
      content: "\f053";
      display: inline-block;
      padding-left: 10px;
      padding-right: 10px;
      vertical-align: middle;
      //  float:right;
    }
  }
}

.nav-side-menu ul :not(collapsed) .arrow:before,
.nav-side-menu li :not(collapsed) .arrow:before {
  font-family: FontAwesome;
  content: "\f078";
  display: inline-block;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
  // float: right;
}

.nav-side-menu ul .active,
.nav-side-menu li .active {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
}

.nav-side-menu ul .sub-menu li.active,
.nav-side-menu li .sub-menu li.active {
  color: #d19b3d;
}

.nav-side-menu ul .sub-menu li.active a,
.nav-side-menu li .sub-menu li.active a {
  color: #d19b3d;
}

.nav-side-menu ul .sub-menu li,
.nav-side-menu li .sub-menu li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}

.nav-side-menu ul .sub-menu li:hover,
.nav-side-menu li .sub-menu li:hover {
  background-color: #020203;
}

.nav-side-menu ul .sub-menu li:before,
.nav-side-menu li .sub-menu li:before {
  font-family: Fontawesome;
  content: "\f105";
  display: inline-block;
  padding-left: 20px;
  padding-right: 10px;
  vertical-align: middle;
}

.nav-side-menu li {
  padding-left: 0px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}

.nav-side-menu li a {
  text-decoration: none;
  color: #e1ffff;
}

.nav-side-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}

.nav-side-menu li:hover {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
  // -webkit-transition: all 1s ease;
  // -moz-transition: all 1s ease;
  // -o-transition: all 1s ease;
  // -ms-transition: all 1s ease;
  // transition: all 1s ease;
}

@media (max-width: 767px) {
  .hide-display {
    display: none !important;
  }

  .nav-side-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }

  .nav-side-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }

  .brand {
    text-align: left !important;
    font-size: 22px;
    padding-left: 20px;
    line-height: 50px !important;
  }
}

@media (min-width: 767px) {
  .nav-side-menu .menu-list .menu-content {
    display: block;
  }
}

body {
  margin: 0px;
  padding: 0px;
}

.nav-side-menu ul .sub-menu ul .sub-line li.active,
.nav-side-menu li .sub-menu li .sub-line li.active {
  color: #d19b3d;
}

.nav-side-menu ul .sub-menu li .sub-line li.active a,
.nav-side-menu li .sub-menu li .sub-line li.active a {
  color: #d19b3d;
}

.nav-side-menu ul .sub-menu li .sub-line li,
.nav-side-menu li .sub-menu li .sub-line li {
  background-color: #181c20;
  border: none;
  line-height: 28px;
  border-bottom: 1px solid #23282e;
  margin-left: 0px;
}

.nav-side-menu ul .sub-menu li .sub-line li:hover,
.nav-side-menu li .sub-menu li .sub-line li:hover {
  background-color: #020203;
}

.nav-side-menu ul .sub-menu li .sub-line li:before,
.nav-side-menu li .sub-menu li .sub-line li:before {
  font-family: FontAwesome;
  content: "\f105";
  display: inline-block;
  padding-left: 100px;
  padding-right: 10px;
  vertical-align: middle;
}

.nav-side-menu .sub-menu li {
  padding-left: 20px;
  border-left: 3px solid #2e353d;
  border-bottom: 1px solid #23282e;
}

.nav-side-menu .sub-menu li a {
  text-decoration: none;
  color: #e1ffff;
}

.sub-menu li a i {
  padding-left: 10px;
  width: 20px;
  padding-right: 20px;
}

.nav-side-menu li .sub-menu li:hover {
  border-left: 3px solid #d19b3d;
  background-color: #4f5b69;
  // -webkit-transition: all 1s ease;
  // -moz-transition: all 1s ease;
  // -o-transition: all 1s ease;
  // -ms-transition: all 1s ease;
  // transition: all 1s ease;
}

@media (max-width: 767px) {
  .nav-side-menu .sub-menu {
    position: relative;
    width: 100%;
    margin-bottom: 10px;
  }

  .nav-side-menu .sub-menu .toggle-btn {
    display: block;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10 !important;
    padding: 3px;
    background-color: #ffffff;
    color: #000;
    width: 40px;
    text-align: center;
  }

  .sub-line ul .sub-press li.active,
  .sub-line li .sub-press li.active {
    color: #d19b3d;
  }

  .sub-line ul .sub-press li.active a,
  .sub-line li .sub-press li.active a {
    color: #d19b3d;
  }

  .sub-line ul .sub-press li,
  .sub-line li .sub-press li {
    background-color: #181c20;
    border: none;
    line-height: 28px;
    border-bottom: 1px solid #23282e;
    margin-left: 0px;
  }

  .sub-line ul .sub-press li:hover,
  .sub-line li .sub-press li:hover {
    background-color: #020203;
  }

  .sub-line ul .sub-press li:before,
  .sub-line li .sub-press li:before {
    font-family: Arial;
    content: "\f105";
    display: inline-block;
    padding-left: 50px;
    padding-right: 10px;
    vertical-align: middle;
  }

  .sub-line li {
    padding-left: 20px;
    border-left: 3px solid #2e353d;
    border-bottom: 1px solid #23282e;
  }

  .sub-line li a {
    text-decoration: none;
    color: #e1ffff;
  }

  .sub-line li a i {
    padding-left: 50px;
    width: 20px;
    padding-right: 20px;
  }

  .sub-line li:hover {
    border-left: 3px solid #d19b3d;
    background-color: #4f5b69;
    // -webkit-transition: all 1s ease;
    // -moz-transition: all 1s ease;
    // -o-transition: all 1s ease;
    // -ms-transition: all 1s ease;
    // transition: all 1s ease;
  }

  @media (max-width: 767px) {
    .sub-line {
      position: relative;
      width: 100%;
      margin-bottom: 10px;
    }

    .sub-line .toggle-btn {
      display: block;
      cursor: pointer;
      position: absolute;
      right: 10px;
      top: 10px;
      z-index: 10 !important;
      padding: 3px;
      background-color: #ffffff;
      color: #000;
      width: 40px;
      text-align: center;
    }
  }
}

.side-nav-btn {
  text-align: left;
  color: white;
  width: 100%;
  padding: 10px;

  &:hover {
    background-color: black;
    color: #fff;
    font-weight: bolder;
    // padding-left: 30px;
    // background-color: $gradientBlue;
    // -webkit-transition: all 1s ease;
    // -moz-transition: all 1s ease;
    // -o-transition: all 1s ease;
    // -ms-transition: all 1s ease;
    // transition: all 1s ease;
  }

  &:focus {
    background-color: black;
    color: #fff;
    font-weight: bolder;
    // padding-left: 30px;
  }

  &:active {
    background-color: black;
    color: #fff;
    font-weight: bolder;
    // padding-left: 30px;
  }
}

.group {
  position: relative;
  margin-bottom: 45px;
  margin-top: 20px;
}

.input-event {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 300px;
  border: none;
  border: 1px solid #757575;
}

.input-event:focus {
  outline: none;
}

/* LABEL ======================================= */
.label-login {
  color: #999;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 20px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

/* active state */
.input-event:focus~label,
.input-event:valid~label {
  top: -20px;
  font-size: 14px;
  color: #5264ae;
}

/* BOTTOM BARS ================================= */
.bar {
  position: relative;
  display: block;
  width: 300px;
}

.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: #5264ae;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}

.bar:before {
  left: 50%;
}

.bar:after {
  right: 50%;
}

/* active state */
.input-event:focus~.bar:before,
.input-event:focus~.bar:after {
  width: 50%;
}

/* HIGHLIGHTER ================================== */
.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

/* active state */
.input-event:focus~.highlight {
  -webkit-animation: inputHighlighter 0.3s ease;
  -moz-animation: inputHighlighter 0.3s ease;
  animation: inputHighlighter 0.3s ease;
}

/* ANIMATIONS ================ */
@-webkit-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@-moz-keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

@keyframes inputHighlighter {
  from {
    background: #5264ae;
  }

  to {
    width: 0;
    background: transparent;
  }
}

.new {
  margin-left: 50% !important;
}

.profile-h {
  border-bottom: solid 5px #23a4cc;
}

.ph-box {
  border: solid 1px #e8e8e8;
  padding: 5px;
  width: 80%;
  background: #b5deea;
  text-transform: uppercase;
  margin: 10px auto;
}

.ph-sc {
  width: 30px;
  text-align: center;
  background: $bodycolor;
  float: right !important;
  font-weight: bold;
  height: 29px;
  padding: 5px;
  margin: -6px -5px 0 0;
}

li.active {
  // border-top: solid 2px blue;
  border-radius: 5px;
}

ul.tabs {
  // width: 390px;
  height: 80px;
  margin: 0 auto;
  list-style: none;
  overflow: hidden;
  padding: 0;
}

ul.tabs li {
  float: left;
  width: 33.33%;
}

ul.tabs li a {
  cursor: pointer;
  position: relative;
  display: block;
  line-height: 0.5;
  height: 30px;
  margin-top: 10px;
  padding: 10px 0 0 0;
  font-family: "Open Sans", sans-serif;
  font-size: 18px;
  text-align: center;
  text-decoration: none;
  color: #ffffff;
  background: #6edeef;
  -webkit-box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4);
  border: 0px solid #000000;
  -webkit-transition: padding 0.2s ease, margin 0.2s ease;
  -moz-transition: padding 0.2s ease, margin 0.2s ease;
  -o-transition: padding 0.2s ease, margin 0.2s ease;
  -ms-transition: padding 0.2s ease, margin 0.2s ease;
  transition: padding 0.2s ease, margin 0.2s ease;
}

.tabs li:first-child a {
  z-index: 3;
  -webkit-border-top-left-radius: 8px;
  -moz-border-radius-topleft: 8px;
  border-top-left-radius: 8px;
}

.tabs li:nth-child(2) a {
  z-index: 2;
}

.tabs li:last-child a {
  z-index: 1;
  -webkit-box-shadow: 2px 8px 25px -2px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 2px 8px 25px -2px rgba(0, 0, 0, 0.3);
  box-shadow: 2px 8px 25px -2px rgba(0, 0, 0, 0.3);
  -webkit-border-top-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  border-top-right-radius: 8px;
}

ul.tabs li a:hover {
  margin: 35px 0 0 0;
  padding: 10px 0 5px 0;
}

ul.tabs li a.active {
  margin: 30px 0 0 0;
  padding: 10px 0 10px 0;
  background: #545f60;
  color: #6edeef;
  /*color: #ff6831;*/
  z-index: 4;
  outline: none;
}

.group:before,
.group:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

.group:after {
  clear: both;
}

.config-pg {
  margin-top: 30px;
}

.config-notif {
  h5 {
    margin: 0 !important;
  }

  label {
    font-weight: normal;
  }
}

.count {
  line-height: 0.9;
  font-size: 18px;
  padding: 20px 6px;
  margin-top: 30px;
  margin-right: 40px;
  border: 3px solid green;
  border-radius: 50%;
}

.grp-btn {
  margin-top: 0 !important;
}

.similarBtn {
  background-color: $gradientBlue;
  color: black !important;
  border: 1px solid $gradientBlue;
  padding: 7px 14px;
  border-radius: 3px;
  white-space: nowrap;
}

.analysis-table {
  margin-top: 20px;
  background: white !important;
}

table.blueTable {
  border: 1px solid grey;
  /* background-color: #EEEEEE; */
  background: white;
  width: 80%;
  margin-top: 20px;
  margin-left: auto;
  color: white;
  margin-right: auto;
  text-align: center;
  border-collapse: collapse;
}

table.blueTable td,
table.blueTable th {
  // border: 1px solid #AAAAAA;
  // padding: 5px 2px;
  padding: 14px;
  color: black;
  font-size: 18px;
  // font-weight: bold;
}

table.blueTable tbody td {
  font-size: 13px;
  color: black;
}

// table.blueTable tr:nth-child(even) {
//   background: #e2e2e2;
// }
table.blueTable thead {
  background: #DBE9FF;
  // background: -moz-linear-gradient(top, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  // background: -webkit-linear-gradient(top, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  // background: linear-gradient(to bottom, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  border-bottom: 2px solid #808080;
}

table.blueTable thead th {
  font-size: 15px;
  font-weight: bold;
  color: black;
  // border-left: 2px solid #d3d3d3;
}

table.blueTable thead th:first-child {
  border-left: none;
}

table.blueTable tfoot {
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  background: #d0e4f5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  border-top: 2px solid #444444;
}

table.blueTable tfoot td {
  font-size: 11px;
  color: black;
}

table.blueTable tfoot .links {
  text-align: right;
}

table.blueTable tfoot .links a {
  display: inline-block;
  background: $bluecolor;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

.cluster-marker {
  // color: #fff;
  background: #1978c8;
  border-radius: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.crime-marker {
  background: none;
  border: none;
}

.crime-marker img {
  width: 25px;
}

.mapLocation {
  width: 80%;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
}

.tbl-count {
  // margin-top: 10px;
  font-weight: bold;
}

.pagination-btn {
  color: $bluecolor !important;
  margin-bottom: 10px;
  padding: 7px 20px;
  border-radius: 40px;
  margin-top: 20px;
  background: #dedede;
  white-space: nowrap;
  margin-right: 10px;
  margin-left: 10px;
}

.bank-detail-header {
  border: 2px solid grey;
  // border-radius: 35px;
  margin-top: 10px;
  margin-left: auto;
  width: 82%;
  margin-right: auto;
}

.debit {
  border-left: 5px solid $orangecolor !important;
  background: #fbdddd !important;
}

.difference {
  border-left: 5px solid $bluecolor !important;
  background: #add8e6 !important;
}

.bank-card {
  max-width: 261px;
  text-align: center;
  position: relative;
  min-height: 80px;
  width: 100%;
  background: #d7f4b2;
  box-shadow: 5px 5px 10px #ccc;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 30px auto 0 auto;
  border-left: 5px solid green;

  .heading {
    color: #999999;
    font-size: 20px;
    margin-top: 8px;
  }
}

.positive {
  border: 4px solid green !important;
}

.negative {
  border: 4px solid $orangecolor !important;
}

.regenerate-Crif {
  cursor: pointer;
  margin-top: 12px;
  margin-bottom: 12px;
  color: blue !important;
}

.font20px {
  font-size: 20px !important;
}

.header-analysis {
  display: flex;
  text-align: center;
  top: 0;
  background: black;
  padding: 0;
  position: fixed;
  width: 100%;
  z-index: 999;
}

.test1 {
  cursor: pointer;
  width: calc(100% / 7) !important;
  font-size: 13px;
  color: white !important;
  padding: 15px 5px;
  /* display: flex; */
  text-transform: uppercase;
  border-bottom: 1px solid;
  text-align: center;
  transition: 1s ease-in-out;

  &:first-child {
    border-left: none !important;
  }

  &:last-child {
    border-right: none !important;
  }

  &:hover {
    color: black !important;
    transition: 1s ease-in-out;
    background: whitesmoke;
  }
}

.active-analysis {
  color: black !important;
  background: whitesmoke;
  // border-bottom: 6px solid orangered;
}

.page-analysis {
  .bank-card {
    max-width: 261px;
    text-align: center;
    position: relative;
    min-height: 80px;
    width: 100%;
    background: #d7f4b2;
    color: black !important;
    box-shadow: 5px 5px 10px #ccc;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 30px auto 0 auto;
    border-left: 5px solid green;
  }

  text.recharts-text.recharts-cartesian-axis-tick-value {
    fill: #fff;
  }

  .tbl-count {
    color: #56d9fc !important;
    color: #56d9fc;
  }

  table.blueTable tbody td {
    color: white !important;
  }

  color: #56d9fc;
  background: black !important;

  .reportHead {
    color: #56d9fc !important;
  }

  .dataHead {
    color: #56d9fc !important;
  }

  .dataHeader1 {
    color: #56d9fc !important;
    background: black !important;
  }

  .dataHeader {
    color: #56d9fc !important;
    background: black !important;
  }

  .AccValue {
    color: white !important;
  }

  .subHeader1 {
    color: #56d9fc !important;
    background: black !important;
  }

  .box {
    background: black !important;
    color: #56d9fc !important;
    box-shadow: none !important;
  }

  .box1 {
    background: black !important;

    .tbody .td .tr {
      background: black !important;
    }
  }

  .subHeader {
    background: black !important;
  }

  .infoValue {
    background: black !important;
  }

  .dataValue {
    color: white !important;
    background: black !important;
  }

  .dataValue1 {
    color: white !important;
    background: black !important;
  }

  .bank-analysis-dashboard {
    color: #56d9fc !important;
    background-color: black;

    .bank-details-box {
      background-color: black !important;
    }

    .bank-analysis-table table {
      color: white;
      margin: 5px auto;
      font-size: 12.5px;
      // border: solid 1px purple;
      background: transparent;
      width: 82%;
      margin-right: auto;
      margin-left: auto;
    }

    .bank-analysis-table th {
      background: transparent !important;
    }

    .table-responsive {
      background: black !important;
    }
  }
}

.theme-btn {
  background-color: transparent !important;
  border: none !important;
  color: white !important;
}

.analysis-popUp {
  border: 1px solid black;
  background: white;
  padding: 20px;
  margin-top: 3px;
  text-align: center;
  margin-left: 86% !important;
  border-radius: 11px;
  width: 174px;
  position: fixed;
  z-index: 99;
}

.fa-class {
  margin-left: 24%;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

.page-analysis2 {
  .profile-save-btn {
    background: linear-gradient(45deg, black, transparent);
    color: white !important;
    margin-left: 79%;
    border: 1px solid gray !important;
  }

  .positive-box {
    background: #d7f4b2;
  }

  .negative-box {
    background: #fbdddd;
  }

  .heading-analysis {
    margin-top: 57px;
    margin-bottom: 23px;
    margin-left: 24px;
    font-weight: bold;
    text-decoration-line: underline;
    text-transform: uppercase;
  }

  background: #f1f1f1;

  .bank-analysis-table table {
    width: 82%;
    margin-right: auto;
    margin-left: auto;
  }

  .bank-analysis-table th {
    background: transparent !important;
  }
}

.positive-table {
  border: 5px solid green !important;
  color: green !important;
}

.negative-table {
  border: 5px solid red !important;
  color: red !important;
}

// .heading-analysis{
//   margin-top: 57px;
//   margin-left: 24px;
//   font-weight: bold;
//   text-decoration-line: underline;
//   text-transform: uppercase;
// }
.margin-left-25perc {
  margin-left: auto;
  margin-right: auto;
  width: 40%;
}

.reject-note {
  text-align: center;
  color: red;
  font-style: italic;
  font-weight: bold;
  text-transform: capitalize;
}

.credit-card {
  text-align: center !important;
  max-width: 245px;
  position: relative;
  min-height: 114px !important;
  padding: 5px;
  width: 100%;
  // box-shadow: 5px 5px 18px #ccc;
  margin: 30px auto 0 auto;
  background-image: linear-gradient(-270deg, #70e1f5, #eee);
}

.overall-score-analysis {
  background-image: linear-gradient(-270deg, #70e1f5, #eee);
  color: black !important;
}

.text-credit {
  color: gray;
}

.credit-new-score {
  font-size: 17px;
  text-align: center;
  margin-top: 15px;
}

.message-analysis-btn {
  padding: 7px !important;
  width: 108px !important;
  text-align: center !important;
}

.message-header {
  margin-right: 8px !important;
}

.list-group {
  display: inline-block;
}

.list-group-horizontal .list-group-item {
  display: inline-block !important;
  white-space: nowrap;
}

.list-group-horizontal .list-group-item {
  margin-bottom: 0;
  margin-left: -4px;
  margin-right: 0;
}

.list-group-horizontal .list-group-item:first-child {
  border-top-right-radius: 0;
  border-bottom-left-radius: 4px;
}

.list-group-horizontal .list-group-item:last-child {
  border-top-right-radius: 4px;
  border-bottom-left-radius: 0;
}

.active-msg {
  background: grey !important;
  color: white !important;
}

.underline {
  text-transform: uppercase;
  text-decoration-line: underline !important;
}

.exception-note {
  color: yellow;
  text-align: center;
  font-style: italic;
  font-weight: bold;
  text-transform: capitalize;
  text-decoration-line: underline;
}

.green-bg {
  background: #d7f3b1 !important;
}

.red-bg {
  background: #fbdddd !important;
}

.yellow-bg {
  background: #ffff9e !important;
}

.search-icon {
  width: 35px;
  margin-left: 77px;
  margin-top: 17px;
}

.search-fil {
  margin-top: 17px !important;
  width: 141px;
  float: right;
}

.transaction-msg {
  .rt-thead.-header {
    padding: 10px;
    border-bottom: 2px solid gray;
    background: #e2e2e2;
  }
}

.rt-td {
  white-space: normal !important;
}

.-pagination {

  .-previous,
  .-next {
    button.-btn {
      background: darkgray;
      color: $whitecolor;
    }

    .-btn:not([disabled]):hover {
      background: $teal;
      color: $whitecolor;
    }
  }
}

.overlay-error {
  position: fixed;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.popup-error {
  margin-top: 6% !important;
  padding: 20px;
  background: #fff;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  width: 33%;
  background: #ffcccb;
  transition: all 5s ease-in-out;
}

.success-popup {
  margin-top: 6% !important;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  width: 33%;
  background: #c3fcca;
  transition: all 5s ease-in-out;

  .error-btn {
    padding: 6px;
    width: 78px;
    background: white;
    border-radius: 5px;
    margin-top: 3px;

    &:hover {
      background: black;
      color: white;
    }
  }
}

.error-btn {
  padding: 6px;
  width: 78px;
  background: white;
  border-radius: 5px;
  margin-top: 3px;

  &:hover {
    background: #ff0000;
    color: white;
  }
}

.popup-error h2 {
  margin-top: 0;
  color: #333;
}

.popup-error .close {
  position: absolute;
  transition: all 200ms;
  font-size: 30px;
  font-weight: bold;
  text-decoration: none;
  color: #333;
}

.popup-error .close:hover {
  color: #06d85f;
}

.popup-error .content {
  max-height: 30%;
}

.react-csv-input {
  .csv-input {
    &:active {
      border: none;
      outline: none;
    }

    &:focus {
      border: none;
      outline: none;
    }
  }
}

.convertPaid {
  .profile-save-btn {
    border-radius: initial !important;
  }

  input[type="file" i]::-webkit-file-upload-button {
    background-image: linear-gradient(#fff, #87cefa) !important;
    color: black !important;
    border: none !important;
    padding: 8px 20px !important;
    white-space: nowrap;
  }

  .react-csv-input {
    border: solid 1px lightskyblue !important;
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked+.slider {
  background-color: #2196f3;
}

input:focus+.slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.income {
  .income-table {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;

    table,
    th,
    td {
      border: 1px solid black;
      border-collapse: collapse;
    }

    th,
    td {
      padding: 5px;
      text-align: left;
    }

    th {
      background: #b9d8f9;
      color: #000;
    }
  }

  .income-table-new {
    margin-top: 20px;
    width: 100%;
    border-radius: 10px;

    table,
    th,
    td {
      border-collapse: collapse;
    }

    th,
    td {
      padding: 5px;
      text-align: left;
    }

    th {
      background: #b9d8f9;
      color: #000;
    }
  }
}

.ambassador-login-capcha {
  .rnc {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 255px;
    background-color: #eee;
    border-radius: 6px;
    padding: 10px;
    box-sizing: border-box;
  }

  .rnc-row {
    display: flex;
    align-items: stretch;
    margin: 0 0 10px 0;
  }

  .rnc-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 0 0 10px;
  }

  .rnc-canvas {
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 4px;
  }

  .rnc-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: #fff;
    color: inherit;
    border: none;
    padding: 0;
    width: 35px !important;
    height: 20px;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .rnc-button svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
  }

  .rnc-input {
    border: none;
    padding: 10px 6px;
    font-size: inherit;
    font-family: inherit;
  }
}

.google-btn {
  cursor: pointer;
  width: 278;
  height: 42px;
  // background-color: $google-blue;
  border-radius: 2px;
  box-shadow: 0 3px 19px 0 rgba(0, 0, 0, 0.25);

  .google-icon-wrapper {
    position: absolute;
    margin-top: 1px;
    margin-left: 1px;
    width: 40px;
    height: 40px;
    border-radius: 2px;
    background-color: $whitecolor;
  }

  .google-icon {
    position: absolute;
    margin-top: 11px;
    margin-left: 30px;
    width: 20px;
    height: 20px;
  }

  .btn-text {
    text-align: center;
    line-height: 2.9;
    margin: 11px 11px 0 0;
    color: $orangecolor;
    font-size: 14px;
    margin-left: 28px;

    letter-spacing: 0.2px;
  }

  &:hover {
    box-shadow: 0 0 6px $google-blue;
  }

  &:active {
    background: $button-active-blue;
  }
}

.captcha-new {
  text-align: center;
  font-size: 25px;
  margin-bottom: 10px;
  margin-left: 29%;
  width: 45%;
  border-radius: 26px;
  border: 1px solid green;
  text-decoration: line-through;
}

.captcha-btn {
  margin-top: -40px;
  padding: 5px;
  float: right;
  margin-right: 41px;
}

.step-bar {
  .barpro {
    margin-top: 30px;
    counter-reset: step;
  }

  .barpro li {
    list-style-type: none;
    width: 15%;
    float: left;
    font-size: 12px;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    color: #7d7d7d;
  }

  .barpro li:before {
    width: 30px;
    height: 30px;
    content: counter(step);
    counter-increment: step;
    line-height: 30px;
    border: 2px solid #7d7d7d;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 50%;
    background-color: white;
  }

  .barpro li:after {
    width: 100%;
    height: 2px;
    content: "";
    position: absolute;
    background-color: #7d7d7d;
    top: 15px;
    left: -50%;
    z-index: -1;
  }

  .barpro li:first-child:after {
    content: none;
  }

  .barpro li.active {
    color: #006fbc;
  }

  .barpro li.active:before {
    border-color: #006fbc;
    background-color: #006fbc;
  }

  .barpro li.active+li:after {
    background-color: #006fbc;
  }
}

///////////////////new user////////
.user-input {
  background: transparent;
  width: 100%;
  padding: 5px;
  border: none;
  border-bottom: 2px solid grey;

  &:focus {
    outline: none;
    border: none;
    border-bottom: 2px solid #2884da;
  }
}

.label-basic {
  label {
    color: #737373;
    // margin-top: 20px;
  }

  .margin-top-30 {
    margin-top: 30px;
  }
}

.box-upload {
  width: 94px;
  height: 82px;
  left: 726px;
  top: 419px;

  background: #edf2f7;
  border-radius: 10px 0px 0px 10px;
}

.box-upload-main {
  width: 431px;
  height: 82px;
  left: 726px;
  top: 419px;

  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.upload-bg {
  width: 100%;
  height: 82px;
  left: 726px;
  top: 419px;

  background: #ffffff;
  border: 1px solid #eaeaea;
  box-sizing: border-box;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
}

.file-upload {
  background: url(./images/uploads1.png) center no-repeat;

  cursor: pointer;
  width: 94px;
  height: 82px;
  left: 726px;
  top: 419px;
  background-color: #edf2f7;
  border-radius: 10px 0px 0px 10px;
}

.document-upload-user {
  margin-left: 118px;
  margin-top: 32px;
  max-width: 241px;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  text-overflow: ellipsis;
}

@media only screen and (max-width: 1082px) {
  .document-upload-user {
    width: 120px;
  }
}

@media only screen and (max-width: 540px) {
  .document-upload-user {
    width: 120px;
  }
}

@media only screen and(min-width:400px) and (max-width: 768px) {
  .document-upload-user {
    width: 150px;
  }

  .product-box-gift-form {
    display: none;
  }
}

@media only screen and(min-width:768px) and (max-width: 882px) {
  .document-upload-user {
    width: 92px;
  }
}

@media only screen and (max-device-width: 480px) {
  .gift-disc {
    p {
      font-size: 9px !important;
    }
  }
}

@media only screen and(min-width:500px) and (max-width: 767px) {
  .document-upload-user {
    width: 200px;
  }
}

.product-box {
  width: 100%;
  height: 136px;
  left: 726px;
  box-shadow: 0px 1.68732px 4.68732px 3px rgba(0, 0, 0, 0.25);
  border-radius: 11.7183px;
  top: 291px;
  background-color: #ffffff;
  border-radius: 5px;
  background: url(./images/vectorpaint1.png) bottom no-repeat;
}

.gift-disc {
  padding: 10px;

  p {
    margin-left: 20px;
    font-size: 12px;
    color: grey;
    font-weight: 600;
  }
}

.loan-box {
  width: 100%;
  height: 136px;
  left: 726px;
  box-shadow: 0px 1.68732px 4.68732px 3px rgba(0, 0, 0, 0.25);
  border-radius: 11.7183px;
  top: 291px;
  background-color: #ffffff;
  border-radius: 5px;
  background: url(./images/vectorpaintLoan.png) bottom no-repeat;
}

.user-table {
  overflow: auto;
  border: 1px solid grey;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.emi-table-user {
  width: 100%;
  color: $bluecolor;

  th {
    padding: 10px;
  }

  td {
    padding: 6px 10px;
  }

  th,
  td {
    text-align: center;
    color: #606060;
  }
}

.product-box-gift {
  width: 100%;
  height: 117px;

  box-shadow: 0px 1.68732px 4.68732px 3px rgba(0, 0, 0, 0.25);
  border-radius: 11.7183px;
  background-color: #ffffff;
  border-radius: 5px;
  background: url(./images/vectorpaint1.png) bottom no-repeat;
  margin-top: 25px;
}

.profile-details-box-new {
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  p {
    padding: 1px 10px;
  }
}

.new-basic-head {
  font-size: 17px;
  background: #0d3953;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  font-weight: 600;
  padding: 3px 6px;
  margin-bottom: 4px;
  color: #fff;
}

@keyframes slideInLeft {
  0% {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
  }
}

.slideInLeft {
  -webkit-animation-name: slideInLeft;
  animation-name: slideInLeft;
}

.product-box-gift-form {
  width: 100%;
  height: 100px;
  box-shadow: 0px 1.68732px 4.68732px 3px rgba(0, 0, 0, 0.25);
  border-radius: 11.7183px;
  background-color: #ffffff;
  border-radius: 5px;
  background: url(./images/vectorpaint1.png) bottom no-repeat;
  margin-top: 23px;

  .gift-disc {
    padding: 2px;
  }
}

.amount-btn {
  width: 100%;
  padding: 8px;
  color: #3e3e3e;
  background: #ffffff;
  box-shadow: 0px 4.85714px 24.2857px rgba(0, 0, 0, 0.1);
  border-radius: 6.07143px;
  //   &:active{
  //     color:#fff;
  //     background: linear-gradient(96deg, #5F9DF9 -17.24%, #3078BB 116.89%);
  // box-shadow: 0px 4.85714px 24.2857px rgba(0, 0, 0, 0.1);
  // border-radius: 6.07143px;
  //   }
  //   &:focus{
  //     color:#fff;
  //     background: linear-gradient(96deg, #5F9DF9 -17.24%, #3078BB 116.89%);
  // box-shadow: 0px 4.85714px 24.2857px rgba(0, 0, 0, 0.1);
  // border-radius: 6.07143px;
  //   }
}

.intallment-box {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 12px;
  color: #5c5c5c;
  margin-bottom: 50px;
}

.gift-amt-active {
  width: 100%;
  padding: 8px;
  box-shadow: 0px 4.85714px 24.2857px rgba(0, 0, 0, 0.1);
  border-radius: 6.07143px;
  color: #fff;
  background: linear-gradient(96deg, #5f9df9 -17.24%, #3078bb 116.89%);
  box-shadow: 0px 4.85714px 24.2857px rgba(0, 0, 0, 0.1);
  border-radius: 6.07143px;
}

.vl {
  border-right: 3px solid #cccccc;
  height: 300px;
  margin-top: 100px;
}

.google {
  background-color: #dd4b39;
  color: white;
  padding: 12px 23px !important;

  &:hover {
    opacity: 0.5;
    color: #fff !important;
  }
}

.google-btn-new {
  width: 400px !important;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 17px;
  line-height: 20px;
  text-decoration: none;
}

.fb {
  background-color: #3f5e95;
  color: white;

  &:hover {
    opacity: 0.5;
    color: #fff !important;
  }
}

.card-forms {
  background: #ffffff;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  height: 151px;
  margin-top: 46px;
  padding: 15px;
  margin-bottom: 2px;
}

.gift-section {
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 13px 10px;
  margin-top: 49px;
  color: #000;
  font-weight: 600;
  cursor: pointer;
}

.new-gift-card-apply {
  background: #2884da;
  box-shadow: 0px 4.88889px 36.6667px rgba(0, 0, 0, 0.1);
  border-radius: 6.11111px;
  padding: 60px;
  // margin-left: 15px;
  color: #fff;
  cursor: pointer;

  // width: 80%;
  &:hover {
    -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  }
}

.flipkart-active-card {
  width: 75%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  height: 80px;
  box-shadow: 0px 1.68732px 4.68732px 3px rgba(0, 0, 0, 0.25);
  border-radius: 11.7183px;
  // background-color: #ffffff;
  border-radius: 5px;
  background: url(./images/vectorpaint.png) bottom no-repeat;

  &:hover {
    -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  }
}

.amazon-active-card {
  width: 75%;
  padding: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  height: 80px;
  box-shadow: 0px 1.68732px 4.68732px 3px rgba(0, 0, 0, 0.25);
  border-radius: 11.7183px;
  // background-color: #ffffff;
  border-radius: 5px;
  background: url(./images/vectorpaint1.png) bottom no-repeat;

  &:hover {
    -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  }
}

.flipkart-active-card-report {
  width: 75%;
  height: 150px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.installment-card {
  width: 75%;
  height: 140px;
  padding: 15px;
  margin-left: auto;
  margin-right: auto;
  background: #ffffff;
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  &:hover {
    -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  }
}

.payment-page {
  width: 75%;
  margin-left: auto;
  margin-right: auto;
  color: #606060;
}

.black-filter {
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.hover-shadow {
  &:hover {
    -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  }
}

@-webkit-keyframes blinker {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.blinknew {
  text-transform: uppercase;
  text-decoration: blink;
  font-weight: 600;
  -webkit-animation-name: blinker;
  -webkit-animation-duration: 0.6s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-direction: alternate;
}

.width-80 {
  width: "80%" !important;
}

.center-part-alert {
  background-color: aliceblue;
  border-radius: 5px;
  padding: 20px;
  text-align: center;
  margin-bottom: 11.5%;
  margin-top: 15%;
}

table.blueTablecontact {
  border: 1px solid grey;
  /* background-color: #EEEEEE; */
  background: white;
  width: 80%;
  margin-top: 20px;
  margin-left: auto;
  color: white;
  margin-right: auto;
  text-align: center;
  border-collapse: collapse;
}

table.blueTablecontact thead {
  background: #DBE9FF;
  // background: -moz-linear-gradient(top, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  // background: -webkit-linear-gradient(top, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  // background: linear-gradient(to bottom, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  border-bottom: 2px solid #808080;
}

table.blueTablecontact thead th {
  font-size: 15px;
  font-weight: bold;
  color: black;
  padding: 15px;
  // border-left: 2px solid #d3d3d3;
}

.text-right-imp {
  text-align: right !important;
}

.border-grey {
  border: 1px solid grey !important;
}

.payment-btn {
  background: green;
  color: white;
  margin-bottom: 10px;
  padding: 7px 20px;
  border-radius: 3px;
  margin-top: 20px;
  white-space: nowrap;

  &:hover {
    background: black;
    color: white;
  }
}

.consent {
  padding: 24px !important;
  border-radius: 5px !important;
}

table.reference-table {
  background: white;
  width: 100%;
  margin-top: -11px;
  color: white;
}

table.reference-table td,
table.reference-table th {
  // border: 1px solid #AAAAAA;
  // padding: 5px 2px;
  padding: 8px;
  color: black;
  font-size: 18px;
  // font-weight: bold;
}

table.reference-table tbody td {
  font-size: 13px;
  color: black;
}

// table.reference-table tr:nth-child(even) {
//   background: #e2e2e2;
// }
table.reference-table thead {
  background: #e2e2e2;
  // background: -moz-linear-gradient(top, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  // background: -webkit-linear-gradient(top, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  // background: linear-gradient(to bottom, #5592bb 0%, $bluecolor 66%, $bluecolor 100%);
  border-bottom: 2px solid #808080;
}

table.reference-table thead th {
  font-size: 15px;
  font-weight: bold;
  color: black;
  // border-left: 2px solid #d3d3d3;
}

table.reference-table thead th:first-child {
  border-left: none;
}

table.reference-table tfoot {
  font-size: 11px;
  font-weight: bold;
  color: #ffffff;
  background: #d0e4f5;
  background: -moz-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: -webkit-linear-gradient(top, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  background: linear-gradient(to bottom, #dcebf7 0%, #d4e6f6 66%, #d0e4f5 100%);
  border-top: 2px solid #444444;
}

table.reference-table tfoot td {
  font-size: 11px;
  color: black;
}

table.reference-table tfoot .links {
  text-align: right;
}

table.reference-table tfoot .links a {
  display: inline-block;
  background: $bluecolor;
  color: #ffffff;
  padding: 2px 8px;
  border-radius: 5px;
}

@-o-keyframes highlightBg {
  0% {
    background: #fff;
  }

  50% {
    background: #38fc59;
  }

  to {
    background: #fff;
  }
}

@-webkit-keyframes highlightBg {
  0% {
    background: #fff;
  }

  50% {
    background: #79d462;
  }

  to {
    background: #fff;
  }
}

@keyframes highlightBg {
  0% {
    background: #fff;
  }

  50% {
    background: #a9c574;
  }

  to {
    background: #fff;
  }
}

.highlight-new {
  /*     box-shadow: 0 0 0 2px #FF4081; */
  width: fit-content;
  text-align: center;
  padding: 10px;
  margin-top: 20px;
  border-radius: 5px;
  -webkit-animation: highlightBg 1s linear;
  -o-animation: highlightBg 1s linear;
  animation: highlightBg 1s linear;
  -webkit-animation-iteration-count: infinite;
  -o-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}

.tool-dot {
  top: 70px;
  position: fixed;
  margin-left: -2%;
  z-index: 1000;
}

.circle-wrapper {
  position: relative;
  width: 50px;
  height: 50px;
  float: left;
  margin: 10px;
}

.icon {
  position: absolute;
  color: #fff;
  font-size: 24px;
  top: -2px;
  left: 2px;
  padding: 2px;
}

.circle {
  cursor: pointer;

  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  padding: 2.5px;
  background-clip: content-box;
  animation: spin 10s linear infinite;
}

.circle-wrapper:active .circle {
  animation: spin 2s linear infinite;
}

.success {
  // background-color: rgb(139, 126, 126);
  // border: 2.5px dashed #337ab7;
}

@keyframes spin {
  100% {
    transform: rotateZ(360deg);
  }
}

.page-wrapper {
  cursor: pointer;
  top: 39%;
  padding: 3px;
  height: 29px;
  width: 27px;
  height: 46;
  right: -7px;
  margin-right: 29px;
  position: fixed;
  background: #f2483a;
  z-index: 999;
  justify-content: center;
}

.button-popup {
  z-index: 1001;
  padding: 5px 6px 8px 6px;
  // border-radius: 6px;
  color: black;
  background-color: white;
  margin-top: 21%;
  float: right;
  margin-right: 14px;

  button {
    background-color: $bluecolor !important;
    margin-top: 15px !important;
  }
}

.str {
  position: absolute;
  margin-top: 106px;
  margin-left: 93%;
  margin-right: -19pxauto;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #146f79;
  transform: rotate(-139deg);
}

.badabazar-tab-active {
  cursor: pointer;
  border-bottom: 5px solid blue;
  padding: 10px;
  // border-right: 3px solid blue;
  background: aliceblue;
  font-size: 13px;
}

.badabazar-tab-inactive {
  color: #fff;
  cursor: pointer;
  font-size: 13px;
  // border-bottom: 3px solid rgb(133, 138, 138);
  padding: 10px;
  // border-right: 3px solid blue;
  background: rgb(162, 164, 165);
}

.i-button {
  background: #0d3953;
  font-size: 15px;
  border-radius: 5px;
  color: #fff;
}

.payment-fields {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.div-bottom {
  border-bottom: 6px solid $bluecolor;
  width: 20%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
}

// .file-upload {
//   background-color: #ffffff;
//   width: 600px;
//   margin: 0 auto;
//   padding: 20px;
// }

.file-upload-btn {
  width: 100%;
  margin: 0;
  color: #fff;
  background: #1fb264;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 4px solid $bluecolor;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.file-upload-btn:hover {
  background: #1aa059;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.file-upload-btn:active {
  border: 0;
  transition: all 0.2s ease;
}

.file-upload-content {
  display: none;
  text-align: center;
}

.file-upload-input {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer;
}

.image-upload-wrap {
  margin-top: 20px;
  border: 4px dashed #006fbc;
  background-size: 29%;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;

  background-image: url("./images/Upload icon.png");
}

.image-dropping,
.image-upload-wrap:hover {
  background-image: url("./images/Upload icon.png");
  border: 4px dashed green;
  color: #000;
}

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text {
  text-align: center;
}

.drag-text h5 {
  font-weight: 100;
  text-transform: uppercase;
  color: grey;
  padding: 60px 0;
}

.file-upload-image {
  max-height: 200px;
  max-width: 200px;
  margin: auto;
  padding: 20px;
}

.remove-image {
  width: 200px;
  margin: 0;
  color: #fff;
  background: #cd4535;
  border: none;
  padding: 10px;
  border-radius: 4px;
  border-bottom: 2px solid #b02818;
  transition: all 0.2s ease;
  outline: none;
  text-transform: uppercase;
  font-weight: 700;
}

.remove-image:hover {
  background: #c13b2a;
  color: #ffffff;
  transition: all 0.2s ease;
  cursor: pointer;
}

.remove-image:active {
  border: 0;
  transition: all 0.2s ease;
}

.config-box {
  // box-shadow: 6px 6px 60px rgb(0 0 0 / 10%);
  border-radius: 10px;
  padding: 20px;
  margin-top: 65px;
}

.card-profile {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  margin-top: 30px;
  padding: 20px;
  margin-left: 10px;
  min-height: 452px;
  margin-right: 10px;
  margin-bottom: 30px;
}

#material-tabs {
  cursor: pointer;
  position: relative;
  display: block;
  padding: 0;
  border-bottom: 1px solid #e0e0e0;
}

#material-tabs>a {
  margin-top: 20px;
  position: relative;
  display: inline-block;
  text-decoration: none;
  padding: 9px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  color: #424f5a;
  text-align: center;
  outline: none;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.card-tab-active {
  color: #000 !important;
  background-color: #e3f8ff;
  border-bottom: 5px solid $teal;
}

.back-card-btn {
  margin-top: 25px;
  font-size: 40px;
  // position: absolute;
  margin-left: 20px;
  // z-index: 1;
  cursor: pointer;
}

.card-card {
  width: 365px;
  height: 200px;
  -webkit-perspective: 600px;
  -moz-perspective: 600px;
  perspective: 600px;
}

// .card__part {
//   // box-shadow: 1px 1px #aaa3a3;
//   top: 0;
//   position: absolute;
//   z-index: 1000;
//   left: 0;
//   display: inline-block;
//   width: 365px;
//   height: 200px;
//   background-image: url("https://image.ibb.co/bVnMrc/g3095.png"),
//     linear-gradient(to right bottom, #f4f4f5, #fcfcfc, #e1e1f5, #6ca3ec, #ec4879); /*linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)*/
//   background-repeat: no-repeat;
//   background-position: center;
//   background-size: cover;
//   border-radius: 8px;

//   -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
//   -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
//   -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
//   -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
//   transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
//   -webkit-transform-style: preserve-3d;
//   -moz-transform-style: preserve-3d;
//   -webkit-backface-visibility: hidden;
//   -moz-backface-visibility: hidden;
// }

// .card__front {
//   padding: 18px;
//   -webkit-transform: rotateY(0);
//   -moz-transform: rotateY(0);
// }

// .card__back {
//   padding: 18px 0;
//   -webkit-transform: rotateY(-180deg);
//   -moz-transform: rotateY(-180deg);
// }

// .card__black-line {
//   margin-top: 5px;
//   height: 38px;
//   background-color: #303030;
// }

// .card__logo {
//   height: 16px;
// }

// .card__front-logo {
//   position: absolute;
//   top: 18px;
//   right: 18px;
// }
// .card__square {
//   border-radius: 5px;
//   height: 30px;
//   width: 100px;
// }

// .card_numer {
//   display: block;
//   width: 100%;
//   word-spacing: 4px;
//   font-size: 20px;
//   letter-spacing: 2px;
//   color: #000;
//   text-align: center;
//   margin-bottom: 20px;
//   margin-top: 20px;
// }

// .card__space-75 {
//   width: 75%;
//   float: left;
// }

// .card__space-25 {
//   width: 25%;
//   float: left;
// }

// .card__label {
//   font-size: 10px;
//   text-transform: uppercase;
//   color: #000;
//   letter-spacing: 1px;
// }

// .card__info {
//   margin-bottom: 0;
//   margin-top: 5px;
//   font-size: 16px;
//   line-height: 18px;
//   color: #000;
//   letter-spacing: 1px;
//   text-transform: uppercase;
//   font-weight: 700;
//   // text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
//   // opacity: 0.4;
// }

// .card__back-content {
//   padding: 15px 15px 0;
// }
// .card__secret--last {
//   color: #303030;
//   text-align: right;
//   margin: 0;
//   font-size: 14px;
// }

// .card__secret {
//   padding: 5px 12px;
//   background-color: #fff;
//   position: relative;
// }

// .card__secret:before {
//   content: "";
//   position: absolute;
//   top: -3px;
//   left: -3px;
//   height: calc(100% + 6px);
//   width: calc(100% - 42px);
//   border-radius: 4px;
//   background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 10px);
// }

// .card__back-logo {
//   position: absolute;
//   bottom: 15px;
//   right: 15px;
// }

// .card__back-square {
//   position: absolute;
//   bottom: 15px;
//   left: 15px;
// }

// .card-card:hover .card__front {
//   -webkit-transform: rotateY(180deg);
//   -moz-transform: rotateY(180deg);
// }

// .card-card:hover .card__back {
//   -webkit-transform: rotateY(0deg);
//   -moz-transform: rotateY(0deg);
// }

.card__part {
  // box-shadow: 1px 1px #aaa3a3;
  top: 0;
  position: absolute;
  z-index: 1000;
  left: 0;
  display: inline-block;
  width: 418px;
  height: 249px;
  // background-image: url("./images/Rectangle.png");
  background-color: #101820;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  border-radius: 8px;

  -webkit-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -moz-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -ms-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -o-transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}

.card__front {
  padding: 18px;
  -webkit-transform: rotateY(0);
  -moz-transform: rotateY(0);
}

.card__back {
  padding: 18px 0;
  -webkit-transform: rotateY(-180deg);
  -moz-transform: rotateY(-180deg);
}

.card__black-line {
  margin-top: 5px;
  height: 43px;
  background-color: #000;
}

.card__logo {
  height: 16px;
}

.card__front-logo {
  position: absolute;
  top: 18px;
  right: 18px;
}

.card__square {
  border-radius: 5px;
  // height: 30px;
  // width: 100px;
}

.card_numer {
  display: block;
  width: 100%;
  word-spacing: 4px;
  font-size: 20px;
  letter-spacing: 2px;
  color: #000;
  text-align: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.card__space-75 {
  width: 75%;
  float: left;
}

.card__space-25 {
  width: 25%;
  float: left;
}

.card__label {
  font-size: 10px;
  text-transform: uppercase;
  color: #000;
  letter-spacing: 1px;
}

.card__info {
  margin-bottom: 0;
  margin-top: 5px;
  font-size: 16px;
  line-height: 18px;
  color: #fff;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 700;
  // text-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  // opacity: 0.4;
}

.card__back-content {
  // padding: 15px 15px 0;
  width: 252px;
  margin-top: 4px;
  margin-left: auto;
  margin-right: auto;
}

.card__secret--last {
  color: #303030;
  text-align: right;
  margin: 0;
  font-size: 14px;
}

.card__secret {
  padding: 5px 12px;
  background-color: #fff;
  position: relative;
}

.card__secret:before {
  content: "";
  position: absolute;
  top: -3px;
  left: -3px;
  height: calc(100% + 6px);
  width: calc(100% - 42px);
  border-radius: 4px;
  background: repeating-linear-gradient(45deg, #ededed, #ededed 5px, #f9f9f9 5px, #f9f9f9 290px);
}

.card__back-logo {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.card__back-square {
  position: absolute;
  bottom: 15px;
  left: 15px;
}

.card-card:hover .card__front {
  -webkit-transform: rotateY(180deg);
  -moz-transform: rotateY(180deg);
}

.card-card:hover .card__back {
  -webkit-transform: rotateY(0deg);
  -moz-transform: rotateY(0deg);
}

.tabs-new {
  background: lightgrey;
  text-align: center;
  padding: 10px;
  font-weight: 700;
  cursor: pointer;

  // color:#fff
  &:hover {
    background: $bluecolor !important;
    color: #fff !important;
  }
}

.tabs-new-active {
  background: $bluecolor !important;
  color: #fff !important;
  padding: 10px;
  text-align: center;

  font-weight: 700;
  cursor: pointer;
}

.product-box-tea {
  background: #fff;
  box-shadow: 0px 4.88889px 36.6667px rgba(0, 0, 0, 0.1);
  border-radius: 6.11111px;
  padding: 60px;
  // margin-left: 15px;
  color: #000;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  // width: 80%;
  margin-top: 50px;

  &:hover {
    color: #fff;
    background: #1061b3;
    -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  }

  &:active {
    color: #fff;

    background: #1061b3;
    -webkit-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
    box-shadow: 1px 0px 5px 2px rgba(0, 0, 0, 0.75);
  }
}

.product-box-tea-active {
  background: #1061b3;
  box-shadow: 0px 4.88889px 36.6667px rgba(0, 0, 0, 0.1);
  border-radius: 6.11111px;
  padding: 60px;
  // margin-left: 15px;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  font-size: 18px;
  // width: 80%;
  margin-top: 50px;
}

.list-name {
  list-style: none;
  display: flex;
}

.text-india {
  transform: rotate(180deg);
  font-size: 10px;
  margin-top: 61px;
  margin-right: 28px;
  color: #fff;
}

.error-pan-popup {
  margin-top: 6% !important;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  width: 33%;
  background: #cccccc;
  transition: all 5s ease-in-out;

  .error-btn {
    padding: 6px;
    width: 78px;
    color: white;
    background: #006fbc;
    border-radius: 5px;
    margin-top: 3px;

    &:hover {
      background: black;
      color: white;
    }
  }
}

#right_content {
  display: block !important;
}

// .bg-class {
//   background: #111111;
// }
// .proof-heading {
//   margin-top: 20%;
//   color: #fff;
//   font-size: 72px  ;
//   margin-left: 10%;
// }
// .dispaly-bg-{
//   display: flex;
// }
// .second-proof {
//   font-size: 18px;
//   line-height: 23px;
//   margin-left: 10%;
//   color: #fff;
// }
// .test-btn {
//   background: #656cff;
//   color: #fff;
//   width: 285px;
//   font-size: 18px;
//   padding: 10px;
//   border-radius: 25px;
//   margin-top: 20px;
//   margin-bottom: 20px;
//   margin-left: 10%;
// }
// @media all and (max-width: 767px) {
// .dispaly-bg-{
//   flex-direction: column-reverse;
// }
// .proof-heading {
//   color: #fff;
//   font-size: 40px !important ;
//   margin-left: auto;
// }
// .image-demo{
//   width: 100% !important;
// }
// .second-proof{
//   font-size: 20px ;
//   margin-left: auto;
// }
// .bg-class{
//   height: 100%;
// }
// .test-btn{
//   margin-left: auto;
// }
// }

// .image-demo{
//   width: 90%;

// }
// @media all and (min-width: 767px)and (max-width:1439px) {

//   .proof-heading {
//     color: #fff;
//     font-size: 40px !important ;
//   }
//   .second-proof{
//     font-size: 20px ;
//   }
//   .bg-class{
//     height: 100%;
//   }
//   }

// @media all and (max-width: 280px) {
//   .bg-class {
//     height: 100% !important;
//   }
//   .test-btn {
//     width: 100%;
//     font-size: 10px;
//     margin-bottom: 20px;
//   }
//   .proof-heading {
//     margin-top: 20px;
//     line-height: 48px;

//     font-size: 25px !important;
//   }
//   .second-proof {
//     font-size: 8px !important;
//     color: #fff;
//   }
// }

.header-dbr {
  font-size: 22px;
  background: #0d3953;
  text-align: center;
  font-weight: 600;
  padding: 3px 6px;
  margin-bottom: 4px;
  color: #fff;
}

.box-border-dbr {
  box-shadow: 0 0 4px 0 #008c02;
  padding-bottom: 20px !important;
}

.login-tea-image {
  margin-top: 200px;
}

.Te_body {
  font-family: "Roboto", sans-serif !important;
  margin: 5px !important;
  font-size: 16px !important;
  height: 100% !important;
}

.Te_DisburseMail {
  width: 96%;
  margin: 2% auto;
  border: 1px solid #006fbc;
  height: 96% !important;
}

.Te_header {
  background: #fff;
  text-align: center;
  border-bottom: solid 1px #006fbc;
  height: 50px !important;
  padding: 10px;
}

.Te_header-img {
  width: 200px;
  float: right;
}

.Te_DisburseText {
  padding: 20px;
}

#bgBlue {
  color: #ffffff;
  background-color: #006fbc;
  height: 32px;
}

#bgBlue h3 {
  padding: 5px !important;
}

.small-text {
  font-size: 12px;
}

.card-bank {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 10px;
}


#loading-spinner {
  width: 117px;
  height: 117px;
  position: relative;
  margin: 50px auto;
  border: 3px solid #f3f3f3;
  border-radius: 50%;
  border-top-color: #3498db;
  animation: spin 1s linear infinite;
  margin-top: 20%;
}


@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.pay-head {
  margin-top: 50px;
  padding: 20px;
  background: #006fbc;
  color: #fff;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

}

.pay-body {
  padding: 20px;

  border-left: 1px solid #006fbc;
  border-right: 1px solid #006fbc;
  border-bottom: 1px solid #006fbc;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  min-height: 300px;

}

.date-input-admin {
  background: #efefef;
  border-radius: 5px;
  padding: 10px;
  border: none;
  width: 80%;
  color: #006fbc;

}

.new-cancle-btn {
  background: #006fbc;
  padding: 10px;
  color: #fff;
  border-radius: 5px;
  margin-top: 15px;

}

.admin-card {
  // border: 1px solid grey;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

}

.elipse-blue {
  background: #006fbc;
  height: 28px;
  width: 28px;
  border-radius: 40px !important;
  color: #fff;
  padding: 14px 15px;
  margin-top: 8px;
  font-size: 20px;

}

.green-div {
  // background-color: #00233C;
  // width: 100%;
  color: #000;
  padding: 15px;
  // border-bottom-left-radius: 20px;
  // border-top-left-radius: 20px
}

.grey-div {
  background-color: #f8faf7;
  height: 100%;
}

.active-credit-tab {

  background: rgb(35, 50, 44);
  color: #000 !important;
  padding: 10px;
  border-radius: 10px;

}

.main-credit-tab {
  background: #fff;
  border-radius: 5px;
  // padding: 10px;
  font-weight: 700 !important;
  color: #000 !important;

}

.credit-icon {
  // position: relative;
  width: 22px;
  width: 25px;
  padding: 3px 7px;
  height: 25px;
  border-radius: 83px;
  background-color: #000;
  margin-left: 96%;
  // z-index: 999;
  cursor: pointer;
}

.phocket-credit {
  color: #000;

  ul {
    list-style-type: none;
    padding: 0;
  }

  .accordion-credit {
    color: #000;
    // cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    outline: none;
    font-size: 15px;
    // border: solid 2px $bluecolor;
    // transition: 0.4s;
    background: rgba(246, 246, 246, 0.6);
    border: 1px solid #EEEEEE;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;

  }

  .active,
  .accordion:hover {
    border: solid 2px $orangecolor;
    color: $orangecolor;
  }

  .accordion:after {
    content: "\002B";
    font-weight: bold;
    float: right;
    margin-left: 5px;
  }

  .active:after {
    content: "\2212";
  }

  .panel {
    background-color: white;
    max-height: 0;
    overflow: hidden;
    padding: 3px 10px;
    transition: max-height 0.2s ease-out;
    box-shadow: none !important;
    border-radius: 0 !important;
    margin-bottom: 0 !important;

    ul {
      list-style-type: disc;
      padding: 0px 20px;
    }

    table {
      margin: 10px 0px 10px 40px;

      td,
      th {
        padding: 5px;
        border: solid 1px $bluecolor;
      }
    }
  }
}

.credit-cards-new {
  // width: 98%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  overflow: hidden;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 6px;
  min-height: 400px;


  .credit-head {
    background: #00233C;
    padding: 20px;
    color: #fff;
    border-radius: 6px 6px 0px 0px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
  }

  .credit-body {
    font-weight: 500;
    padding: 15px;

    &:hover {
      background-color: #FFE9E3;
      cursor: pointer;
    }
  }

  .text-blue {
    color: #2B78FF;
    font-weight: 700;
    font-size: 12px;
    text-align: end;
  }

  .border-credit {
    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  }

  .credit-footer {
    background: #DBE9FF;
    border-radius: 0px 0px 6px 6px;
    padding: 10px;
    text-align: center;
    color: #2B78FF;
    font-weight: 700;
    font-size: 14px;
    width: 100%;

    &:hover {
      background-color: #2B78FF;
      color: #fff;
      cursor: pointer;
    }

  }

  .fixed-btn {
    position: absolute;
    bottom: 0px;
    // left: 20px;
    width: 100%;
  }
}

.credit-dashboard {
  .credit-cards {
    margin-top: 20px;
    background: #FFFFFF;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
    min-height: 140px;
    border-radius: 6px;
    padding: 10px;

  }

  .color-cards {
    min-height: 80px;
    // background: linear-gradient(180deg, #EEF6DA 0%, #FFFFFF 100%);
    border: 1px solid #E6E8F6;
    background: linear-gradient(180deg, #C8E2FA 0%, #FFF 100%);
    border-radius: 6px;
    // border-top: 10px solid #95C90A;
    position: relative;
    margin-top: 10px;
  }

  .color-footer {
    position: absolute;
    bottom: 0px;
    // left: 20px;
    // background: #95C90A;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-weight: 700;
    color: #95C90A;
    border-radius: 0px 0px 6px 6px;

  }

  .color-cards-red {
    min-height: 180px;

    // background: linear-gradient(180deg, #EEF6DA 0%, #FFFFFF 100%);
    border: 1px solid #E6E8F6;
    background: linear-gradient(180deg, #C8E2FA 0%, #FFF 100%);
    border-radius: 6px;
    // border-top: 10px solid #95C90A;
    position: relative;
    margin-top: 10px;

  }

  .color-footer-red {
    position: absolute;
    bottom: 0px;
    // left: 20px;
    // background: #F2483A;
    width: 100%;
    text-align: center;
    padding: 5px;
    font-weight: 700;
    color: #F2483A;
    border-radius: 0px 0px 6px 6px;

  }

  .green-circle {
    background: #4C9AD0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 22px;
    text-align: center;
    padding: 11px;
    color: #fff;
  }

  .red-circle {
    background: #4C9AD0;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    font-size: 22px;
    text-align: center;
    padding: 11px;
    color: #fff;
  }

  .middle-card-height {
    min-height: 300px;
  }

  .notes {
    margin-left: 20px;

    //  margin-right: auto;
    li::marker {
      color: #FF6B93;
      font-size: 1.5em;
      background-color: #FF6B93;
    }


    li {
      list-style: disc;
      padding-bottom: 10px;
      padding-top: 10px;
      border-bottom: 1px solid dimgray;

    }
  }

  .recharts-curve {
    display: none !important;
  }

  .credit-cards-big-green {
    // background: rgba(223, 239, 182, 0.5);
    border-radius: 6px;
    border-bottom: 10px solid #95C90A !important;
    min-height: 140px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .credit-cards-big-purple {
    // background: rgba(216, 212, 254, 0.5);
    border-radius: 6px;
    border-bottom: 10px solid #7C70FC !important;
    min-height: 140px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);



  }

  .credit-cards-big-red {
    // background: rgba(255, 233, 227, 0.5);
    border-radius: 6px;
    border-bottom: 10px solid #FF7448 !important;
    min-height: 140px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .credit-cards-big-blue {
    // background: rgba(227, 238, 255, 0.5);
    border-radius: 6px;
    border-bottom: 10px solid #2B78FF !important;
    min-height: 140px;
    border: 1px solid rgba(0, 0, 0, 0.10);
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .green-circle-big {
    background: #95C90A;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 50px;
    height: 50px;
    border-radius: 25px;
    margin-left: auto;

    font-size: 22px;
    text-align: center;
    padding: 11px;
    color: #fff;
  }

  .blue-box {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid rgba(0, 0, 0, 0.10);

    position: relative;
    // background: #EBF3FF;
    border-radius: 6px;
    min-height: 230px;
    padding-top: 40px;
  }

  .blue-dot-small {
    background: #006FBC;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    width: 20px;
    height: 20px;
    border-radius: 25px;
    // margin-left: auto;
    font-size: 10px;
    padding: 3px 7px;
    color: #fff;
    // margin-right: auto;
    float: left;
    margin-top: -5px;
  }
}

.recharts-text {
  background: #000 !important;
}

.credit-head-tab {
  li {
    width: calc(100%/5);
  }
}

.cred-sub-tab-active {
  background: #D4E5FF;
  box-shadow: 4px 4px 30px 0px #7D91CB inset;
  padding: 4px 8px 0px 9px;
  min-height: 100px;
  position: relative;
  cursor: pointer;

}

.cred-sub-tab {
  min-height: 100px;
  background: #FFF;
  box-shadow: 10px 10px 20px 0px #E6E8F6 inset, -10px -10px 20px 0px #D3D9E9 inset;
  padding: 4px 8px 0px 9px;
  position: relative;
  cursor: pointer;
}

.cred-circle {
  border-radius: 50px;
  background: #1D3557;
  padding: 10px 14px;
  color: #fff;
  width: 40px;
  height: 40px;
  margin-top: 20px;


}

.box-verify {
  bottom: 3;
  color: #f2483a;
  font-weight: 700;
  padding: 5px;
  position: absolute;
  text-align: center;
  font-size: 12px;
  // width: 100%;
}

.credit-sub-tab {
  border-right: 1px solid rgba(125, 145, 188, 0.50);
  border-bottom: 1px solid rgba(125, 145, 188, 0.50);
  background: #E3EEFF;
  padding: 20px;
  cursor: pointer;
}

.credit-sub-tab-active {
  border-bottom: 4px solid #2B78FF !important;
  background: #1D3557;
  padding: 20px;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
}

.credit-table {
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  width: 100%;
  // font-weight: 600;

  table {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    width: 100%;
    margin-top: 20px;


    thead {
      background: #00233C;
      // text-align: center !important;
      color: #fff;


      th {
        padding: 20px;
        text-align: center;
      }

      th:first-child {
        border-top-left-radius: 15px;
        text-align: center;

      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {

      // background: $tablebody;
      td {
        border-bottom: 0 !important;
        padding: 20px !important;
        text-align: center;
        font-weight: 400;

      }

      tr:nth-child(even) {
        background-color: #e8f0fe;
      }

      tr {
        &:first-child {
          // color: $grey;
          // border-bottom: solid 1px $borderColor;
          height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          // border-bottom-left-radius: 15px;

        }

        td:last-child {
          // border-bottom-right-radius: 15px;
        }
      }
    }
  }

}

.credit-table-finbit {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  font-weight: 600;

  table {
    width: 100%;
    // margin-top: 90px;


    thead {
      background: #DBE9FF;
      // text-align: center !important;
      color: #000;


      th {
        padding: 20px;
        text-align: center;
      }

      th:first-child {
        // border-top-left-radius: 15px;
        text-align: center;
        font-weight: 700;
      }

      th:last-child {
        // border-top-right-radius: 15px;
      }
    }

    tbody {
      // background: $tablebody;

      td {
        border-bottom: 0 !important;
        padding: 20px !important;
        text-align: center;
      }

      tr:nth-child(even) {
        background-color: #F1F7FF;
      }

      tr {
        &:first-child {
          // color: $grey;
          // border-bottom: solid 1px $borderColor;
          height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          // border-bottom-left-radius: 15px;
        }

        td:last-child {
          // border-bottom-right-radius: 15px;
        }
      }
    }
  }

}

.main-finbit-div {
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  // border-radius: 10px;
  // background: #00233C;
  // padding: 20px;
  margin-bottom: 20px;
  border-top-right-radius: 0px !important;
  border-top-left-radius: 0px !important;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  border: 1px solid var(--line-color, rgba(0, 0, 0, 0.25));

  // label {
  //   font-size: 14px;
  //   color: #D3D9E9;
  // }

  // h5 {
  //   color: #fff !important;
  //   margin-top: 0px;
  // }
}

.font-popins {
  font-family: 'Poppins' !important;
}

.green-table {
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  width: 100%;
  font-weight: 600;

  table {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    width: 100%;
    margin-top: 20px;


    thead {
      background: #00233C;
      // text-align: center !important;
      color: #fff;


      th {
        padding: 20px;
        text-align: center;
      }

      th:first-child {
        border-top-left-radius: 15px;
        text-align: center;

      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      background: #e5f5ec;

      td {
        border-bottom: 2px solid #4CC78C;
        padding: 20px !important;
        text-align: center;
        font-weight: 600;


      }

      // tr:nth-child(even) {
      //   background-color: #e8f0fe;
      // }

      tr {
        &:first-child {
          // color: $grey;
          // border-bottom: solid 1px $borderColor;
          height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          // border-bottom-left-radius: 15px;

        }

        td:last-child {
          // border-bottom-right-radius: 15px;
        }
      }
    }
  }


}

.yellow-table {

  overflow: scroll;
  width: 100%;
  font-weight: 600;

  table {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    width: 100%;
    margin-top: 20px;


    thead {
      background: #00233C;
      // text-align: center !important;
      color: #fff;


      th {
        padding: 20px;
        text-align: center;
      }

      th:first-child {
        border-top-left-radius: 15px;
        text-align: center;

      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      background: #fefbe2;

      td {
        border-bottom: 2px solid #FFD837;
        padding: 20px !important;
        text-align: center;
        font-weight: 600;


      }

      // tr:nth-child(even) {
      //   background-color: #e8f0fe;
      // }

      tr {
        &:first-child {
          // color: $grey;
          // border-bottom: solid 1px $borderColor;
          height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          // border-bottom-left-radius: 15px;

        }

        td:last-child {
          // border-bottom-right-radius: 15px;
        }
      }
    }
  }
}

.red-table-new {

  overflow: scroll;
  width: 100%;
  font-weight: 600;

  table {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    width: 100%;
    margin-top: 20px;


    thead {
      background: #00233C;
      // text-align: center !important;
      color: #fff;


      th {
        padding: 20px;
        text-align: center;
      }

      th:first-child {
        border-top-left-radius: 15px;
        text-align: center;

      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      background: #fcf0f2;

      td {
        border-bottom: 2px solid #FF5C5D;
        padding: 20px !important;
        text-align: center;
        font-weight: 600;


      }

      // tr:nth-child(even) {
      //   background-color: #e8f0fe;
      // }

      tr {
        &:first-child {
          // color: $grey;
          // border-bottom: solid 1px $borderColor;
          height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          // border-bottom-left-radius: 15px;

        }

        td:last-child {
          // border-bottom-right-radius: 15px;
        }
      }
    }
  }
}

.tree-structure {

  .genealogy-scroll::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  .genealogy-scroll::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #e4e4e4;
  }

  .genealogy-scroll::-webkit-scrollbar-thumb {
    background: #212121;
    border-radius: 10px;
    transition: 0.5s;
  }

  .genealogy-scroll::-webkit-scrollbar-thumb:hover {
    background: #d5b14c;
    transition: 0.5s;
  }


  /*----------------genealogy-tree----------*/
  .genealogy-body {
    white-space: nowrap;
    overflow-y: hidden;
    padding: 50px;
    min-height: 500px;
    padding-top: 10px;
    text-align: center;
  }

  .genealogy-tree {
    display: inline-block;
  }

  .genealogy-tree ul {
    padding-top: 20px;
    position: relative;
    padding-left: 0px;
    display: flex;
    justify-content: center;
  }

  .genealogy-tree li {
    float: left;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 20px 5px 0 5px;
  }

  .genealogy-tree li::before,
  .genealogy-tree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 2px solid #ccc;
    width: 50%;
    height: 18px;
  }

  .genealogy-tree li::after {
    right: auto;
    left: 50%;
    border-left: 2px solid #ccc;
  }

  .genealogy-tree li:only-child::after,
  .genealogy-tree li:only-child::before {
    display: none;
  }

  .genealogy-tree li:only-child {
    padding-top: 0;
  }

  .genealogy-tree li:first-child::before,
  .genealogy-tree li:last-child::after {
    border: 0 none;
  }

  .genealogy-tree li:last-child::before {
    border-right: 2px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
  }

  .genealogy-tree li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
  }

  .genealogy-tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 2px solid #ccc;
    width: 0;
    height: 20px;
  }

  .genealogy-tree li a {
    text-decoration: none;
    color: #666;
    font-family: arial, verdana, tahoma;
    font-size: 11px;
    display: inline-block;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
  }

  .genealogy-tree li a:hover+ul li::after,
  .genealogy-tree li a:hover+ul li::before,
  .genealogy-tree li a:hover+ul::before,
  .genealogy-tree li a:hover+ul ul::before {
    border-color: #fbba00;
  }

  /*--------------memeber-card-design----------*/
  .member-view-box {
    padding: 0px 20px;
    text-align: center;
    border-radius: 4px;
    position: relative;
  }

  .member-image {
    width: 60px;
    position: relative;
  }

  .member-image img {
    width: 60px;
    height: 60px;
    border-radius: 6px;
    background-color: #000;
    z-index: 1;
  }

}

.card-credit-head {
  border-radius: 6px 6px 0px 0px;
  background: #CDE0FF;
  padding: 10px;
}

.card-credit {
  min-height: 250px;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  border-left: 1px solid rgba(0, 0, 0, 0.15);
  background: #F4F9FF;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
}

.activity-circle {
  // margin-top: 2px;
  width: 20px;
  height: 20px;
  background: #232F63;
  color: #fff;
  // box-shadow: 6px 6px 20px rgba(255, 92, 93, 0.3);
  // background: linear-gradient(white, white) padding-box,
  //     linear-gradient(to bottom, #FF6A92, #6278E1) border-box;
  border-radius: 50em;
  border: 10px solid transparent;
  margin-left: auto;
  margin-right: auto;
}

.heading-kyc {
  background-color: #1D3557;
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  .heading {
    color: #fff
  }

  .green-bg-kyc {
    text-align: center;
    border-radius: 5px;
    background-color: #fff;
    padding: 5px;
    color: #4CC78C;
  }
}


.kyc-body {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border: 1px solid #D3D9E9;
  min-height: 300px;
}

.circle-inside-circle {
  width: 20px;
  height: 20px;
  background: #232F63;
  color: #fff;
  border-radius: 50em;
  border: 10px solid transparent;
  margin-left: auto;
  margin-right: auto;
}

.green-div-circle {
  width: 10px;
  height: 10px;
  background: #4CC78C;
  color: #fff;
  border-radius: 50em;
  margin-left: -5px;
  margin-top: -5px;
}

.red-div-circle {
  width: 10px;
  height: 10px;
  background: #FF5C5D;
  color: #fff;
  border-radius: 50em;
  margin-left: -5px;
  margin-top: -5px;
}

.remove-rechart-line {
  .recharts-curve {
    display: none;
  }
}

.pan-circle {
  background: #006FBC;
  width: 30px;
  height: 30px;
  border-radius: 25px;
  color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 5px 7px;
  margin-top: 10px;
}

.pan-div {
  border-radius: 6px;
  background: #E9F1FF;
  padding: 10px;


}

.salary-div {
  margin-top: 10px;
  margin-bottom: 5px;
  background: rgba(242, 242, 250, 0.80);
  padding: 10px 9px;
  border-radius: 5px;
  border-left: 3px solid #006FBC;

}

// .blue-line{
//   width: 3px ;
//   height: 90;
//   background-color: #006FBC;
//   margin-top: auto;
//   margin-bottom: auto;
// }
.conclusion-card {
  background: rgba(242, 242, 250, 0.80);
  padding: 10px;
  border-radius: 6px;
  margin-top: 10px;

}

.easein {
  transition: 0.2s ease-in;
}

.new-menu {
  .mail-list-item-active {
    background-color: #50617e !important;
    transition: box-shadow 0.3s ease-in-out;
    font-weight: 700;
    border-radius: 5px;
    padding: 12px;
    color: #fff;
    font-size: 12px;

  }

  .sub-list-item {
    font-weight: 700;
    border-radius: 5px;
    padding: 12px;
    color: #909aa9;
    cursor: pointer;
    font-size: 12px;
    margin-left: 24px;
  }

  .sub-list-item-active {
    background-color: #50617e !important;
    transition: box-shadow 0.3s ease-in-out;
    font-weight: 700;
    border-radius: 5px;
    padding: 12px;
    color: #fff;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    font-size: 12px;
    margin-left: 24px;

  }

  .sub-menu-item {
    color: #fff;
    padding: 5px;
    // margin-left: 33px;
    font-size: 12px;
  }

  .PhoneInputInput {
    margin-top: -19px !important;
  }
}

.main-tab {
  color: #fff;
  line-height: normal;
  // margin-right: 5px;
  margin-top: 10px;

  padding: 10px 10px;
  font-size: 13px;
  cursor: pointer;
  // width: 100%;
  border-radius: 8px;

  background: linear-gradient(315deg, rgba(29, 53, 87, 0.60) 0%, rgba(0, 0, 0, 0.30) 100%);
  background-blend-mode: soft-light;
  box-shadow: 6.13px 6.13px 8px 0px rgba(11, 34, 68, 0.50), -4.08px -4.08px 12.25px 0px rgba(48, 73, 109, 0.50);
}

.divide-width {
  display: flex;
  list-style: none;

  li {
    width: 130px;
    margin-left: 20px;
    // width: calc(100%/2);
  }
}

.main-tab-active {
  color: #fff;
  line-height: normal;
  // margin-right: 5px;
  margin-top: 10px;

  padding: 10px 10px;
  font-size: 13px;
  cursor: pointer;
  // width: 100%;
  border-radius: 8px;
  background: linear-gradient(315deg, #ffffff99 0%, #ffffff4d 100%);
  background-blend-mode: soft-light;
  // box-shadow: 6.13px 6.13px 3px 0px #051C3C inset, -4px -4px 3px 0px rgba(59, 94, 144, 0.70) inset;

}

@media (max-width: 1024px) {

  .main-tab,
  .main-tab-active {
    font-size: 10px !important;
  }

  .small-svg {
    svg {
      font-size: 66% !important;
    }
  }
}

.loader {
  .loading-container {
    width: 100%;
    max-width: 520px;
    text-align: center;
    color: #fff;
    position: relative;
    margin: 0 32px;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #fff;
      bottom: 0;
      left: 0;
      border-radius: 10px;
      animation: movingLine 2.4s infinite ease-in-out;
    }
  }

  @keyframes movingLine {
    0% {
      opacity: 0;
      width: 0;
    }

    33.3%,
    66% {
      opacity: 0.8;
      width: 100%;
    }

    85% {
      width: 0;
      left: initial;
      right: 0;
      opacity: 1;
    }

    100% {
      opacity: 0;
      width: 0;
    }
  }

  .loading-text {
    font-size: 5vw;
    line-height: 64px;
    letter-spacing: 10px;
    margin-bottom: 32px;
    display: flex;
    justify-content: space-evenly;

    span {
      animation: moveLetters 2.4s infinite ease-in-out;
      transform: translatex(0);
      position: relative;
      display: inline-block;
      opacity: 0;
      text-shadow: 0px 2px 10px rgba(46, 74, 81, 0.3);
    }
  }

  @for $i from 1 through 7 {
    .loading-text span:nth-child(#{$i}) {
      animation-delay: $i * 0.1s;
    }
  }

  @keyframes moveLetters {
    0% {
      transform: translateX(-15vw);
      opacity: 0;
    }

    33.3%,
    66% {
      transform: translateX(0);
      opacity: 1;
    }

    100% {
      transform: translateX(15vw);
      opacity: 0;
    }
  }
}

@media only screen and (min-width: 1900px) {
  input[type="date"] {
    width: 80% !important;
    
  }
  #customDateInput{
    width:100% !important;
  }

  .table-other {
    th {
      color: #746E6E !important;
      font-size: 25px;
      font-weight: 500;
      line-height: 18px;
      font-weight: normal !important;
      text-align: center;

    }
  }

  .recent-img {
    width: 75px !important;
  }

  label {
    color: rgba(0, 0, 0, 0.70) !important;
    font-size: 17px !important;
  }

  p {
    font-size: 16px !important;

  }

  .details-new .bank-box {
    font-size: 22px !important;
  }

  .lm-dashboard .lm-card li {
    min-height: 180px;
  }

  .min-height-api-tracker {
    height: 1100px !important;
  }

  .landing-cards {
    height: 410px !important;
  }

  .landing-inner {
    font-size: 20px !important;
    overflow: scroll;
    height: 350px !important;
  }

  .landing-head {
    font-size: 25px !important;
  }

  .phocket-table-new {
    margin-top: 20px;

    table {
      width: 100%;
      font-size: 14px;
      font-weight: 400;

      thead {
        th {
          padding: 11px !important;
          font-size: 14px !important;
        }
      }

      tbody {

        td {
          border-bottom: 0 !important;
          padding: 25px 7px !important;
          font-size: 14px;
        }


      }
    }
  }

  .container {
    min-width: 80% !important
  }

  .api-tracker-1900 {
    .new-cancle-btn {
      margin-top: 41px !important;
    }

    .btn-count-new {
      div {
        font-size: 17px !important;

        .spandate {
          font-size: 17px !important;
        }
      }

      span {
        font-size: 22px !important;
      }
    }

    .tracker1900table {
      font-size: 18px !important;

      tr {

        th,
        td {
          padding: 18px !important;
        }
      }
    }
  }

  .MISDashboard {

    .Filterations {
      .inputgrp {
        .inputlabel {
          font-size: 24px !important;
        }

        .inputtag {
          font-size: 18px;
        }
      }

      .filterbtn,
      .filterbtnReverse {
        font-size: 18px;
        margin-top: 56px !important;
      }


      .filterbtn2 {
        font-size: 18px;
      }
    }

    .Sections {

      .subSections {
        .Dashboardsmis {
          .table {

            .newTable {
              th {
                font-size: 22px !important;
              }


              td {
                font-size: 20px !important;
              }

            }
          }

          .charts {
            h3 {
              font-size: 30px !important;
              margin: 25px 10px !important;
            }
          }
        }
      }
    }
  }

  .LoanDashboardInsightsApproval {
    .Filterations {
      .inputgrp {
        .inputlabel {
          font-size: 22px !important;
        }

        .inputtag {
          font-size: 20px;
        }
      }

      .filterbtn,
      .filterbtnReverse {
        font-size: 20px;
        margin-top: 45px !important;
      }
    }

    .LoanInsights {

      .topTabs {

        .tabss {
          margin-top: 50px !important;
        }
      }
    }

    .LoanApproval {
      .sectionss {
        margin-top: 60px !important;

        .heading h5 {
          font-size: 30px !important;
        }

        .table {
          border-radius: 15px;

          .newTable {
            th {
              font-size: 22px !important;
            }

            td {
              font-size: 20px !important;
            }
          }

          .table-footer {
            h5 {
              font-size: 20px !important;
            }
          }


        }

      }
    }
  }


}

.cred-scrore-new {
  border-radius: 4px;
  border: 1px solid #D3D9E9;
  background: rgba(242, 242, 250, 0.80);
  padding: 10px;


}

.card-credit-score {
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #D3D9E9;
  background: rgba(242, 242, 250, 0.80);
  min-height: 100px;
  background-image: url('./images/Group 230268.svg');
  background-position: 78% -4px;
  background-repeat: no-repeat;
}

.credit-second-box {
  border-radius: 4px;
  border: 1px solid #D3D9E9;
  background: rgba(242, 242, 250, 0.80);
  // paddin`1g: 4px;
  margin-top: 15px;
  min-height: 500px;
  margin-bottom: 20px;
}

.credit-input {
  border-radius: 6px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  background: #FFF;
}

.credit-second-box-small {
  border-radius: 4px;
  border: 1px solid #D3D9E9;
  background: rgba(242, 242, 250, 0.80);
  // paddin`1g: 4px;
  margin-top: 15px;
  min-height: 400px;
  margin-bottom: 20px;
}

.variation-table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }

  th,
  td {
    text-align: left;
    padding: 5px;
    font-size: 12px;
  }

  tr:nth-child(even) {
    background-color: #f1f3f5;

  }

}

.loan-table-crif {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }

  th,
  td {
    // text-align: center;
    padding: 5px;
    font-size: 12px;
  }

  tr:nth-child(even) {
    background-color: #e6e6fd;
  }

}

.landing-cards {
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 6px;
  border: 2.5px solid #DBE9FF;
  background: #FFF;
  height: 270px;
  margin-top: 50px;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  // overflow: auto;
}

.landing-head {
  border-bottom: 1px solid #808080;
  background: #DBE9FF;
  padding: 10px;
  font-weight: 700;
}

.landing-inner {
  // padding-top: 20px;
  padding: 10px;
  font-size: 14px;
  color: #1D3557;
  cursor: pointer;
  height: 220px;
  overflow: auto;
  // width: 92%;
  // margin-left: auto;
  // margin-right: auto;

}

.phocket-table-new {
  margin-top: 20px;

  table {
    width: 100%;
    font-size: 14px;
    font-weight: 400;

    thead {
      background: $tablehead;
      text-align: left !important;
      color: #746E6E;

      th {
        padding: 20px;
      }

      th:first-child {
        border-top-left-radius: 15px;
      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      // background: $tablebody;

      td {
        border-bottom: 0 !important;
        padding: 5px 10px;
      }

      tr {
        &:first-child {
          // color: $grey;
          font-weight: normal;
          border-bottom: solid 1px $borderColor;
          // height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          border-bottom-right-radius: 15px;
        }
      }

      tr:nth-child(even) {
        background-color: #f7f9fe;
      }


    }
  }
}

.select-new {
  select {
    border-radius: 6px;
    background: #DBE9FF;
    color: #555;
    border: none;
    font-weight: 700;
    padding: 15px;
  }
}

.count-div {
  padding: 10px;
  border-radius: 12px;
  background: #DBE9FF;
}

.btn-count-new {
  background-color: #fff;
  padding: 10px;
  border-radius: 12px;
  width: 100%;
}

.grey-circle {
  height: 43px;
  width: 43px;
  border-radius: 25px;
  border: 1px solid rgba(0, 0, 0, 0.45);
  color: rgba(0, 0, 0, 0.45);
  cursor: pointer
}

.details-new {

  .chat-container {
    display: flex;
    flex-direction: column;
    height: 300px;
    border: 1px solid #ccc;
    border-radius: 5px;
    overflow: hidden;
  }

  .message-container {
    flex: 1;
    padding: 10px;
    overflow-y: auto;
  }

  .message {
    padding: 8px;
    margin-bottom: 8px;
    border-radius: 5px;
    max-width: 100%;
  }

  .user {
    background-color: #e6f7ff;
    align-self: flex-start;
  }

  .admin {
    background-color: #f0f0f0;
    align-self: flex-end;
  }

  .input-container {
    display: flex;
    padding: 10px;
  }



  .chat-box {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    min-height: 300px;
  }

  .operation-inner-box {
    margin-top: 20px;
    border-radius: 12px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    min-height: 200px;
  }

  .operation-inner-box-head {
    padding: 10px;
    border-radius: 11px 11px 0px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    background: #F1F7FF;
  }

  .opertion-table {
    width: 100%;

    td {
      padding: 10px;
      width: 50%;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.25);
    }
  }

  .sms-box {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    padding: 10px;
    min-height: 300px;


  }

  .ql-container.ql-snow {
    border: none !important;
  }

  .mailBody {
    // max-height: 300px;
    border-radius: 3px;
    background: #fff;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;

    .heading {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
      background-color: #e9ecf5;
      height: 40px;
      margin-top: 4px;
    }

    .ql-container {
      box-sizing: border-box;
      font-family: Helvetica, Arial, sans-serif;
      font-size: 13px;
      // height: 300px !important;
      margin: 0px;
      position: relative;
    }

  }

  .com-box {
    border-radius: 6px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    background: #FFF;
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 200px;
    padding: 10px;

  }

  .action-small-box {
    margin-top: 10px;
    background: #F1F7FF;
    border-radius: 12px;
    min-height: 200px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;

    select,
    input {
      background: #DBE9FF !important;
      color: #000 !important
    }

    label {
      color: #000 !important;
      font-weight: 400 !important;
      font-size: 12px !important;
    }
  }

  .btn-grp-own {
    width: 100%;
    max-width: 760px;

    .btn {
      padding: 16px 2px;
      border: none !important;
      width: calc(100% / 5);
      font-size: 10px;
      background-color: #F1F7FF;
      color: #000;
      // text-wrap: pretty;


    }
  }

  .btn-grp-own-action {
    width: 100%;
    max-width: 760px;

    .btn {
      padding: 16px 2px;
      border: none !important;
      width: calc(100% / 4);
      font-size: 10px;
      background-color: #F1F7FF;
      color: #000;
      // text-wrap: pretty;


    }
  }

  .btn-grp-own-big {
    padding: 0px;
    width: 100.3%;

    .btn {
      padding: 16px 2px;
      // border: none !important;
      width: calc(100% / 4);
      font-size: 14px;
      background-color: #F1F7FF;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.25);


      // text-wrap: pretty;


    }
  }

  .btn-grp-own-logs {
    padding: 0px;
    width: 100%;

    .btn {
      padding: 16px 2px;
      // border: none !important;
      width: calc(100% / 3);
      font-size: 14px;
      background-color: #F1F7FF;
      color: #000;
      border: 1px solid rgba(0, 0, 0, 0.25);


      // text-wrap: pretty;


    }
  }


  .action-btn {
    transform: rotate(-90deg);
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 11px !important;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.95px;
    background: #267DFF;
    // right: 200px;
    /* z-index: 999; */
    border-radius: 6px 6px 0px 0px;
    width: 140px;
    position: fixed;
    z-index: 9;
    margin-top: 93px;
    left: -86px;
    height: 31px;

  }

  .action-btn-communication {
    transform: rotate(-90deg);
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 11px !important;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.95px;
    background: #267DFF;
    /* z-index: 999; */
    border-radius: 6px 6px 0px 0px;
    width: 195px;
    position: fixed;
    z-index: 9;
    margin-top: 260px;
    left: -113px;
    height: 31px;


  }

  .action-btn-close {
    transform: rotate(-90deg);
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 11px !important;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.95px;
    background: #1D3557;
    // right: 200px;
    /* z-index: 999; */
    border-radius: 6px 6px 0px 0px;
    width: 101px;
    position: fixed;
    z-index: 9;
    margin-top: 63px;
    // right: -50px
    right: -25px;
    height: 31px;
  }

  .action-btn-close-communication {
    transform: rotate(-90deg);
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 11px !important;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.95px;
    background: #1D3557;
    /* z-index: 999; */
    border-radius: 6px 6px 0px 0px;
    width: 173px;
    position: fixed;
    z-index: 99;
    margin-top: 212px;
    right: -61px;
    height: 31px;
  }
  .action-btn-close-checklist {

    transform: rotate(-90deg);
    color: #FFF;
    text-align: center;
    font-family: DM Sans;
    font-size: 11px !important;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 3.95px;
    background: #1D3557;
    /* z-index: 999; */
    border-radius: 6px 6px 0px 0px;
    width: 183px;
    position: fixed;
    z-index: 99;
    margin-top: 402px;
    right: -66px;
    height: 31px;
  }

  .action-box {
    // margin-left: auto;
    // margin-right: auto;
    width: 40%;
    // margin-top: 10px;
    border-radius: 10px 0px 0px 10px;
    background: #FFF;
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 92%;
    padding: 10px;
    position: fixed;
    z-index: 999;
    right: 0px;
    top: 60px;
    // overflow: auto;

    select,
    input {
      border-radius: 6px !important;
      background: #1D3557;
      padding: 10px;
      border: none;
      color: #fff
    }

    option {
      background: #fff;
      color: '#000'
    }
  }



  .upload-file {
    width: 100%;
    border-radius: 6px !important;
    background: rgba(219, 233, 255, 0.70) !important;
    padding: 10px;
    border: none;
    cursor: pointer;
  }

  .newpopup {
    position: fixed;
    z-index: 99;
    // width: 40%;
    // margin-right: auto;
    // margin-left: auto;

  }

  .popup-body {
    padding: 20px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .btn-primary {
    background-color: #267DFF !important;
    border-color: #2e6da4 !important;
    padding: 10px !important;
    font-weight: 700;
    margin-bottom: 20px;
  }

  .edit-box {
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    margin-top: 100px;
    border-radius: 12px;
    border-radius: 12px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    min-height: 450px;
    padding: 10px 40px;


    input,
    select {
      border-radius: 6px !important;
      background: rgba(219, 233, 255, 0.70) !important;
      padding: 10px;
      border: none
    }

    label {
      font-size: 12px;
      color: #000
    }
  }

  .reject-text {
    border-radius: 8px;
    border: 1.5px solid #FF772B;
    background: rgba(255, 119, 43, 0.12);
    padding: 15px;
    color: #1D3557;
    font-weight: 700;
  }

  label {
    color: rgba(0, 0, 0, 0.70);
    font-size: 14px;
  }

  .details-new-tab-div {
    border-bottom: 1px solid #DBE9FF;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 5px;
  }

  .btn-group-new {
    button {
      background: #fff;
      // border: 1px solid #39383b;
      border-radius: 12px;
      color: #486F88;
      // font-weight: 700;
      margin-left: 2px;
      padding: 10px;
      width: auto;
      cursor: pointer;
      float: inline-start;

      &:not(:last-child) {
        border-right: none;
      }

      &:hover,
      .active {
        // background: #ecf0f0;
        color: #267DFF;
      }

      &:focus {
        outline: 0;
      }
    }

    .btn-bg-new {
      border: none;
      background: #0c3953;
      padding: 8px !important;
    }

    btn-group &:after {
      content: "";
      clear: both;
      display: table;
    }

    .active {
      // background: #ecf0f0;
      color: #267DFF;
    }
  }

  .details-card {
    border-radius: 16px;
    border: 1.5px solid #DBE9FF;
    background: #FFF;
    min-height: 350px;
    padding: 20px;
    margin-top: 30px;
  }

  .details-head {
    border-radius: 10px 10px 0px 0px;
    background: #DBE9FF;
    padding: 10px 14px;
    font-weight: 700 !important;
    color: rgba(0, 0, 0, 0.80);
  }

  .details-body {
    border-radius: 12px;
    border: 1.5px solid #DBE9FF;
    min-height: 200px;

  }

  .bank-box {
    // min-height: 270px;
    border-radius: 12px;
    border: 1.5px solid #DBE9FF;
  }

  .search-btn {
    border-radius: 12px;
    border: 1px solid #267DFF;
    background: #FFF;
    color: #267DFF;
    padding: 13px;
    font-weight: 700;

  }

  .document-box {
    border-radius: 12px;
    background: rgba(219, 233, 255, 0.70);
    padding: 20px;
    color: #000;
    margin-top: 20px;

    img,
    iframe,
    object {
      margin-bottom: 20px;
    }



  }
}

.animated {
  animation-duration: 0.5s;
  /* Adjust the duration as needed */
}

.slideInRight {
  animation-name: slideInRight;
}

.slideOutRight {
  animation-name: slideOutRight;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}

.threshhold-table {
  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    border: 1px solid #ddd;
  }

  th,
  td {
    text-align: center;
    padding: 10px;
    font-size: 12px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }
}

.date-picker-new {
  .form-control {
    input {
      background: #DBE9FF !important;
      border: none !important;
      border-radius: 6px !important;
    }
  }
}

.activity-logs {
  margin-top: 70px;

  // allactivity section 
  .activity-circle-activetick {

    width: 25px;
    height: 25px;
    color: #fff;
    background: rgba(38, 125, 255, 1);
    border-radius: 50em;
    margin-left: auto;
    margin-right: auto;
    padding: 5px 0px;

    svg {
      width: 28px;
      height: 15px;
      fill: white;
    }
  }

  .activity-circle-active {
    width: 25px;
    height: 25px;
    color: #fff;
    border: 4px solid rgba(38, 125, 255, 1);
    border-radius: 50em;
    margin-left: auto;
    margin-right: auto;
  }


  .h6stepper {
    text-align: center;
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 175%;
    letter-spacing: 0.15px;
  }

  .clocktextstepper {
    color: #EC4899;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-top: 87px;
    position: absolute;
    rotate: 90deg;
    // margin-left: 10px;
    right: 40%;

  }

  .stepperlineActive {
    background: rgba(38, 125, 255, 1);
    width: 3px;
    margin-left: auto;
    margin-right: auto;
    height: 208px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stepperlineinActive {
    background: #bcb4b4;
    width: 3px;
    margin-left: auto;
    margin-right: auto;
    height: 208px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stepperlineinActive-ops {
    background: #bcb4b4;
    width: 3px;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .stepperlineinActive-ops-active {
    background: #267DFF;
    width: 3px;
    margin-left: auto;
    margin-right: auto;
    height: 100px;
    margin-top: 10px;
    margin-bottom: 10px;
  }


  .activity-circle-inactive {
    width: 25px;
    height: 25px;
    color: #fff;
    border: 4px solid #bcb4b4;
    border-radius: 50em;
    margin-left: auto;
    margin-right: auto;
  }


  .tableactivity {
    padding: 30px 15px;
    margin-top: 20px;
    border-radius: 6px;
    background: #F1F7FF;
    width: 95%;



  }

  .active-ops {
    margin-top: -10px;
    border-radius: 12px;
    background: #fff;
    padding: 20px 10px;
  }

  .active-ops-active {
    margin-top: -10px;
    border-radius: 12px;
    background: #DBE9FF;
    padding: 20px 10px;
  }

  .Activites-table {

    table {

      thead {
        background: rgba(185, 216, 249, 0.45);
        // background: #b9d8f9;
        text-align: left !important;

        th {
          padding: 20px;
        }

        th:first-child {
          // border-top-left-radius: 15px;
        }

        th:last-child {
          // border-top-right-radius: 15px;
        }
      }

      tbody {
        background: white;
        // background: rgba(219, 233, 255, 0.25);

        td {
          border-bottom: 0 !important;
          padding: 20px !important;
          color: #000;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }

        tr {
          &:first-child {
            color: #746e6e;
            font-weight: normal;
            border-bottom: solid 1px rgba(0, 0, 0, 0.25);
            height: 70px !important;
          }

          .table-icon {
            border: 0;
            padding: 0;
            background-color: transparent;

            &:hover {
              background-color: transparent;
            }
          }
        }

        tr:nth-last-child(1) {
          td:first-child {
            // border-bottom-left-radius: 15px;
          }

          td:last-child {
            // border-bottom-right-radius: 15px;
          }
        }
      }
    }
  }

  .table-other {
    // font-size: 12px !important;

    th {
      color: #746E6E !important;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      font-weight: normal !important;
      text-align: center;
    }
  }

  .ops-com-box {
    .com-box {
      overflow: auto !important;
      max-height: 429px !important;
    }

    .btn-grp-own {
      max-width: 100% !important;
    }

    select {
      border: none !important;
    }
  }

  .small-popup-rfd {
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border-radius: 14px;
    text-align: center;
    width: 199px;
    top: 220px;
    position: fixed;
    z-index: 9999;
    padding: 10px;
    right: 20px;
    background: #fff;
  }
}

.btn-grp-own-lead-manage {
  padding: 0px;
  width: 100%;

  .btn {
    padding: 13px 2px;
    // border: none !important;
    width: calc(100% / 5);
    font-size: 14px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.40);
    border-bottom: 3px solid rgba(0, 0, 0, 0.25);
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none !important;


  }

  .btn-active {
    padding: 13px 2px;
    // border: none !important;
    width: calc(100% / 5);
    font-size: 14px;
    background-color: #fff !important;
    color: #267DFF !important;
    font-weight: 700;
    border-bottom: 3px solid #267DFF;
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none !important;

  }
}

.btn-grp-own-lead-manage-new {
  padding: 0px;
  width: 100%;

  .btn {
    padding: 13px 2px;
    // border: none !important;
    width: calc(100% / 3);
    font-size: 14px;
    background-color: #fff;
    color: rgba(0, 0, 0, 0.40);
    border-bottom: 3px solid rgba(0, 0, 0, 0.25);
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none !important;


  }

  .btn-active {
    padding: 13px 2px;
    // border: none !important;
    width: calc(100% / 3);
    font-size: 14px;
    background-color: #fff !important;
    color: #267DFF !important;
    font-weight: 700;
    border-bottom: 3px solid #267DFF;
    border-left: none;
    border-top: none;
    border-right: none;
    border-radius: 0;
    box-shadow: none !important;

  }
}

.lead-count-box {
  border-radius: 6px;
  border: 2px solid rgba(0, 0, 0, 0.15);
  padding: 20px;
  text-align: center;
  min-height: 100px;
  cursor: pointer;

  h5 {
    color: rgba(0, 0, 0, 0.45);
  }

  &:hover {
    border: 2px solid #267DFF;
    background: #E7F0FF;

    h5 {
      color: #267DFF
    }
  }
}



.lead-count-box-active {
  border-radius: 6px;
  border: 2px solid #267DFF;
  background: #E7F0FF;
  padding: 20px;
  text-align: center;
  min-height: 100px;
  cursor: pointer;
  border: 2px solid #267DFF;
  background: #E7F0FF;

  h5 {
    color: #267DFF
  }
}

.form-control-date {
  padding: 16px !important;
  border-radius: 6px !important;
  background: #DBE9FF !important;
  width: 100% !important
}

.reset-btn {
  border-radius: 6px;
  border: 2px solid #267DFF;
  background: #fff;
  padding: 13.4px;
  margin-top: 21px;
  width: max-content;
  color: #2B78FF;
  font-weight: 700
}

.lm-dashboard {
  .date-input-lm {
    border-radius: 6px;
    border: 1px solid #DDE1E6;
    width: 100%;
    padding: 10px;
  }

  .lm-second-card {
    border-radius: 6px;
    border: 2px solid #ECEEF6;
    min-height: 200px;
    margin-top: 20px;
  }

  .lm-card-main {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;
      width: 20%;
      padding: 0 15px;
      vertical-align: text-top;
    }
  }

  .lm-card {
    li {
      padding: 10px;
      margin-top: 20px;
      border-radius: 6px;
      border: 2px solid #ECEEF6;
      background: #FFF;
      min-height: 130px;
      margin-left: 5px;
      width: calc(98.2% / 5) !important;
    }

    h5 {
      font-weight: 600;
    }
  }

  .tableFirst {
    border-radius: 10px;
    overflow: scroll;
    text-align: center;
    width: 80%;
    // margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    border: 1px solid #8b808085;

    th {
      padding: 30px;
      background: rgba(185, 216, 249, 0.45);
      border-bottom: 1px solid #8b808085;
    }

    td {
      padding: 30px;
      border-bottom: 1px solid #8b808085;
      background: rgba(185, 216, 249, 0.15);
    }
  }


}

.fixed-top {
  width: 100%;
  position: fixed;
  top: 71px;
  z-index: 1;
}

.card-csv {
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.15);
  padding: 20px;
  margin-top: 20px;
  min-height: 450px;
}

.upload-csv {
  border: 2px dotted #267DFF;
  position: relative;
  top: 35%;

  label {
    cursor: pointer;
    color: #267DFF;
    font-size: 14px !important;

    i {
      font-size: 30px;
    }

    span {
      top: -5px;
      position: relative;
    }
  }

  input {
    display: none;
  }

}

.mark-status {
  box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 14px;
  text-align: center;
  width: 150px;
  top: 220px;
  // position: fixed;
  z-index: 9999;
  padding: 10px;
  margin-left: auto;
  background: #fff;
  margin-top: 10px;
}

.marketing-table {
  overflow: hidden;
  width: 100%;

  table {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    width: 100%;
    margin-top: 20px;
    border-spacing: 0;

    thead {
      background: #00233C;
      color: #fff;
      display: table;
      width: 100%;

      th {
        padding: 20px;
        text-align: center;
      }

      th:first-child {
        border-top-left-radius: 15px;
      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      height: 300px;
      overflow-y: scroll;
      display: block;

      tr {
        display: table;
        width: 100%;

        td {
          border-bottom: 0 !important;
          padding: 20px !important;
          text-align: center;
          font-weight: 400;
        }
      }

      tr:nth-child(even) {
        background-color: #e8f0fe;
      }

      tr:first-child {
        height: 70px !important;
      }

      tr:last-child {
        td:first-child {
          /* border-bottom-left-radius: 15px; */
        }

        td:last-child {
          /* border-bottom-right-radius: 15px; */
        }
      }
    }
  }
}


.aggDetailsTab-new {
  color: #fff;
}

.detail-box {
  border: 1px solid #A8C8FF;
  min-height: 450px;
  margin-top: 10px;
  border-radius: 10px;

  .detail-box-head {
    background: #A8C8FF;
    color: #000;
    padding: 15px;
    border-top-left-radius: 9px;
    border-top-right-radius: 9px;
    font-weight: 700;

  }
}

.box-score {
  margin-top: 40px;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 2px 6px 4px rgba(50, 71, 92, .02), 0 2px 9px 0 rgba(50, 71, 92, .04), 0 4px 4px 0 rgba(0, 0, 0, .25) !important;
  height: 600px;
  overflow: auto;
}

.projection-new {
  $Blackcolor1: #00000040;

  .Projection {
    .BTTR {
      border-top-right-radius: 12px;
    }

    .BBTR {
      border-bottom-right-radius: 12px;
    }

    .BTLR {
      border-top-left-radius: 12px;
    }

    .BBLR {
      border-bottom-left-radius: 12px;
    }

    .Positivebox {
      background: #079704 !important;
      border-radius: 4px;
      padding: 2px 7px;
      color: white;
    }

    .Negativebox {
      background: #DD442A !important;
      border-radius: 4px;
      padding: 2px 7px;
      color: white;
    }

    .bg-positive {
      background-color: #B6E8D0 !important;
    }

    .bg-negative {
      background-color: #FFD3D1 !important;
    }

    .accords {
      // border: 1px solid #00000066;
      padding: 10px;
      // box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02);
      // box-shadow: 0px 2px 9px 0px rgba(50, 71, 92, 0.04);
      // box-shadow: 0px 4px 55px 0px rgba(0, 0, 0, 0.25);
      background: #fff;
      border-radius: 12px;
      margin-top: 20px;

      .heading {
        display: flex;
        box-shadow: 0px 2px 6px 4px #32475C05;

        box-shadow: 0px 2px 9px 0px #32475C0A;

        box-shadow: 0px 4px 4px 0px $Blackcolor1;

        .box {
          cursor: pointer;
          padding: 12px;
          padding-left: 18px;
          display: flex;
          align-items: center;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;

          h5 {
            font-size: 18px;
            font-weight: 500;
            margin-bottom: 0px;
          }

          img {
            width: 40%;
          }
        }

        .tableheader {
          margin-bottom: 0px;

          .trhead {
            background-color: #A8C8FF;
          }

          .tr1 {
            border-bottom: 1px solid $Blackcolor1;
          }

          td {
            border-left: 1px solid $Blackcolor1;
            font-size: 14px;
            font-weight: 400;
          }

          .td1 {
            background-color: #DBE9FF;
          }

          .subtd {
            width: 24%;
          }
        }
      }

    }

    .accordsub {
      padding: 0px !important;

    }

    .td41 {
      display: flex;
      justify-content: center;
    }

    .openaccord {
      border: 1px solid #000 ;

      .heading {
        border-radius: 12px;

        box-shadow: 0px 2px 6px 4px #32475C05;

        box-shadow: 0px 2px 9px 0px #32475C0A;

        box-shadow: 0px 4px 4px 0px $Blackcolor1;

        .tableheader {
          .td1 {
            background-color: #DBE9FF !important;
          }
        }

      }

      .bottomtable {
        padding: 15px;
        padding-top: 30px;


        .subbottom {

          border-radius: 12px;
          border: 1px solid #00000066;

          .tablebottom {
            margin-bottom: 0px;

            th {
              border-left: 1px solid $Blackcolor1;
              font-size: 12px;
              color: #000;
              background-color: #E8D0A3;
            }

            td {
              border: 1px solid $Blackcolor1;
              background-color: #F9F6F1;
            }

            .Leftcolborder {
              color: #000;
              border-left: none !important;
              width: 19.4%;
            }
          }
        }
      }
    }

  }

  .scrollanimation {
    animation: slideinprojection 1s ease-in;
  }

  @keyframes slideinprojection {
    from {
      max-height: 0px;
    }

    to {
      max-height: 1000px;
    }
  }

  @media(max-width:576px) {
    .accords {
      .heading {
        img {
          width: auto !important;
        }
      }

    }
  }
}

.css-z7uhs0-MuiStepConnector-line {
  border-color: #267DFF !important
}

.AccountReconciliaiton-CSS {
  .datefilter {
    border: 1px solid rgba(221, 225, 230, 1);
    border-radius: 6px;
    padding: 10px;
    margin-top: 10px;
    background-color: white;
  }

  .ARTable {
    margin-top: 30px;
    padding: 15px;
    padding-top: 0px;
    border-radius: 12px;
    border: 1px solid rgba(230, 230, 230, 0.7);
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02);

    box-shadow: 0px 2px 9px 0px rgba(50, 71, 92, 0.04);

    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    .tablecol {
      margin-top: 10px;

      .heading {
        h5 {
          font-size: 16px;
          font-weight: 500;
          color: rgba(29, 53, 87, 1);
        }

        &.secondary {
          display: flex;
          justify-content: space-between;
        }
      }

      .tablefirst {
        .table1 {
          border-radius: 12px;
          border: 1px solid rgba(242, 242, 242, 1)
        }

        .thead-class {
          background: rgba(185, 216, 249, 0.45) !important;
          color: rgba(116, 110, 110, 1);
          font-size: 12px;
          font-weight: 500;

        }

        td {
          font-size: 12px;
          font-weight: 400;
          color: rgba(0, 0, 0, 1);
        }

        .status {
          font-weight: 600;
        }

        input[type="checkbox"] {
          width: 20px;
          height: 20px;
          cursor: pointer;
        }

        input[type="checkbox"]:checked {

          accent-color: rgb(43, 120, 255);
        }
      }

      .tablesecond {
        .table2 {
          border-radius: 12px;
          border: 1px solid rgba(230, 237, 255, 1);
        }

        .thead-class {
          background: rgba(0, 0, 0, 1) !important;
          color: white !important;

        }

        .table2 tbody tr:nth-child(even) {
          background-color: rgba(153, 153, 153, .3);
        }

      }
    }
  }


  .background-blur {
    background: rgba(0, 0, 0, 0.4);
  }

  .modal {
    top: 15% !important;
    display: block !important;

    .modalheader {
      padding: 15px;
      padding-bottom: 0px;
      display: flex;
      justify-content: space-between;

      h4 {
        color: rgba(29, 53, 87, 1);
        font-size: 16px;
        font-weight: 700;
      }
    }

    .modal-body {
      padding-top: 0px;

      .tablesecond {
        margin-top: 20px;

        .table2 {
          border-radius: 12px;
          border: 1px solid rgba(230, 237, 255, 1);
          font-size: 14px;
          margin-top: 0px !important;
        }

        td {
          border-bottom: 10px solid rgba(0, 0, 0, 0.25)
        }

        .thead-class {
          background: rgba(0, 0, 0, 1) !important;
          color: white !important;
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        tbody {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }

        .table2 tbody tr:nth-child(odd) {
          background-color: rgba(153, 153, 153, .3) !important;
        }

        .table2 tbody tr:nth-child(even) {
          background-color: white !important;
        }

        .buttoncolor {
          color: #3b81fc;
          font-weight: 500;

          h5 {
            display: inline;
            cursor: pointer;
          }

        }
      }
    }
  }

  .animation-top {
    animation: slidein .4s ease-in;
  }

  @keyframes slidein {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0%);
    }
  }

  .ReconcileTabs {
    padding-left: 15px;

    .headrow {
      display: flex;

      .backbtn {
        // padding:10px;
        width: 35px;
        height: 35px;
        border-radius: 6px;
        background: rgba(219, 233, 255, 1);
        cursor: pointer;
      }

      h3 {
        margin-left: 10px;
        margin-top: 4px;
        color: rgba(29, 53, 87, 1);

      }
    }

    .tabs {
      margin-top: 20px;
      display: flex;
      text-align: center;
      border-bottom: 2.5px solid rgba(0, 0, 0, .1);

      .box {
        width: calc(100%/5);
        padding: 20px;
        cursor: pointer;

        &.selectedtab {
          background: rgba(219, 233, 255, 0.7) !important;
          border-bottom: 3px solid rgba(38, 125, 255, 1) !important;

          h3 {
            color: rgba(38, 125, 255, 1) !important;
          }
        }

        h3 {
          color: rgba(0, 0, 0, 0.4);
          margin-top: 0px;
          margin-bottom: 0px;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }

  // mandate report
  .main-search-comp {
    display: flex;

    .form-control {
      padding: 13px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
      width: 45%;
      background: rgba(219, 233, 255, 1);
      border: 1px solid rgba(168, 200, 255, 1);
      font-size: 14px;
      font-weight: 400;

    }

    .subsearchform {
      border-radius: 6px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
      background: rgba(255, 255, 255, 1);
      border-width: 1px, 1px, 1px, 0px;
      border-color: rgba(0, 0, 0, 0.25);
      color: rgba(0, 0, 0, 0.4);
      font-size: 14px;
      font-weight: 400;
    }

  }

  .col-sm-3 {
    width: auto !important;
  }

  .datecol {
    .dates {
      .dateinput {
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding: 13px;
      }

      .fromdate {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: none;
      }

      .todate {
        border-left: none;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
      }
    }

    .hrtag {
      width: 0px;
      height: 30px;
      margin-top: 33px;
      border: 1px solid rgba(0, 0, 0, 0.8);
    }
  }

  .reset-btn {
    background: rgba(43, 120, 255, 1);
    color: white;
    font-weight: 500;
    padding: 6px 10px;
  }

  .hrtag2 {
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .MandateTable {
    border-radius: 12px;
    border: 1px solid rgba(242, 242, 242, 1);

    .table {
      margin-bottom: 0px;

      .thead-class {
        background: rgba(219, 233, 255, 0.25);

      }

      th {
        padding-top: 20px;
        padding-bottom: 20px;
        border: none;
        font-size: 12px;
        font-weight: 500;
        color: rgba(116, 110, 110, 1);
      }

      td {
        font-size: 12px;
        font-weight: 400;
        color: #000;
        vertical-align: middle;
        border: none;

      }

      .box {
        padding: 8px;
        border-radius: 20px;
        color: white;

        h5 {
          display: inline;
        }
      }
    }
  }

  .MandateTable tbody tr:nth-child(even) {
    background-color: rgba(230, 237, 255, 1);
  }
}

.PerformanceTracker {
  .Performancetrackermainview {
    margin-top: 100px;
    padding-left: 30px;

    .headingh5 {
      font-size: 22px;
      font-weight: 400;
    }

    .col-sm-6 {
      width: auto !important;
    }


    .boxperformance {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      background: rgba(255, 255, 255, 1);
      border-radius: 15px;
      margin-top: 10px;

      .Performancedetailheader {

        .leftside {
          text-align: center;
          padding: 12px;
          width: 50%;

          .subleftside {
            border-right: 1px solid rgba(0, 0, 0, 0.15);
            padding: 14px 5px;
            padding-right: 15px;

            .bg-colorbox {
              width: 100px;
              height: 56px;
              border-top-left-radius: 15px;
              background-color: #267DFF;
              position: absolute;
              z-index: 0;
              margin-top: -26px;
              margin-left: -17px;
            }
          }

          .imgbox {
            position: relative;
            // z-index: 1;
            width: 55px;
            height: 55px;
            border-radius: 50%;
          }

          .h4 {

            font-size: 16px;
            font-weight: 700;
            color: rgba(39, 16, 78, 1);

          }

          .h6 {
            color: rgba(72, 111, 136, 1);
            font-size: 12px;
            font-weight: 400;
          }

          .timerbox {
            display: flex;
            padding: 5px 15px;
            border-radius: 8px;
            background-color: rgba(242, 242, 242, 1);
            text-align: left;

          }
        }

        .rightside {
          padding: 12px;
          width: 50%;

          .subrightside {
            .h4 {
              text-align: center;
              font-size: 12px;
              font-weight: 400;
              color: rgba(72, 111, 136, 1);
            }

            .chart {
              margin-top: 15px;
              text-align: center;

              .box {
                h5 {

                  font-size: 12px;
                  font-weight: 500;
                }
              }
            }

            .text1 {
              margin-left: 10px;

              h5 {
                margin-top: 5px;
                color: #486F88;
                font-size: 12px;
                font-weight: 400;
              }
            }

            .text2 {

              h5 {
                margin-top: 5px;
                color: #486F88;
                font-size: 12px;
                font-weight: 400;
              }

            }

            .text1,
            .text2 {
              width: 50%;

              .boxtext {
                display: flex;

                h5 {
                  color: #000;
                  font-size: 14px;
                  font-weight: 500;
                }

                .square {
                  border-radius: 12px;
                  padding: 0px 10px;

                  h6 {
                    margin-top: 2px;
                    margin-bottom: 0px;
                  }
                }

                .bg-green {
                  background: #E1F6EC;
                  color: #4CC78C;
                  // height: 25px;
                  font-family: DM Sans;
                  font-size: 10px;
                  font-weight: 500;
                  padding: 4px;

                }

                .bg-red {
                  background: #FEECEB;
                  color: #FF5C5D;
                  // height: 25px;
                  font-family: DM Sans;
                  font-size: 10px;
                  font-weight: 500;
                  padding: 4px;

                }
              }
            }
          }
        }
      }

      .buttontext {
        text-align: center;
        padding: 8px;
        background: rgba(219, 233, 255, 0.7);

        h5 {
          cursor: pointer;
          color: rgba(38, 125, 255, 1);
          font-size: 14px;
          font-weight: 700;
        }
      }
    }

    .boxteammembers {
      position: relative;
      z-index: 0;
      background-color: #FFF2EA;
      border-radius: 12px;
      padding: 15px;
      margin-top: 10px;

      .caraousel {
        .owl-nav {
          text-align: end;

          .owl-prev,
          .owl-next {
            background: #267DFF;
            color: white;
            border-radius: 50%;
            width: 22px;
            height: 22px;
          }

          .owl-dots {
            margin-bottom: 30px !important;
          }
        }


        .itemcard {
          padding: 5px;

          .subcard {
            box-shadow: 0px 0px 10px 1px #b3a7a7;
            min-height: 200px;
            background-color: white;
            text-align: center;
            padding: 20px;
            border-radius: 12px;

            .imgbox {
              margin: 0 auto;
              width: 55px;
              height: 55px;
              border-radius: 50%;
            }

            .h4 {
              font-size: 12px;
              font-weight: 700;
              color: rgba(39, 16, 78, 1);

            }

            .h6 {
              color: rgba(72, 111, 136, 1);
              font-size: 10px;
              font-weight: 400;
            }
          }
        }
      }

    }

    .hrtag {
      margin-top: 25px;
      border: 1px solid #00000040;
    }

    .searchboxrows {
      margin-top: 20px;

      .filterHeaders {
        margin-top: 4px;
        margin-bottom: 5px;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
      }

      .datefilter {
        border: 1px solid #00000040;
        background: #FFFFFF;
        border-radius: 6px;
      }

      .btn {
        color: white;
        border-color: #2B78FF;
        background-color: #2B78FF;
        margin-top: 26px;
        padding: 10px;
        border-radius: 5px;
      }

      .search-comp {
        .searchform {
          background: #FFFFFF;
          border: 1px solid #00000040;
          border-radius: 6px;
        }
      }
    }

    .rowgraph {
      margin-bottom: 40px;

      .col-sm-6 {
        width: 50% !important;
      }

      .operationprocessgraph {
        border: 1px solid #00000040;
        border-radius: 15px;
        padding: 10px;
        box-shadow: 0px 4px 4px 0px #00000040;
        min-height: 320px;

        h5 {
          color: #252C58;
          font-size: 16px;
          font-weight: 500;
        }

        .funnelhorizontalgraph {
          overflow-x: scroll;

          .cols {
            display: flex;

            .box {
              width: calc(12.5% - 5px);
              margin-right: 5px;
              padding-top: 10px;
              padding-bottom: 10px;
              background-color: #f8f8f8;
              height: 285px;
              text-align: center;

              h5 {
                color: #486F88;
                font-size: 10px;
                font-weight: 400;
              }

              .subbox {
                // clip-path: polygon(0 10%, 100% 20%, 100% 80%, 0 90%);
                background-color: #232F63;
                height: 200px;
              }

              .h5 {
                position: relative;
                top: -45%;

                font-size: 16px;
                font-weight: 700;
                // color: white ;
                text-align: center;
              }

              h4 {
                margin-top: 0px;
                position: relative;
                top: -20%;
              }
            }
          }

          .cols {
            display: flex;

            .box-customer {
              width: calc(14.5% - 5px);
              margin-right: 5px;
              padding-top: 10px;
              padding-bottom: 10px;
              background-color: #f8f8f8;
              height: 285px;
              text-align: center;

              h5 {
                color: #486F88;
                font-size: 10px;
                font-weight: 400;
              }

              .subbox {
                // clip-path: polygon(0 10%, 100% 20%, 100% 80%, 0 90%);
                background-color: #496f49;
                height: 200px;
              }

              .h5 {
                position: relative;
                top: -40.3%;

                font-size: 13px;
                font-weight: 700;
                // color: white ;
                text-align: center;
              }

              h4 {
                margin-top: 0px;
                position: relative;
                top: -20%;
              }
            }

            .box-dashboard {
              width: calc(14.5% - 5px);
              margin-right: 5px;
              padding-top: 10px;
              padding-bottom: 10px;
              background-color: #eeeeee;
              height: 285px;
              text-align: center;

              h5 {
                color: #486F88;
                font-size: 10px;
                font-weight: 400;
              }

              .subbox {
                // clip-path: polygon(0 10%, 100% 20%, 100% 80%, 0 90%);
                background-color: #252f60;
                height: 200px;
              }

              .h5 {
                position: relative;
                top: -41%;

                font-size: 16px;
                font-weight: 700;
                // color: white ;
                text-align: center;
              }

              h4 {
                margin-top: 0px;
                position: relative;
                top: -20%;
              }
            }

          }
        }

        .custom-tooltip-area {
          position: relative;
          background-color: #222539;
          font-size: 17px;
          font-weight: 700;
          color: white;
          border-radius: 8px;
          padding: 10px;


          .arrow {
            width: 0;
            height: 0;
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-top: 10px solid #222539;
            position: absolute;
            bottom: -10px;
            left: calc(50% - 10px);
          }
        }

        .funnelchart2 {
          display: flex;

          .chart {
            width: 96%;
          }

          .text {
            margin-top: 2%;
            width: 40%;

            h5 {
              display: flex;
              margin-top: 20px;
            }
          }
        }
      }
    }

    .background-blur {
      background: rgba(0, 0, 0, 0.4);
    }

    .modal {
      top: 15% !important;
      display: block !important;

      .modal-dialog {
        width: 80% !important;
      }

      .modalheader {
        padding: 15px;
        padding-bottom: 0px;
        display: flex;
        justify-content: space-between;

        .popuph5 {
          margin-top: 5px;
        }

        .btnss {
          .downloadbtn {
            border: 1px solid #267DFF;
            padding: 5px 10px;
            color: #267DFF;
            border-radius: 6px;
          }
        }
      }

      .modal-body {
        padding-top: 0px;

        .tablesecond {
          margin-top: 20px;

          .table2 {
            border-radius: 12px;
            border: 1px solid #A8C8FF;
            font-size: 14px;
            margin-top: 0px !important;
          }

          td {
            border-bottom: 10px solid rgba(0, 0, 0, 0.25)
          }

          thead {
            background: #A8C8FF !important;
            color: #486F88 !important;
          }

          .thead-class {
            border-top-left-radius: 12px;
            border-top-right-radius: 12px;
          }

          tbody {
            border-bottom-left-radius: 12px;
            border-bottom-right-radius: 12px;
          }

          .table2 tbody tr:nth-child(odd) {
            background-color: white !important;
          }

          .table2 tbody tr:nth-child(even) {
            background-color: rgba(168, 200, 255, .2) !important;
          }


        }
      }
    }

    .animation-top {
      animation: slidein .4s ease-in;
    }

    @keyframes slidein {
      from {
        transform: translateY(-100%);
      }

      to {
        transform: translateY(0%);
      }
    }
  }

  @media (max-width:992px) {

    .colrights {
      float: left !important;
    }

  }
}

.mui-ste {

  .css-z7uhs0-MuiStepConnector-line,
  .css-95m0ql {
    border-color: #267DFF !important;
    width: 86% !important;
    margin-left: auto !important;
    margin-right: auto !important;
    border: 1.5px solid #267DFF !important
  }
}

.excel-table {
  overflow: auto;
}

.excel-table table {
  border-collapse: collapse;
  width: 100%;
}

.excel-table th,
.excel-table td {
  border: 1px solid #000;
  padding: 8px;
}

.excel-table th {
  background-color: #f2f2f2;
}

.excel-table tbody tr:nth-child(even) {
  background-color: #f2f2f2;
}

.excel-table tbody tr:hover {
  background-color: #ddd;
}

.APITracker {
  .outer-table {
    box-shadow: rgba(50, 71, 92, 0.02) 0px 2px 6px 4px, rgba(50, 71, 92, 0.04) 0px 2px 9px 0px, rgba(0, 0, 0, 0.25) 0px 4px 4px 0px;
    width: 80%;
    margin: 20px auto 0px auto;
    border-radius: 10px;

    .table {
      .table-heads {
        background-color: #DBE9FF;
        color: #000;

        th {
          border: none;
          text-align: center;
        }
      }

      td {
        border: 0px;
        background-color: #ffffff;
      }

      .Totalcost-row {
        td {
          border-top: 1px solid grey !important;
        }
      }

      .api-tracker-striped-data tr:nth-child(even) {
        td {
          background-color: #f7f9fe !important;
        }

      }
    }
  }
}

.spinner-loader {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;

  .loader {
    position: absolute;
    left: 46%;
    top: 30%;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid $bluecolor;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
  }

  .loader::after {
    content: '';
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-left: 4px solid #FF3D00;
    border-bottom: 4px solid transparent;
    animation: rotation 0.5s linear infinite reverse;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

.justify {
  justify-content: center !important;
}

.owl-phocket {

  /* Equal Heights for OwlCarousel 2 */
  .owl-carousel {
    .owl-stage {
      display: flex;
    }

    .owl-item {
      display: flex;
      flex: 1 0 auto;
    }

    .thumbnail {
      display: flex;
      flex-direction: column;
      margin: 0 15px;

      .caption {
        display: flex;
        flex: 1 0 auto;
        flex-direction: column;

        .flex-text {
          flex-grow: 1;
        }
      }
    }
  }

}

.check-new {
  text-align: -webkit-center !important;

  .input_wrapper {
    width: 80px;
    height: 40px;
    position: relative;
    cursor: pointer;
  }

  .input_wrapper input[type="checkbox"] {
    width: 80px;
    height: 40px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: #315e7f;
    border-radius: 2px;
    position: relative;
    outline: 0;
    -webkit-transition: all .2s;
    transition: all .2s;
  }

  .input_wrapper input[type="checkbox"]:after {
    position: absolute;
    content: "";
    top: 3px;
    left: 3px;
    width: 34px;
    height: 34px;
    background: #dfeaec;
    z-index: 2;
    border-radius: 2px;
    -webkit-transition: all .35s;
    transition: all .35s;
  }

  .input_wrapper svg {
    position: absolute;
    top: 50%;
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    fill: #fff;
    -webkit-transition: all .35s;
    transition: all .35s;
    z-index: 1;
  }

  .input_wrapper .is_checked {
    width: 18px;
    left: 18%;
    -webkit-transform: translateX(190%) translateY(-30%) scale(0);
    transform: translateX(190%) translateY(-30%) scale(0);
  }

  .input_wrapper .is_unchecked {
    width: 15px;
    right: 10%;
    -webkit-transform: translateX(0) translateY(-30%) scale(1);
    transform: translateX(0) translateY(-30%) scale(1);
  }

  .input_wrapper input[type="checkbox"]:checked {
    background: #23da87;
  }

  .input_wrapper input[type="checkbox"]:checked:after {
    left: calc(100% - 37px);
  }

  .input_wrapper input[type="checkbox"]:checked+.is_checked {
    -webkit-transform: translateX(0) translateY(-30%) scale(1);
    transform: translateX(0) translateY(-30%) scale(1);
  }

  .input_wrapper input[type="checkbox"]:checked~.is_unchecked {
    -webkit-transform: translateX(-190%) translateY(-30%) scale(0);
    transform: translateX(-190%) translateY(-30%) scale(0);
  }
}

.boxteammembers-credit {
  // position: relative;
  // z-index: 0;
  background-color: #FFF2EA;
  border-radius: 12px;
  padding: 15px;
  margin-top: 10px;
  display: flex;
  width: fit-content;
  float: inline-end;

  .itemcard {
    padding: 5px;

    .subcard {
      box-shadow: 0px 0px 10px 1px #b3a7a7;
      min-height: 220px;
      background-color: white;
      text-align: center;
      padding: 20px;
      border-radius: 12px;
      min-width: 150px;

      .imgbox {
        margin: 0 auto;
        width: 55px;
        height: 55px;
        border-radius: 50%;
      }

      .h4 {
        font-size: 12px;
        font-weight: 700;
        color: rgba(39, 16, 78, 1);

      }

      .h6 {
        color: rgba(72, 111, 136, 1);
        font-size: 10px;
        font-weight: 400;
      }
    }

  }
}

.generate-predefined-report {
  margin-top: 100px;

  .boxes {
    // margin-top: 25px;

    .fnbox {
      margin-top: 20px;
      min-height: 100px;

      .box {
        cursor: pointer;
        padding: 5px;
        text-align: center;
        box-shadow: 5px 5px 10px #ccc;
        border-radius: 5px;
        transition: box-shadow 0.3s ease-in-out;

        .square {
          margin: 0px auto;
          width: 35px;
          height: 35px;
          border-radius: 4px;
          box-shadow: 3px 3px 5px 2px #ededed;

          img {
            margin: 4px;
          }
        }

        h5 {
          margin-top: 20px;
          font-size: 20px;
          color: #000;
        }

        &.activebtn {
          box-shadow: inset 5px 5px 10px #ccc !important;
        }

        &:hover {
          background: #757c8024;
        }
      }
    }
  }

  .modal {
    display: block !important;
    top: 25% !important;

    .modal-header {
      text-align: end;
      border-bottom: none;
    }

    .modal-body {
      text-align: center;

      .btn {
        margin-top: 10px;
      }

      .inputgrp {
        .inputtag {
          margin-right: 8px;
          width: 15px;
          height: 15px;
        }

        .labeltag {
          font-size: 12px;
          font-weight: 400;
          cursor: pointer;
        }
      }

      &.datefilter {
        p {
          font-size: 18px;
        }

        .inputss {
          display: flex;
          margin-top: 30px;
          margin-bottom: 20px;
          justify-content: space-around;

          .grp {
            label {
              display: block;
              font-weight: 400;
              text-align: left !important;
            }

            .dateinput {
              border: 1px solid black;
              padding: 10px;
              border-radius: 6px;
            }
          }
        }
      }
    }
  }
}

.custom-react-datePicker-css {
  .react-datepicker__tab-loop {
    .react-datepicker-popper {
      z-index: 2;
    }
  }
}

.css-1km1ehz {
  padding: 0px 15px;
}

.popup-new-emi {
  .modal {
    display: block;
    top: 19%;

    .modal-header {
      border-bottom: none;
      padding: 10px 15px;

      .header-text {
        display: inline-flex;

        h6 {
          font-size: 15px;
          margin-top: 5px;
        }
      }

      .btn {
        float: inline-end;
      }
    }

    .modal-body {
      padding: 0px 20px;

      h6 {
        font-size: 15px;
      }

      .formSelect {
        border-radius: 6px;
        background-color: #DBE9FF;
        color: #000;
        padding: 10px;
        width: 30%;
      }
    }

    .modal-footer {
      display: flex;
      justify-content: center;
      border-top: none;
    }
  }
}

.DebtListMain {
  .tabs {
    position: fixed;
    width: 100%;
    overflow-x: hidden;
    z-index: 2;
    top: 71px;

    .box {
      cursor: pointer;
      border: 0.5px solid #A8C8FF;
      padding: 10px;
      background: #DBE9FF;
      border-top: none !important;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);

      &.customwidth {
        width: calc(100% /6);
      }

      h5 {
        text-align: center;
        color: #486F88;
        font-size: 14px;
      }

      &.active {
        background: rgb(38, 125, 255);

        h5 {
          color: #fff;
        }
      }
    }
  }

  .Sections {
    // padding: 100px 20px 50px 20px;
    // padding: 10px 20px;

    .MonthDateFilter {
      .inputGrp {
        .labeltag {
          font-size: 14px;
          display: block;
          margin-bottom: 0px;
          color: #000;
          font-weight: 400 !important;
        }

        .MonthinputTag {
          background-color: rgba(219, 233, 255, 0.7) !important;
          border: 0 !important;
          margin-top: 7px;
          padding: 10px;
          border-radius: 6px;
        }
      }

      .FilterApply {
        color: rgb(43, 120, 255);
        background-color: white;
        margin-top: 23px;
        padding: 10px;
        border-radius: 5px;
        border: 1px solid rgb(43, 120, 255);

        &:hover {
          background-color: rgb(43, 120, 255);
          color: #fff;
        }
      }
    }

    .tables {
      margin-top: 30px;

      .LeftBox {
        height: 235px;
        display: flex;

        .subbox {
          height: 235px;
          width: 50%;
          writing-mode: vertical-rl;
          background-color: #223d64;

          h5 {
            transform: rotateZ(180deg);
            color: #fff;
            width: 100%;
            margin: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            text-align: center;
          }
        }

        .eyeicons {
          display: flex;
          align-items: center;
          margin: auto;
          height: 235px;

          .circle-eye {
            cursor: pointer;
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: #223d64;
            padding: 16px;
            svg{
              color:#fff;
              width:30px;
              height: 30px;
            }

          }
        }
      }

      .newTable {
        box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        overflow-y: auto;
        margin-top: 0px !important;
        height: 230px;

        th {
          border-radius: 0px !important;
        }

        thead {
          position: sticky;
          top: 0;
        }

        &.subnewTable {
          box-shadow: none !important;
          height: 300px !important;
        }
      }

      .newTable2 {
        box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

        th {
          border-radius: 0px !important;
        }

        thead {
          position: sticky;
          top: 0;
        }
      }

      .hrtag {
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }

    .paddingRight {
      padding-right: 10px;
    }

    .paddingLeft {
      padding-left: 10px;
    }

    .newPadding {
      padding: 0px 20px;
    }

    .newmargin20 {
      margin-top: 20px;
    }

    .DebtManagement {
      margin-top: 50px;
      padding: 15px 30px;
      min-height: 600px;
      border-radius: 10px;
      background-color: #fff;
      box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;


    }

    .DebtManagementDashboard {
      .LinechartBlock {
        .outerBox {
          margin-top: 40px;
          position: relative;
          padding-top: 20px;

          .innerBox {
            border: 1px solid #acacac;
            padding: 20px;
            border-radius: 6px;
            box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

            .HeadingBox {
              padding: 5px 15px;
              width: max-content;
              border-radius: 6px;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              text-align: center;

              h5 {
                font-size: 20px;
                color: #fff;
              }

              &.WidthInc {
                width: 50%;
              }
            }

            .Linecharts {
              h5 {
                color: #000;
                background-color: #e1e1e1;
                border-radius: 6px;
                padding: 8px 10px;
                font-size: 18px;
                display: inline-block;
                font-weight: 600;
                margin-top: 20px;
              }

              .charthead {
                height: 80px;

                &.Barchart {
                  height: 300px;
                  margin-top: 20px;
                }

                &.PieNeedlechart {
                  height: 280px;
                  margin-top: 40px;

                }
              }
            }

            &.padding-zero {
              padding: 0px !important;
            }
          }
        }

        &.LoanManagementBlock {
          .Linecharts {
            h4 {
              color: #000;
              font-size: 30px;
              font-weight: 600;
              margin: 30px 0px 15px 0px;
              text-align: center;
            }

            h6 {
              font-size: 18px;
              font-weight: 500;
              margin-top: 20px;

              &.text {
                color: #000;
                margin-top: 0px !important;
              }
            }

            .Rttxt {
              h6 {
                font-weight: 500;
                font-size: 20px;
                margin-bottom: 5px;

                &.text {
                  color: #000;
                  margin-top: 0px !important;
                }
              }
            }

            .Legend {
              &.Scatterchart {
                display: flex;
                justify-content: end;

                .square {
                  width: 40px;
                  height: 15px;
                  margin-right: 10px;
                  background-color: #223d64;
                }

                p {
                  display: inline;
                  margin-bottom: 0px;
                  font-size: 15px;
                  color: #000;
                }
              }

              &.Barchart {
                display: flex;
                justify-content: center;

                .legendbox {
                  display: flex;
                  margin-right: 15px;

                  .square {
                    width: 20px;
                    height: 20px;
                    margin-right: 10px;

                    &.small-size {
                      width: 10px;
                      height: 10px;

                      &.circular {
                        border-radius: 50%;
                      }
                    }
                  }

                  p {
                    display: inline;
                    margin-bottom: 0px;
                    font-size: 15px;
                    color: #000;
                  }
                }
              }
            }

            .sub-legend {
              display: flex;
              justify-content: space-between;

              h4 {
                font-size: 16px;
                font-weight: 600;
              }
            }
          }
        }
      }
    }

    .DebtDashboardBlock {
      margin-top: 25px;



      .Datacard {
        margin-top: 30px;
        box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
        border-radius: 6px;

        .Topsection {
          background-color: #A8C8FF;
          // background-color: #8080804d;
          padding: 8px 10px;
          text-align: center;
          border-radius: 6px;

          h4 {
            font-size: 16px;
            font-weight: 500;
            margin: 0px;
            color: #000;
          }
        }

        .bottomsection {
          background-color: #fff;
          border-radius: 6px;

          h5 {
            font-size: 16px;
            font-weight: 400;
            color: #000;
            text-align: center;

          }

          .fs-inc {
            font-size: 24px;
          }

          .customLegend {
            display: flex;
            justify-content: center;

            .legendbox {
              display: flex;
              align-items: center;
              margin-right: 20px;

              .square {
                margin-right: 10px;
                width: 15px;
                height: 15px;

                &.newone {
                  width: 25px;
                  height: 5px;
                }
              }

              h6 {
                font-size: 16px;
              }
            }
          }
        }
      }
    }

    .PaymentsCollected {

      .filters {
        margin: 20px 0px;

        .labeltag {
          font-weight: 500;
          margin-top: 15px;
        }

        .selectBox2 {
          font-weight: 400;
          width: 100%;
          background-color: #DBE9FF;
          border-radius: 6px;
          height: 40px;
        }

        .ApplyFilter {
          border: 2px solid #267DFF;
          color: #267DFF;
          padding: 10px 15px;
          border-radius: 6px;
          background-color: #fff;
          margin-top: 35px;

          &:hover {
            background-color: #267DFF;
            color: #fff;
          }
        }
      }

      .toptabs {
        // display: flex;
        // flex-wrap: wrap;

        .subtabs {
          // width: 15%;
          // margin-top: 20px;
          // background-color: #223D64;
          // text-align: center;
          // padding: 10px 15px;
          // border-right: 1px solid #fff;
            margin-top: 30px;
    // border: 1px solid #bdbdbd;
    background-color: #ffffff;
    border-radius: 8px;
    padding: 15px;
    box-shadow: 5px 5px 10px 0px #a3a0a0;
   


          h4 {
            color: #000;
            // color: #fff;
            font-size: 24px;
            font-weight: 500;
            // margin-bottom: 5px;
            margin-top: 20px;
          }
          
          h6 {
            color: #000;
            font-size: 18px;
            font-weight: 400;
          }
        }

        .subtabs2 {
          width: 10%;
          background-color: #A8C8FF;
          // background-color: #267dff;          ;
          padding: 10px 15px;
          border-right: 1px solid #000;

          h6 {
            font-size: 16px;
            margin: 0px;
            font-weight: 700;
            color: #000;
          }

          .inputgrp {
            margin-top: 5px;
            display: flex;
            align-items: end;

            .inputtag {
              width: 18px;
              height: 18px;
              margin-right: 10px;
            }

            .labeltag {
              margin-bottom: 0px;
            }
          }

          .selectBox {
            margin-top: 5px;
            width: 100%;
          }

        }
      }

      .graphsSection {
        margin-top: 10px;

        .graphbox {
          margin-top: 20px;
          border: 1px solid #acacac;
          padding: 5px 20px 20px 20px;
          border-radius: 6px;
          box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

          .heading {
            h5 {
              font-size: 18px;
              font-weight: 600;
              color: #000;
            }
          }

          .customLegend {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;

            .box {
              margin-right: 10px;
              display: flex;

              .shape {
                margin-right: 10px;
                width: 15px;
                height: 15px;
                border-radius: 50%;
              }

              h6 {
                margin: 0px;
                font-size: 15px;
                font-weight: 500;
                color: #000;
              }
            }
          }
        }
      }
    }

    .RevenueOperationalExpenses {
      margin-top: 20px;

      .LeftSide {
        .boxes {
          background-color: #223D64;
          padding: 10px 10px 40px 10px;
          border-bottom: 1px solid #fff;
          margin-bottom: 5px;

          .heading {
            h5 {
              color: #fff;
              font-size: 20px;
              font-weight: 500;
            }
          }

          .subbox {
            margin-top: 35px;
            display: flex;
            justify-content: center;

            .text {
              h4 {
                font-size: 45px;
                font-weight: 600;
              }
            }
          }
        }
      }

      .chart {
        border: 1px solid #acacac;
        padding: 5px 20px 20px 20px;
        border-radius: 6px;
        box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

        .heads {
          h5 {
            font-size: 20px;
            font-weight: 500;
            color: #000;
          }
        }

        .legends {
          display: flex;
          justify-content: center;

          .legendboxes {
            display: flex;
            margin-right: 25px;

            .square {
              width: 15px;
              margin-right: 10px;
              height: 15px;
            }

            h6 {
              margin: 0px;
              font-size: 15px;
              font-weight: 400;
              color: #000;
            }

            &.widthchange {
              align-items: baseline;

              .square {
                width: 30px !important;
                height: 10px !important;
              }
            }
          }
        }
      }

      .rightside {
        .outerBox {
          position: relative;
          padding-top: 20px;

          .innerBox {
            border: 1px solid #acacac;
            padding: 20px;
            border-radius: 6px;

            .HeadingBox {
              padding: 5px 15px;
              width: 100%;
              border-radius: 6px;
              position: absolute;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              display: flex;
              align-items: center;
              justify-content: space-between;

              h5 {
                font-size: 20px;
                color: #fff;
              }

              .circleedit {
                width: 25px;
                height: 25px;
                cursor: pointer;
                border-radius: 50%;
              }


            }

            .box {
              .table {
                .newtable {
                  th {
                    border: none;
                    border-right: 1px solid #eee;
                    font-size: 16px;
                    font-weight: 500;
                    text-align: left;
                    padding: 10px;
                  }

                  td {
                    border: none;
                  }

                  .inputTag {
                    border: 1px solid #eee;
                    padding: 5px 10px;
                  }
                }
              }

              .btns {
                text-align: center;

                .savebtn {
                  background-color: green;
                  border: 1px solid green;
                  color: #fff;
                  padding: 10px 15px;
                  border-radius: 6px;

                  &:hover {
                    background-color: #fff;
                    color: green;
                  }
                }
              }
            }
          }
        }
      }
    }

  }
}

.LoanPortfolio {
  box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 3px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

  .TableNew {
    thead {
      tr {
        th {
          color: #fff;
          text-align: center;
          font-weight: 400;
          border-radius: 0px;

          &:nth-child(even) {
            background-color: #05bd47;
          }

          &:nth-child(odd) {
            background-color: #223D64;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          padding: 15px !important;
          text-align: center;
          border: 1px solid rgba(0, 0, 0, 0.25);

          &:nth-child(1) {
            border-bottom: 1px solid #000;
          }
        }

        // &:nth-child(1) {
        //   border-bottom: 1px solid #000;
        //   font-weight: 700;

        //   td {
        //     border-bottom: 1px solid rgba(0, 0, 0, 0.5) !important;
        //   }
        // }

        &:nth-last-child(1) {

          td:first-child,
          td:last-child {
            border-radius: 0px;
          }
        }
      }
    }
  }
}

.optionListContainer {
  .optionContainer {
    .option {
      display: flex;
    }
  }
}

.new-table {
  .table-new {
    width: 100%;
    height: 450px;
    /* Fixed height */
    overflow: auto;
    position: relative;
  }

  table {
    border-collapse: collapse;
    width: 100%;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
    white-space: nowrap;
  }

  th:first-child {
    z-index: 3;
  }

  .fixed-header {
    position: sticky;
    top: -20px;
    background: #f1f1f1;
    z-index: 2;
    left: 0;
  }

  .fixed-column {
    position: sticky;
    left: 0;
    background: #f1f1f1;
    z-index: 1;
  }
}

.Executor {
  // padding: 10px 20px 50px 20px;

  .transition-col {
    transition: all 0.2s ease-in-out;
  }

  .heading {
    font-size: 24px;
    font-weight: 600;
    color: #000;
  }

  .togglebtn {
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 3px;
    box-shadow: inset 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

    .btn {
      border-radius: 3px;
      width: 50%;
      font-size: 16px;
    }

    &.inactive {
      text-align: left;

      .btn {
        box-shadow: 4px 0px 4px 0px rgba(0, 0, 0, 0.25);
        background-color: #737fb3;
        color: #fff;
        margin-top: -3px;
      }
    }

    &.active {
      text-align: right;

      .btn {
        box-shadow: -4px 0px 4px 0px rgba(0, 0, 0, 0.25);
        background-color: green;
        color: #fff;
      }
    }
  }

  .FilterFields {
    margin: 40px 0px;

    .inputs {
      display: flex;

      .Searchgrp {
        margin-right: 20px;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        display: flex;

        .searchselect {
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px;
          height: 100%;
          background-color: #DBE9FF;

        }

        .searchInput {
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          width: 100%;
          padding: 10px;
          background-color: #DBE9FF;
        }
      }

      .searchbtn {
        margin-right: 20px;
        border-radius: 6px;
        background-color: #223d64;
        padding: 10px 15px;

        i {
          color: #fff;
        }
      }
    }

    .RefreshBtn {
      .Refreshbtns {
        background-color: #fff;
        color: rgb(43, 120, 255);
        border: 1px solid rgb(43, 120, 255);
        border-radius: 6px;
        padding: 12px 15px;

        &:hover {
          background-color: rgb(43, 120, 255);
          color: #fff;
        }
      }
    }
  }

  .tableedit {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

    th {
      text-align: center;
    }

    td {
      padding: 15px 20px;
      text-align: center;
    }


    .edits {
      display: flex;
      justify-content: center;

      i {
        margin-right: 15px;
        cursor: pointer;
      }
    }
  }

  .forms {
    margin-top: 30px;
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    padding: 10px 15px;

    .formgrp {
      margin-bottom: 40px;

      &.newmargin {
        margin-bottom: 20px;
      }

      label {
        font-size: 16px;
        font-weight: 400;
        color: #000;
      }

      .inputtag {
        display: block;
        width: 80%;
        margin-top: 5px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        padding: 10px 5px;
        background-color: #DBE9FF;
      }
    }

    .savebtn {
      margin: 40px 0px;
      display: flex;
      justify-content: center;

      .btn {
        background-color: green;
        color: #fff;
        padding: 10px 25px;
        border-radius: 6px;

        &.back {
          background-color: red;
          margin-right: 20px;
        }
      }
    }
  }

  &.NewDesign {
    .tableedit {
      .status {
        border-radius: 6px;
        padding: 5px;
        font-weight: 500;
        color: #fff;
      }

      tbody {
        tr:nth-last-child(1) {

          td:last-child,
          td:first-child {
            border-radius: 0px;
          }
        }
      }
    }

    .infoBox {
      .Subbox {

        padding: 15px;
        box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
        background-color: #fff;

        h4 {
          font-size: 22px;
        }

        .databox {
          display: flex;
          justify-content: space-between;

          h5 {
            font-size: 18px;
          }

          &.margin15 {
            margin-top: 15px;
          }

          &.margin35 {
            margin-top: 35px;
          }
        }

        .borderdatabox {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid rgba(0, 0, 0, 0.25);

          h5 {
            font-size: 18px;
          }
        }

        .ActionBox {
          h5 {
            display: inline-block;
            padding: 5px 0px;
            font-size: 16px;
            margin: 0px;
            cursor: pointer;
          }
        }
      }
    }
  }
}

.LoanUtility {
  padding: 10px;

  .tabs {
    display: flex;

    .tab {
      cursor: pointer;
      padding: 10px;

      h5 {
        font-size: 16px;
        margin: 0px;
        font-weight: 500;
      }

      &.active {
        border-bottom: 5px solid rgb(43, 120, 255);
      }
    }
  }
}

.product-sidepopup-right {

  -webkit-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  position: fixed;
  background: #fff;
  top: 0;
  left: 10;
  z-index: 9;
  width: 70%;
  min-height: 213px;
  bottom: 0;
  right: 0px;
  // color: #006fbc;
  overflow: auto;
  margin-top: 15px;
  height: 94%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: slideInRight;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s;
  /* don't forget to set a duration! */
  background: #f8f8f8;
}

.product-sidepopup-left {

  -webkit-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.75);
  position: fixed;
  background: #fff;
  top: 0;
  left: 10;
  z-index: 9;
  width: 70%;
  min-height: 213px;
  bottom: 0;
  right: 0px;
  color: #006fbc;
  overflow: auto;
  margin-top: 15px;
  height: 94%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  animation: slideInLeft;
  /* referring directly to the animation's @keyframe declaration */
  animation-duration: 1s;
  /* don't forget to set a duration! */
}






.table-logs {
  width: 100%;

  thead {
    background: '#f2f2f2';

  }

  th {
    padding: 8px 13px;
    line-height: 1.42857143;
    vertical-align: middle;
    // border-top: 1px solid #ddd;
  }

  td {
    padding: 10px 13px;
  }

  &.th-width {
    th {
      word-wrap: break-word;
      min-width: 150px;
      white-space: normal;
    }
  }

}

@keyframes backgroundChange {
  0% {
    background-color: #fff;
    /* Original background color */
  }

  50% {
    background-color: #ffeb3b;
    /* Color during animation (e.g., yellow) */
  }

  100% {
    background-color: #fff;
    /* Original background color */
  }
}

.highlight-bg {
  animation: backgroundChange 1s ease;
  /* Animation duration and timing function */
}

.tooltip-new {
  .tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
  }

  /* Tooltip text */
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;

    /* Position the tooltip text - see examples below! */
    position: absolute;
    z-index: 1;
  }

  /* Show the tooltip text when you mouse over the tooltip container */
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

}

.LoanDashboard {
  .heading {
    h4 {
      font-size: 24px;
    }
  }

  .LoanDashboardcards {
    margin-top: 30px;
    box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    background-color: #fff;
    padding: 20px;

    .heads {
      border-bottom: 1px solid #000;

      h5 {
        font-size: 20px;
      }
    }

    .Piechart {
      height: 280px;

      .innnerPieText {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        text-align: center;

        h5 {
          margin-top: 20px;
          color: #267dff;
          font-size: 50px;
          font-weight: 700;
        }
      }

      .bottomtext {
        position: absolute;
        left: 50%;
        width: 100%;
        transform: translate(-50%, -50%);
        text-align: center;

        h6 {
          font-size: 24px;
          font-weight: 500;
          text-align: center;
        }
      }
    }

    .forms {
      .grp {
        margin-top: 20px;

        .btns {
          background-color: #267dff;
          color: #fff;
          border: 1px solid #267dff;
          padding: 10px 15px;
          border-radius: 6px;

          &:hover {
            background-color: #fff;
            color: #267dff;

          }
        }
      }
    }

    .contacts {
      padding: 10px 0px;

      .boxx {
        display: flex;
        align-items: center;

        .leftimg {
          margin-right: 20px;
          padding: 10px 0px;

          .user-circle {
            width: 40px;
            height: 40px;
            border-radius: 50%;
            background-color: #ddd6d6;
            padding: 10px 12px;
          }
        }

        .rightimg {
          h5 {
            font-size: 18px;
          }
        }
      }
    }

    .expireTable {
      overflow-y: auto;
      height: 396px;

      th {
        text-align: center;
      }

      td {
        text-align: center;
        padding: 10px;

        .actions {
          display: flex;
          justify-content: space-evenly;

          i {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.LoanDashboardInsightsApproval {
  .tabs {
    display: flex;
    position: fixed;
    width: 100%;
    overflow-x: hidden;
    z-index: 2;
    top: 71px;

    .subtabss {
      width: 50%;
      cursor: pointer;
      border: 0.5px solid #A8C8FF;
      padding: 10px;
      background: #DBE9FF;
      border-top: none !important;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;

      h5 {
        font-size: 20px;
        color: #486F88;
        font-weight: 500;
      }

      &.active {
        background-color: #267dff;

        h5 {
          color: #fff;
        }
      }
    }
  }

  .Filterations {
    .inputgrp {
      margin-top: 10px;

      .inputlabel {
        display: block;
        font-size: 18px;
        font-weight: 400;
      }

      .inputtag {
        border: 0 !important;
        background-color: rgba(219, 233, 255, 0.7);
        border-radius: 6px;
        width: 100%;
        padding: 10px;
      }
    }

    .filterbtn {
      border: 1px solid #267dff;
      background-color: #267dff;
      color: #fff;
      padding: 10px 20px;
      border-radius: 6px;

      &:hover {
        background-color: #fff;
        color: #267dff;
      }
    }

    .filterbtnReverse {
      border: 1px solid #267dff;
      background-color: #fff;
      color: #267dff;
      padding: 10px 15px;
      border-radius: 6px;

      &:hover {
        background-color: #267dff;
        color: #fff;
      }
    }
  }

  .LoanInsights {

    .topTabs {
      margin-top: 10px;

      .tabss {
        margin: 30px 10px 10px 10px;
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding: 15px 30px;
        box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

        .topBox {
          display: flex;
          justify-content: space-between;
          align-items: center;

          h4 {
            font-size: 26px;
            font-weight: 400;
          }

          h5 {
            font-size: 18px;
            font-weight: 400;
          }
        }

        .BottomBox {
          margin-top: 10px;
          display: flex;
          flex-wrap: wrap;
          align-items: center;

          .left {
            width: 50%;
            display: flex;
            align-items: center;

            h5 {
              font-size: 20px;
              color: grey;
              margin-right: 10px;
            }

            h6 {
              white-space: nowrap;
              font-size: 18px;
              font-weight: 500;
              color: #000;
            }
          }
        }

        .heads {
          margin-bottom: 15px;
          text-align: center;

          h5 {
            font-size: 26px;
            font-weight: 400;
          }
        }

        .legends {
          display: flex;
          justify-content: center;

          .legend1 {
            display: flex;
            align-items: center;
            margin-right: 20px;

            .line {
              width: 30px;
              height: 3px;
            }

            .square {
              width: 20px;
              height: 20px;
            }

            h5 {
              font-size: 18px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }

  .LoanApproval {
    .sectionss {
      margin-top: 30px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      padding: 15px 30px;
      box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

      .heading {
        h5 {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .table {
        border-radius: 15px;

        .newTable {
          th {
            background-color: rgb(242, 242, 242);
            color: #000;
          }

          .activeTr {
            background-color: #c2e1f5;
          }

          tr {
            &:nth-last-child(1) {
              td:first-child {
                border-bottom-left-radius: 0px;
              }

              td:last-child {
                border-bottom-right-radius: 0px;
              }
            }
          }

          td {
            padding: 10px;
          }
        }

        .table-footer {
          background-color: rgb(242, 242, 242);
          display: flex;
          justify-content: space-between;
          padding: 10px 15px;
          border-bottom-left-radius: 15px;
          border-bottom-right-radius: 15px;

          h5 {
            color: #000;
            font-size: 16px;

            i {
              cursor: pointer;
            }
          }

          .paginationBox {
            display: flex;
            justify-content: space-evenly;

            .pagenumber {
              margin: auto 15px;
            }

            .LeftArrow {
              font-size: 20px;
              cursor: pointer;
            }
          }
        }
      }

      .qualifyloanbtn {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;

        .txt {
          h5 {
            font-size: 18px;
            color: #000;
          }
        }

        .btn {
          .qualifytbtn {
            padding: 10px 50px;
            margin-left: 15px;
            color: #fff;
            border-radius: 3px;
            font-size: 16px;
          }
        }
      }

      .dataSection {
        border-radius: 6px;
        border: 1px solid rgba(0, 0, 0, 0.25);
        padding: 15px 30px;

        .block {
          padding: 10px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);

          h3 {
            font-size: 24px;
            margin: 0px 0px 10px 0px;
          }

          .info {
            display: flex;
            align-items: center;

            h4 {
              font-weight: 600;
              margin-right: 10px;
              font-size: 16px;
            }

            h5 {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

.MISDashboard {
  .tabs {
    display: flex;
    position: fixed;
    width: 100%;
    overflow-x: hidden;
    z-index: 2;
    top: 71px;

    .subtabss {
      width: 50%;
      cursor: pointer;
      border: 0.5px solid #A8C8FF;
      padding: 10px;
      background: #DBE9FF;
      border-top: none !important;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      text-align: center;

      h5 {
        font-size: 20px;
        color: #486F88;
        font-weight: 500;
      }

      &.active {
        background-color: #267dff;

        h5 {
          color: #fff;
        }
      }
    }
  }

  .Filterations {
    .inputgrp {
      margin-top: 10px;

      .inputlabel {
        display: block;
        font-size: 18px;
        font-weight: 400;
      }

      .inputtag {
        border: 0 !important;
        background-color: rgba(219, 233, 255, 0.7);
        border-radius: 6px;
        width: 100%;
        padding: 10px;
      }
    }

    .filterbtn {
      border: 1px solid #267dff;
      background-color: #267dff;
      color: #fff;
      padding: 10px 20px;
      border-radius: 6px;

      &:hover {
        background-color: #fff;
        color: #267dff;
      }
    }

    .filterbtnReverse {
      border: 1px solid #267dff;
      background-color: #fff;
      color: #267dff;
      padding: 10px 20px;
      border-radius: 6px;

      &:hover {
        background-color: #267dff;
        color: #fff;
      }
    }

    .filterbtn2 {
      background: transparent;
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.25);
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      width: 35%;
      margin-right: 20px;
      margin-top: 10px;

      &.active {
        background: rgb(222, 232, 253);
        color: rgb(58, 111, 222);
      }
    }
  }

  .charts {
          border: 1px solid rgba(0, 0, 0, 0.25);
          border-radius: 6px;
          padding: 10px;

          h3 {
            font-size: 20px;
            font-weight: 500;
            margin: 10px;
          }
        }

  .Sections {
    margin-top: 75px;
    padding: 20px;

    .subSections {
      padding: 20px;
      border-radius: 6px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;

      .Editbox {
        position: absolute;
        right: 0;
        top: 0;

        .editbtn {
          border: 1px solid #223D64;
          background-color: #223D64;
          color: #fff;
          padding: 15px;
          border-top-right-radius: 6px;

        }
      }

      .Dashboardsmis {
        .transition-col {
          transition: all 0.2s ease-in-out;
        }

        .table {
          border-radius: 15px;

          .newTable {
            th {
              border-radius: 0px;
              background-color: #b9d8f9;
              // background-color: rgb(242, 242, 242);
              color: #000;
            }

            .activeTr {
              background: #c2e1f5;

              td {
                background: #c2e1f5 !important;
              }
            }

            td {
              border-radius: 0px;
              border: 1px solid #eee !important;
              padding: 10px;
            }

            .leftsticky {
              width: 50px;
              position: sticky;
              left: 0;
              text-align: left;
              background: #fff;
            }

            .Editinputtag {
              padding: 10px;
              border-radius: 6px;
              border: 1px solid #267dff;
            }
          }
        }

        
      }
    }
  }
}

.target-box {

  width: 70%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: #f4f3f1;
  border-radius: 5px;
  margin-top: 50px
}


.customfilterbtn{
  .filterbtn2 {
    background: transparent;
    margin-left: 10px;
    color: rgba(0, 0, 0, 0.25);
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    width: 12%;
    margin-right: 20px;
    margin-top: 10px;

    &.active {
      background: rgb(222, 232, 253);
      color: rgb(58, 111, 222);
    }
  }
}
.shadow-card{
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px 4px rgba(50, 71, 92, 0.02), 0px 2px 9px 0px rgba(50, 71, 92, 0.04), 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
}


.expense-config{
  .Filterations {
    .inputgrp {
      margin-top: 10px;

      .inputlabel {
        display: block;
        font-size: 16px;
        font-weight: 400;
      }

      .inputtag {
        border: 0 !important;
        background-color: rgba(219, 233, 255, 0.7);
        border-radius: 6px;
        width: 100%;
        padding: 10px;
      }
    }

    .filterbtn {
      border: 1px solid #267dff;
      background-color: #267dff;
      color: #fff;
      padding: 10px 20px;
      border-radius: 6px;

      &:hover {
        background-color: #fff;
        color: #267dff;
      }
    }

    .filterbtnReverse {
      border: 1px solid #267dff;
      background-color: #fff;
      color: #267dff;
      padding: 10px 20px;
      border-radius: 6px;

      &:hover {
        background-color: #267dff;
        color: #fff;
      }
    }

    .filterbtn2 {
      background: transparent;
      margin-left: 10px;
      color: rgba(0, 0, 0, 0.25);
      padding: 10px;
      border: 1px solid rgba(0, 0, 0, 0.25);
      border-radius: 6px;
      width: 15%;
      margin-right: 20px;
      margin-top: 10px;

      &.active {
        background: rgb(222, 232, 253);
        color: rgb(58, 111, 222);
      }
    }
  }
}
.new-switch{
  .toggle-button-cover {
    display: table-cell;
    position: relative;
    width: 200px;
    height: 45px;
    box-sizing: border-box;
  }
  
  .button-cover {
    // height: 100px;
    // margin: 20px;
    // background-color: #fff;
    // box-shadow: 0 10px 20px -8px #c5d6d6;
    // border-radius: 4px;
  }
  
  .button-cover:before {
    // counter-increment: button-counter;
    // content: counter(button-counter);
    // position: absolute;
    // right: 0;
    // bottom: 0;
    // color: #d7e3e3;
    // font-size: 12px;
    // line-height: 1;
    // padding: 5px;
  }
  
  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  
  .button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -20px auto 0 auto;
    overflow: hidden;
  }
  
  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }
  
  .button.b2 {
    border-radius: 2px;
  }
  
  .checkbox {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .knobs {
    z-index: 2;
  }
  
  .layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  #button-18 .knobs:before,
#button-18 .knobs span {
  content: "OFF";
  position: absolute;
  top: 4px;
  left: 4px;
  color: #fff;
  font-size: 10px;
  font-weight: bold;
  text-align: center;
  line-height: 1;
  background-color: red;
  border-radius: 2px;
}

#button-18 .knobs:before {
  top: 50%;
  left: 8px;
  width: 20px;
  height: 10px;
  margin-top: -5px;
  background-color: transparent;
  z-index: 2;
}

#button-18 .knobs span {
  width: 30px;
  height: 29px;
  padding: 9px 4px;
  transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  z-index: 1;
}

#button-18 .checkbox:active + .knobs:before {
  left: 10px;
  width: 46px;
  height: 4px;
  color: transparent;
  margin-top: -2px;
  background-color: #0095d8;
  transition: 0.3s ease all;
  overflow: hidden;
}

#button-18 .checkbox:active + .knobs span {
  width: 58px;
}

#button-18 .checkbox:checked:active + .knobs:before {
  left: auto;
  right: 10px;
  background-color: #d80000;
}

#button-18 .checkbox:checked:active + .knobs span {
  margin-left: -38px;
}

#button-18 .checkbox:checked + .knobs:before {
  content: "ON";
  left: 47px;
}

#button-18 .checkbox:checked + .knobs span {
  left: 42px;
  background-color: green;
}

#button-18 .checkbox:checked ~ .layer {
  background-color: #fcebeb;
}
}
.department-main{
  .accordion-body {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
    padding: 0 10px;
}

.accordion-body.expanded {
    max-height: 500px; 
    min-height: 400px;
    padding: 10px;
}

}

.paymentInsightsMain{
  .heading{
    h3{
font-size: 24px;
font-weight: 600;
    }
    h4{
font-size: 20px;
font-weight: 600;
margin: 0px;
    }
  }
  .filters{
    margin-top: 20px;
    .inputGrp{
      .labelTag{
        display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700;
      }
    
      .applyBtn{
        border: 1px solid #267dff;
        background-color: #267dff;
        color: #fff;
        padding: 10px 20px;
        border-radius: 6px;
        &:hover{
          background-color: #fff;
          color: #267DFF;
        }
      }
    }
  }

  .outerBoxWithPadding{
    border-radius: 12px;
    box-shadow: 5px 5px 10px 5px #cfcfcf;
    padding: 25px 40px;
  }
  
  .PaymentBoxes{
    display: flex;
    .tabs{
      margin-top: 10px;
      padding: 10px;
      width: calc(100%/5);
      .subtabs{
        padding: 15px;
        border-radius: 8px;
        .circlesIcon{
          width: 40px;
          height: 40px;
          border-radius: 50%;
          color: #fff;
          padding: 11.5px 12px;
         
        }
        h4{
          margin: 20px 0px 0px 0px;
          font-size: 18px;
          font-weight: 700;
          color: #000;
        }
        h5{
          font-size: 14px;
          margin: 15px 0px 0px 0px;
          &.bolder-font{
            font-weight: 700;
          }
        }
      }
    }
  }
  .paymentOverviewSection{
    margin-top: 50px;
  }
  
  .weeklyCollectionSection{
    margin-top: 70px;
    .barChart{
      margin-top: 30px;
      height: 300px;
    }
  }
  .InsightsSection{
    margin-top: 70px;
   
    .PieChart{
      height: 280px;
    }
  }

  .MandateInsights{
    margin-top: 70px;
    .boxes{
      margin-top: 10px;
      cursor: pointer;
      h5{
        font-size: 24px;
        font-weight: 500;
        margin: 0px;
      }
      h4{
        font-size: 30px;
        font-weight: 700;
      }
      &.active{
        background-color: #223D64;
        // background-color: #38cd38;
        color: #fff;
      }
    }
    .barchart{
      height: 300px;
      margin-top: 30px;
    }
  }
}
.phocket-table-ops {
  margin-top: 20px;

  table {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    border-collapse: collapse;
    border: 1px solid #ccc;

    thead {
      background: $tablehead;
      text-align: left !important;
      color: #746E6E;

      th {
        padding: 20px;
        border: 1px solid #ccc;
      }

      th:first-child {
        border-top-left-radius: 15px;
      }

      th:last-child {
        border-top-right-radius: 15px;
      }
    }

    tbody {
      td {
        border-bottom: 1px solid #ccc !important;
        border-right: 1px solid #ccc;
        padding: 5px 10px;
      }

      tr {
        &:first-child {
          font-weight: normal;
          border-bottom: solid 1px $borderColor;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          border-bottom-left-radius: 15px;
        }

        td:last-child {
          border-bottom-right-radius: 15px;
        }
      }

      tr:nth-child(even) {
        background-color: #f7f9fe;
      }
    }
  }
}

.emp-table{
  // box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  overflow: scroll;
  width: 100%;
  // font-weight: 600;

  table {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

    width: 100%;
    margin-top: 20px;


    thead {
      background: #00233C;
      // text-align: center !important;
      color: #fff;


      th {
        padding: 20px;
        text-align: center;
      }

    }

    tbody {

      // background: $tablebody;
      td {
        border-bottom: 0 !important;
        padding: 5px !important;
        text-align: center;
        font-weight: 400;

      }

      tr:nth-child(even) {
        background-color: #e8f0fe;
      }

      tr {
        &:first-child {
          // color: $grey;
          // border-bottom: solid 1px $borderColor;
          // height: 70px !important;
        }

        .table-icon {
          border: 0;
          padding: 0;
          background-color: transparent;

          &:hover {
            background-color: transparent;
          }
        }
      }

      tr:nth-last-child(1) {
        td:first-child {
          // border-bottom-left-radius: 15px;

        }

        td:last-child {
          // border-bottom-right-radius: 15px;
        }
      }
    }
  }

}
.payment-edit{
.edit-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}

.popup-container {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1001;
  max-width: 400px;
  width: 100%;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}
}

.expense-table{
table {
  // margin-top: 80px;
  width: 100%;
  border-collapse: collapse;
}
th {
  background-color: lightgray;
  color: #000;
  padding: 12px;
  text-align: left;
  font-size: 14px;
  font-weight: 600;
}
td {
  padding: 12px;
  text-align: left;
  border: 1px solid #ddd;
  font-size: 14px;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}

tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

td:nth-child(8) {
  font-weight: bold;
  color: #333;
}



}